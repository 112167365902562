import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  Typography,
  Box,
  IconButton,
  TextField,
  styled,
  Tooltip,
  Zoom,
  Grid,
  useMediaQuery
} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import LockResetIcon from '@mui/icons-material/LockReset';

// import { setProfileImageChangeSlice } from "../../../Slices/UserDataSlice";
import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../../Slices/toastSlice";
// import { setCurrentEmployee } from "../../../Slices/employeeDetailSlice";
// import { setAdminData } from "../../../Slices/adminDetailSlice";
import { CLOUD_FUNCTIONS_ORIGIN } from "../../../function-origin";
import ProfileImgGirl from "../../../assets/img/icons/girl.png";
import ProfileImgBoy from "../../../assets/img/icons/man.svg";
import ProfileImgOthers from "../../../assets/img/icons/others.png";
import ProfileImg from "./../../../assets/img/adminIcon.svg";
import ChangePassword from "./ChangePassword";
import Loader from "../../Loader/Loader";
import { errorMessage } from "../../../Services/axiosInstance";
import {
  editAdminDetails,
  editUserDetails,
  upsertProfileImage,
} from "../../../Services/ProfileServices";
import {
  ChangePasswordButton,
  FirstNameTypography,
  InnerBoxFive,
  InnerBoxFour,
  InnerBoxOne,
  InnerBoxSeven,
  InnerBoxSix,
  InnerBoxThree,
  InnerBoxTwo,
  LoadingBox,
  MainBox,
  ProfileTitleTypography,
  RoleTypography,
  TitleTypography,
  BoxZero,
} from "../../../Styles/AdminDetailStyle";

import { DashboardProfileButtons } from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import UpdateContact from "../../UpdateEmployee/UpdateContact/UpdateContact";
import UpdateEducation from "../../UpdateEmployee/UpdateEducation/UpdateEducation";
import UpdateFamily from "../../UpdateEmployee/UpdateFamily/UpdateFamily";
import UpdateExperience from "../../UpdateEmployee/UpdateExperience/UpdateExperience";
import UpdatePersonal from "../../UpdateEmployee/UpdatePersonal/UpdatePersonal";
import UpdateFinancial from "../../UpdateEmployee/UpdateFinancial/UpdateFinancial";
import {
  setemployeeAllData,
  // setemployeeAllLeaveData,
} from "../../../Slices/employeeDetailSlice";

import { getEmployeeDetails } from "../../../Services/EmployeeManageServices";
import {
  ProfileMainBox,
  MainBoxProfile,
  RenderTabBox,
  PersonalDetailsButton,
  ContactDetailsButton,
  EducationQualificationButton,
  FamilyDetailsButton,
  ExperienceDetailsButton,
  FinancialDetailsButton,
} from "../../../Styles/UpdateEmployee/ViewProfileStyle";
import "./../../AdminDashboard/Dashboard.scss";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const MAX_FILE_SIZE_MB = 2;

const AdminDetails = () => {
  const imgRef = useRef(null);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:600px)');

  const adminData = useSelector((state) => state.adminData.adminData);
  const currentEmployee = useSelector((state) => state.addEmployee.current);
  const employeeCall = useSelector(
    (state) => state?.addEmployee?.employeeAllData
  );
  const user = useSelector((state) => state.selectedActiveView.role);
  const currentEmployeeLogin = user === "employee";
  const adminLogin = user === "admin";

  const [displayError, setDisplayError] = useState("");
  const [profileImageChange, setProfileImageChange] = useState(false);
  const [profileImageAvailable, setProfileImageAvailable] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const [profileImgSrc, setProfileImgSrc] = useState(true);
  const [edit, setEdit] = useState();
  const [changePassword, setChangePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [adminDescription, setAdminDescripton] = useState(
    adminData?.description
  );
  const [employeeDescription, setEmployeeDescripton] = useState(
    currentEmployee?.description
  );
  const employeeId = employeeCall?.empId;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("personal");
  const demoMode = process.env.REACT_APP_DEMO_MODE === "true";

  const tabOrder = [
    "personal",
    "contact",
    "education",
    "family",
    "experience",
    "financial",
  ];

  const closeChangePasswordDialog = () => {
    setChangePassword(false);
  };

  const handleTouchStart = () => {
    setTooltipOpen(true);
  };

  const handleTouchEnd = () => {
    setTooltipOpen(false);
  };

  function Gender() {
    if (employeeCall?.Gender === "Male") {
      return ProfileImgBoy;
    } else if (employeeCall?.Gender === "Female") {
      return ProfileImgGirl;
    } else return ProfileImgOthers;
  }

  const profileSrc = Gender();

  const capitalizeFirstLetter = (string) => {
    if (string && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return string;
    }
  };

  function setEmail() {
    if (currentEmployeeLogin) {
      return currentEmployee?.email;
    } else if (adminLogin) {
      return adminData?.email;
    }
  }

  const email = setEmail();

  const rerenderImage = () => {
    const imgElement = imgRef.current;
    if (imgElement) {
      imgElement.src = `${CLOUD_FUNCTIONS_ORIGIN}/user/getProfileImage/${currentUserId}.jpg?timestamp=${Date.now()}`;
    }
  };

  // const handleSaveClick = async () => {
  //   setIsLoading(true);
  //   setDisplayError("");
  //   try {
  //     if (currentEmployeeLogin) {
  //       try {
  //         const description = employeeDescription;
  //         // const response = await axios.put(
  //         //   `${CLOUD_FUNCTIONS_ORIGIN}/user/userDetailChange`,
  //         //   JSON.stringify({ email, description }),
  //         //   postHeader
  //         // );
  //         const postData = JSON.stringify({ email, description });
  //         const response = await editUserDetails(postData);
  //         if (response.status === 200 || response.status === 201) {
  //           setIsLoading(false);
  //           // const employee = {
  //           //   ...currentEmployee,
  //           //   description: response.data.description,
  //           // };
  //           // dispatch(setCurrentEmployee(employee));
  //           setEdit(false);
  //           dispatch(setToast(true));
  //           dispatch(setToastMsg(response.data.message));
  //           dispatch(setToastType("success"));
  //         }
  //       } catch (error) {
  //         setIsLoading(false);
  //         setDisplayError(errorMessage ?? "");
  //       }
  //     } else if (adminLogin) {
  //       const description = adminDescription;
  //       // const response = await axios.put(
  //       //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/admin`,
  //       //   JSON.stringify({ email, description }),
  //       //   postHeader
  //       // );
  //       const postData = JSON.stringify({ email, description });
  //       const response = await editAdminDetails(postData);
  //       if (response.status === 200 || response.status === 201) {
  //         setIsLoading(false);
  //         // const employee = {
  //         //   ...adminData,
  //         //   description: response.data.description,
  //         // };
  //         // dispatch(setAdminData(employee));
  //         setEdit(false);
  //         dispatch(setToast(true));
  //         dispatch(setToastMsg(response.data.message));
  //         dispatch(setToastType("success"));
  //       }
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     setDisplayError(errorMessage ?? "");
  //   }
  // };

  const handleSaveClick = async () => {
    setIsLoading(true);
    setDisplayError("");

    try {
      const description = currentEmployeeLogin
        ? employeeDescription
        : adminDescription;
      if (!description.trim()) {
        setDisplayError("Description is required.");
        setIsLoading(false);
        return;
      }
      if (currentEmployeeLogin) {
        const postData = JSON.stringify({ email, description });
        const response = await editUserDetails(postData);
        if (response.status === 200 || response.status === 201) {
          setIsLoading(false);
          setEdit(false);
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message));
          dispatch(setToastType("success"));
        }
      } else if (adminLogin) {
        const postData = JSON.stringify({ email, description });
        const response = await editAdminDetails(postData);
        if (response.status === 200 || response.status === 201) {
          setIsLoading(false);
          setEdit(false);
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message));
          dispatch(setToastType("success"));
        }
      }
    } catch (error) {
      setIsLoading(false);
      setDisplayError(errorMessage ?? "");
    }
  };

  const handleImageChange = async (event, currentUserId) => {
    try {
      let file = event.target.files[0];
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        dispatch(setToast(true));
        dispatch(
          setToastMsg(
            `File size exceeds maximum limit of ${MAX_FILE_SIZE_MB}MB`
          )
        );
        dispatch(setToastType("error"));
        event.target.value = null;
        return;
      }
      const fileName = currentUserId;
      if (file) {
        const formData = new FormData();
        formData.append("ImageFile", file);
        formData.append("FileName", fileName);

        // const response = await axios.post(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/user/uploadProfileImage?fileName=${fileName}`,
        //   formData,
        //   {
        //     headers: {
        //       Authorization: `${token}`,
        //       "Content-Type": "multipart/form-data",
        //     },
        //   }
        // );
        const response = await upsertProfileImage(fileName, formData);
        if (response.status === 200 || response.status === 201) {
          event.target.value = "";
          dispatch(setToast(true));
          dispatch(setToastMsg(`Profile Image Changed!!`));
          dispatch(setToastType("success"));
          setProfileImageChange(!profileImageChange);
          // dispatch(setProfileImageChangeSlice(!profileImageChange));
        }
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "");
    }
  };

  const saveNextButtonCallback = () => {
    handleNext();
  };

  const handleNext = () => {
    let index = tabOrder.indexOf(selectedTab);
    setSelectedTab(tabOrder[index + 1]);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const renderTabContent = () => {
    if (selectedTab === "personal") {
      return (
        <UpdatePersonal
          image={false}
          employeeCall={employeeCall}
          saveNextButtonCallback={saveNextButtonCallback}
        />
      );
    } else if (selectedTab === "contact") {
      return (
        <UpdateContact
          employeeCall={employeeCall}
          saveNextButtonCallback={saveNextButtonCallback}
        />
      );
    } else if (selectedTab === "education") {
      return (
        <UpdateEducation
          employeeCall={employeeCall}
          saveNextButtonCallback={saveNextButtonCallback}
        />
      );
    } else if (selectedTab === "family") {
      return (
        <UpdateFamily
          employeeCall={employeeCall}
          saveNextButtonCallback={saveNextButtonCallback}
        />
      );
    } else if (selectedTab === "experience") {
      return (
        <UpdateExperience
          employeeCall={employeeCall}
          saveNextButtonCallback={saveNextButtonCallback}
        />
      );
    }
    // else if (selectedTab === "job") {
    //   return (
    //     <UpdateJob
    //       employeeCall={employeeCall}
    //       saveNextButtonCallback={saveNextButtonCallback}
    //     />
    //   );
    // }
    else if (selectedTab === "financial") {
      return <UpdateFinancial employeeCall={employeeCall} />;
    }
  };

  const memoizedemployeeDataReload = useMemo(() => {
    const employeeDataReload = async () => {
      try {
        setIsLoading(true);
        // const response = await axios.get(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/user/employeeDetail`,
        //   getHeader
        // );
        const response = await getEmployeeDetails();
        if (response.status === 200 || response.status === 201) {
          dispatch(setemployeeAllData(response.data.user));
          // dispatch(setemployeeAllLeaveData(response.data.leave));
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        // if (error.response) {
        //   if (error.response.status === 401 || error.response.status === 403) {
        //     window.localStorage.removeItem("Token");
        //     navigate("/");
        //   }
        // }
      }
    };
    return employeeDataReload;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId]);

  useEffect(
    () => {
      if (demoMode) {
        setProfileImageAvailable(false);
        setProfileImgSrc(ProfileImg);
      } else {
      async function checkImageAvailability(id) {
        const imageUrl = `${CLOUD_FUNCTIONS_ORIGIN}/user/getProfileImage/${id}.jpg`;
        try {
          const response = await axios.head(imageUrl);
          if (response.status === 200 || response.status === 201) {
            setProfileImageAvailable(true);
            setProfileImgSrc(imageUrl);
          } else {
            setProfileImageAvailable(false);
            setProfileImgSrc(ProfileImg);
          }
        } catch (error) {
          setProfileImageAvailable(false);
        }
      }

      if (currentEmployeeLogin) {
        setCurrentUserId(currentEmployee?.empId);
        checkImageAvailability(currentEmployee?.empId);
        setEmployeeDescripton(currentEmployee?.description);
        setProfileImgSrc(false);
      } else if (adminLogin) {
        setCurrentUserId(adminData?.empId);
        checkImageAvailability(adminData?.empId);
        setAdminDescripton(adminData?.description);
      }
     }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // dispatch, currentEmployeeLogin, adminLogin, currentEmployee, adminData
    ]
  );

  useEffect(
    () => {
      rerenderImage();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // profileImageChange
    ]
  );

  useEffect(() => {
    memoizedemployeeDataReload();
  }, [memoizedemployeeDataReload]);

  return (
    <Box>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <Box>
          <MainBox>
            <BoxZero>
              <Grid item container spacing={2}>
                <Grid item xs={12} md={7.1} lg={7.1}>
                  <InnerBoxOne>
                    <InnerBoxTwo>
                      <Box
                        sx={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        {
                          // demoMode ? (
                          //   <>
                          //     <img
                          //       style={{
                          //         borderRadius: "50%",
                          //         backgroundColor:
                          //           "var(--primary-highlight-color)",
                          //         padding: "5px",
                          //         border: "2px solid var(--secondary-text-color)",
                          //         height: "120px",
                          //         width: "120px",
                          //       }}
                          //       width={"120px"}
                          //       height={"120px"}
                          //       src={ProfileImg}
                          //       alt="profile"
                          //     />
                          //   </>
                          // ) :
                          profileImageAvailable ? (
                            <>
                              <img
                                ref={imgRef}
                                style={{
                                  borderRadius: "50%",
                                  backgroundColor:
                                    "var(--primary-highlight-color)",
                                  padding: "5px",
                                  border:
                                    "2px solid var(--secondary-text-color)",
                                  height: "120px",
                                  width: "120px",
                                }}
                                width={"120px"}
                                height={"120px"}
                                // src={`${CLOUD_FUNCTIONS_ORIGIN}/user/getProfileImage/${currentUserId}.jpg`}
                                src={profileImgSrc}
                                alt="profile"
                              />
                            </>
                          ) : !currentEmployeeLogin ? (
                            <>
                              <img
                                width={"120px"}
                                height={"120px"}
                                src={ProfileImg}
                                alt="profile"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                style={{
                                  borderRadius: "50%",
                                  backgroundColor:
                                    "var(--primary-highlight-color)",
                                  padding: "5px",
                                  border:
                                    "2px solid var(--secondary-text-color)",
                                }}
                                width={"120px"}
                                height={"120px"}
                                src={profileSrc}
                                alt="profile"
                              />
                            </>
                          )
                        }
                        <InnerBoxThree>
                          <Tooltip
                            title="Upload Image"
                            TransitionComponent={Zoom}
                            arrow
                            placement="right"
                          >
                            <IconButton
                              sx={{
                                color: "var(--invert-icon-color)",
                              }}
                              component="label"
                              role={undefined}
                              tabIndex={-1}
                            >
                              <ModeEditIcon sx={{}} />
                              <VisuallyHiddenInput
                                type="file"
                                accept="image/jpeg, image/jpg, image/png"
                                size={MAX_FILE_SIZE_MB * 1024 * 1024}
                                onChange={(event) =>
                                  handleImageChange(event, currentUserId)
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        </InnerBoxThree>
                      </Box>
                      <Box sx={{ border:'none' }}>
                      <Tooltip title="Change Password" open={tooltipOpen} arrow>
                        <ChangePasswordButton
                          variant="outlined"
                          onClick={() => {
                            setChangePassword(true);
                          }}
                          onTouchStart={handleTouchStart}
                          onTouchEnd={handleTouchEnd}
                          startIcon={
                            isMobile ? (
                              <LockResetIcon
                                sx={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontSize: "35px !important",
                                }}
                              />
                            ) : null
                          }
                        >
                          {!isMobile && "Change Password"}
                        </ChangePasswordButton>
                      </Tooltip>
                      </Box>
                    </InnerBoxTwo>
                    <InnerBoxFour>
                      <Box>
                        <FirstNameTypography
                          sx={{
                            "@media (max-width: 600px)": {
                              fontSize: "16px",
                            },
                          }}
                        >
                          {/* <PersonIcon
                        color="primary"
                        sx={{ marginRight: "10px", fontSize: "30px" }}
                      /> */}
                          {currentEmployeeLogin
                            ? capitalizeFirstLetter(currentEmployee?.firstName)
                            : ""}
                          {adminLogin
                            ? capitalizeFirstLetter(adminData?.firstName)
                            : ""}
                        </FirstNameTypography>
                        <RoleTypography
                          sx={{
                            fontWeight: "300",
                            fontSize: "20px",
                            color: "var(--secondary-text-color)",
                            display: "flex",
                            alignItems: "center",
                            "@media (max-width: 600px)": {
                              fontSize: "14px",
                            },
                          }}
                        >
                          {/* <BadgeIcon
                        color="secondary"
                        sx={{ marginRight: "10px", fontSize: "30px" }}
                      /> */}
                          {currentEmployeeLogin
                            ? currentEmployee?.position?.role
                            : ""}
                          {adminLogin ? adminData?.position?.role : ""}
                        </RoleTypography>
                      </Box>
                      <Box>
                        <Box>
                          <TitleTypography
                            sx={{
                              "@media (max-width: 600px)": {
                                fontSize: "14px",
                              },
                            }}
                          >
                            <EmailIcon
                              color="warning"
                              sx={{
                                marginRight: "10px",
                                fontSize: "25px",
                                "@media (max-width: 600px)": {
                                  fontSize: "14px",
                                },
                              }}
                            />
                            {currentEmployeeLogin ? currentEmployee?.email : ""}
                            {adminLogin ? adminData?.email : ""}
                          </TitleTypography>
                        </Box>
                        <Box>
                          <TitleTypography
                            sx={{
                              "@media (max-width: 600px)": {
                                fontSize: "14px",
                              },
                            }}
                          >
                            <CallIcon
                              color="success"
                              sx={{
                                marginRight: "10px",
                                fontSize: "25px",
                                "@media (max-width: 600px)": {
                                  fontSize: "14px",
                                },
                              }}
                            />
                            {currentEmployeeLogin
                              ? currentEmployee?.phoneNumber
                              : ""}
                            {adminLogin ? adminData?.phoneNumber : ""}
                          </TitleTypography>
                        </Box>
                      </Box>
                    </InnerBoxFour>
                  </InnerBoxOne>
                </Grid>
                <Grid item xs={12} md={4.5} lg={4.5}>
                  {/* For Intro about admin */}
                  <InnerBoxFive>
                    <InnerBoxSix>
                      <ProfileTitleTypography>About</ProfileTitleTypography>
                      <InnerBoxSeven>
                        {edit ? (
                          <>
                            <IconButton
                              sx={{ color: "var(--third-color)" }}
                              onClick={handleSaveClick}
                            >
                              <SaveIcon />
                            </IconButton>
                            <IconButton
                              sx={{ color: "var(--third-color)" }}
                              onClick={() => {
                                setEdit(false);
                                setDisplayError("");
                                setEmployeeDescripton(
                                  currentEmployeeLogin
                                    ? employeeDescription
                                    : ""
                                );
                                setAdminDescripton(
                                  adminLogin ? adminDescription : ""
                                );
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </>
                        ) : (
                          <IconButton
                            sx={{ color: "var(--third-color)" }}
                            onClick={() => setEdit(true)}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </InnerBoxSeven>
                    </InnerBoxSix>
                    <Box sx={{ padding: "20px" }}>
                      {edit ? (
                        <>
                          {currentEmployeeLogin ? (
                            <TextField
                              placeholder="Please add your description"
                              multiline
                              fullWidth
                              rows={3}
                              value={employeeDescription}
                              onChange={(e) =>
                                setEmployeeDescripton(e.target.value)
                              }
                            />
                          ) : null}
                          {adminLogin ? (
                            <TextField
                              placeholder="Please add your description"
                              multiline
                              fullWidth
                              rows={3}
                              value={adminDescription}
                              onChange={(e) =>
                                setAdminDescripton(e.target.value)
                              }
                            />
                          ) : null}
                        </>
                      ) : (
                        <>
                          {currentEmployeeLogin ? (
                            <Typography
                              sx={{
                                fontSize: "15px",
                                color: "var(--secondary-text-color)",
                              }}
                            >
                              {employeeDescription ||
                                "Please add your description"}
                            </Typography>
                          ) : null}
                          {adminLogin ? (
                            <Typography
                              sx={{
                                fontSize: "15px",
                                color: "var(--secondary-text-color)",
                              }}
                            >
                              {adminDescription ||
                                "Please add your description"}
                            </Typography>
                          ) : null}
                        </>
                      )}
                    </Box>
                    <Typography
                      sx={{
                        color: "red",
                        marginLeft: "16px",
                        marginBottom: "8px",
                      }}
                    >
                      {displayError}
                    </Typography>
                  </InnerBoxFive>
                </Grid>
              </Grid>
            </BoxZero>
          </MainBox>
          <ChangePassword
            open={changePassword}
            close={closeChangePasswordDialog}
          />
          <ProfileMainBox>
            <Grid item container spacing={2}>
              <Grid item container xs={12} md={3.5}>
                <MainBoxProfile>
                  <PersonalDetailsButton
                    dashboardprofilebuttons={DashboardProfileButtons}
                    selectedtab={selectedTab}
                    onClick={() => handleTabChange("personal")}
                  >
                    Personal Details
                  </PersonalDetailsButton>
                  <ContactDetailsButton
                    onClick={() => handleTabChange("contact")}
                    dashboardprofilebuttons={DashboardProfileButtons}
                    selectedtab={selectedTab}
                  >
                    Contact Details
                  </ContactDetailsButton>
                  <EducationQualificationButton
                    onClick={() => handleTabChange("education")}
                    dashboardprofilebuttons={DashboardProfileButtons}
                    selectedtab={selectedTab}
                  >
                    Education Qualification
                  </EducationQualificationButton>
                  <FamilyDetailsButton
                    onClick={() => handleTabChange("family")}
                    dashboardprofilebuttons={DashboardProfileButtons}
                    selectedtab={selectedTab}
                  >
                    Family Details
                  </FamilyDetailsButton>
                  <ExperienceDetailsButton
                    onClick={() => handleTabChange("experience")}
                    dashboardprofilebuttons={DashboardProfileButtons}
                    selectedtab={selectedTab}
                  >
                    Experience Details
                  </ExperienceDetailsButton>
                  {/* <Button
                onClick={() => handleTabChange("job")}
                sx={{
                  ...DashboardProfileButtons,
                  backgroundColor:
                    selectedTab === "job"
                      ? "var(--primary-highlight-color)"
                      : "var(--background-table-sidebar-card-color)",
                  color:
                    selectedTab === "job"
                      ? "var(--primary-color)"
                      : "var(--primary-text-color)",
                  fontWeight: selectedTab === "job" ? "bold" : "normal",
                  border: "none",
                }}
              >
                Job Details
              </Button> */}
                  <FinancialDetailsButton
                    onClick={() => handleTabChange("financial")}
                    dashboardprofilebuttons={DashboardProfileButtons}
                    selectedtab={selectedTab}
                  >
                    Financial Details
                  </FinancialDetailsButton>
                </MainBoxProfile>
              </Grid>
              {/* RIGHT BOX */}
              <Grid item container xs={12} md={8.5}>
                {/* FOR MAIN COMPONENT DESIGN BOX */}
                <RenderTabBox>{renderTabContent()}</RenderTabBox>
              </Grid>
            </Grid>
          </ProfileMainBox>
        </Box>
      )}
    </Box>
  );
};

export default AdminDetails;
