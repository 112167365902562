import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CardContent,
  Grid,
} from "@mui/material";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import PunchClockTwoToneIcon from "@mui/icons-material/PunchClockTwoTone";
import FingerprintTwoToneIcon from "@mui/icons-material/FingerprintTwoTone";
import NextWeekTwoToneIcon from "@mui/icons-material/NextWeekTwoTone";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { PointedTextContainer } from "../ReusableComponents/DashboardComponents/PointedTextContainer";
import { BirthDayShow } from "../ReusableComponents/BirthDayShow";
import EmpTaskTable from "./Tables/EmpTaskTable";
import Chart from "./Chart";
import FilterChart from "./FilterChart";
import EventShow from "../ReusableComponents/EventShow";
import EventCalendar from "./EventCalendar";
import LeaveBalanceTable from "./LeaveBalanceTable";
import { MainGrid, WeeklyCheckInsTypography, WeeklyCheckInsCard, DailyCheckInsTypography, DailyCheckInsCard, InnerGridThree, InnerGridTwo, EventTypography, EventCardContent, MainCard, EventCard, ActivityTypography, InnerCardOne, MainBox, InnerGridOne, TextTypography, CardContents, LeaveBalanceCard, InnerGridFour } from "../../Styles/AdminDashboard/CommonDashBoardStyle"

function CommonDashBoard({
  absentData,
  count,
  upComingAbsentData,
  upComingBirthDay,
  employeeInTime,
  employeeChartData,
  employeeWeekChartData,
  workingDaysInThisMonth,
  presentDaysData,
  userData,
  leaveBalanace,
}) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.selectedActiveView.role);
  const adminLogin = user === "admin";

  const [festivalData, setFestivalData] = useState([]);
  const [eventData, setEventData] = useState([]);

  const earlyBirdArray = employeeInTime?.filter((item) => {
    if (item.TimerStart !== null) {
      const date = new Date(item.TimerStart);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      return hours < 10 || (hours === 10 && minutes === 0 && seconds === 0);
    }
    return false; // Explicitly return false if item.TimerStart is null
  });

  const lateOwlArray = employeeInTime?.filter((item) => {
    if (item.TimerStart !== null) {
      const date = new Date(item.TimerStart);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      return (
        hours >= 10 ||
        (hours === 10 && minutes > 0) ||
        (hours === 10 && minutes === 0 && seconds > 0)
      );
    }
    return false; // Explicitly return false if item.TimerStart is null
  });

  const countBox = (name, click) => {
    return (
      <MainCard
        adminlogin={adminLogin ? "true" : "false"}
        name={name}
        onClick={
          adminLogin
            ? click
              ? () => {
                if (name === "Attendance") {
                  navigate("/attendance-management");
                }
                if (name === "OnLeave") {
                  navigate("/leave-management");
                }
                if (name === "Employee") {
                  navigate("/employee-management");
                }
                if (name === "Monthly Present") {
                  navigate("/attendance");
                }
              }
              : null
            : click
              ? () => {
                if (name === "Monthly Present") {
                  navigate("/attendance");
                }
              }
              : null
        }
      >
        <Box sx={{ display: "flex" }}>
          {name === "Employee" ? (
            <PeopleAltTwoToneIcon color="primary" fontSize="large" />
          ) : null}
          {name === "OnLeave" ? (
            <PunchClockTwoToneIcon color="error" fontSize="large" />
          ) : null}
          {name === "Attendance" ? (
            <FingerprintTwoToneIcon color="secondary" fontSize="large" />
          ) : null}
          {name === "Next Onleave" ? (
            <NextWeekTwoToneIcon color="warning" fontSize="large" />
          ) : null}
          {name === "Monthly Present" ? (
            <CalendarMonthIcon color="warning" fontSize="large" />
          ) : null}
        </Box>
        <MainBox>
          <Box sx={{ display: "flex" }}>
            <TextTypography>
              {name === "Employee" ? userData : null}
              {name === "OnLeave" ? absentData?.length : null}
              {name === "Attendance" ? (
                <>
                  {count}/{userData}
                </>
              ) : null}
              {name === "Next Onleave" ? upComingAbsentData?.length : null}
              {name === "Monthly Present" ? (
                <>
                  {presentDaysData?.length}/{workingDaysInThisMonth?.length}
                </>
              ) : null}
            </TextTypography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <TextTypography>
              {name}
            </TextTypography>
          </Box>
        </MainBox>
      </MainCard>
    );
  };

  return (
    <Box vw="100%">
      <MainGrid
        container
        item
        spacing={2}
      >
        <Grid
          item
          lg={upComingBirthDay?.length === 0 ? 3 : 2}
          md={6}
          xs={6}
          sm={6}
          order={{ lg: 1, md: 2, xs: 2, sm: 2 }}
        >
          {countBox("Employee", true)}
        </Grid>
        <Grid
          item
          lg={upComingBirthDay?.length === 0 ? 3 : 2}
          md={6}
          xs={6}
          sm={6}
          order={{ lg: 2, md: 3, xs: 3, sm: 3 }}
        >
          {countBox("OnLeave", true)}
        </Grid>
        <Grid
          item
          lg={upComingBirthDay?.length === 0 ? 3 : 2}
          md={6}
          xs={6}
          sm={6}
          order={{ lg: 3, md: 4, xs: 4, sm: 4 }}
        >
          {countBox("Attendance", true)}
        </Grid>
        {/* <Grid
                    item
                    lg={2}
                    md={6}
                    xs={6}
                    sm={6}
                    order={{ lg: 4, md: 5, xs: 5, sm: 5 }}
                >
                    {countBox("Next Onleave")}
                </Grid> */}
        <Grid
          item
          lg={upComingBirthDay?.length === 0 ? 3 : 2}
          md={6}
          xs={6}
          sm={6}
          order={{ lg: 4, md: 5, xs: 5, sm: 5 }}
        >
          {countBox("Monthly Present", true)}
        </Grid>
        {upComingBirthDay?.length > 0 ? (
          <Grid
            item
            lg={4}
            md={12}
            xs={12}
            sm={12}
            order={{ lg: 5, md: 1, xs: 1, sm: 1 }}
          >
            <BirthDayShow upComingBirthDay={upComingBirthDay} />
          </Grid>
        ) : null}
      </MainGrid>
      <Grid
        container
        item
        spacing={2}
        sx={{ padding: "2px", marginBottom: "12px" }}
      >
        <Grid item lg={8} md={12} xs={12} sm={12}>
          {adminLogin ? (
            <Grid item container lg={12} md={12} xs={12} sm={12}>
              <InnerGridOne
                item
                lg={6}
                md={6}
                xs={6}
                sm={6}
              >
                <InnerCardOne>
                  <CardContents>
                    <PointedTextContainer
                      TitleOfCointainer={"Early Birds"}
                      DataInArrayOfObjectForm={earlyBirdArray}
                    />
                  </CardContents>
                </InnerCardOne>
              </InnerGridOne>
              <InnerGridFour
                item
                lg={6}
                md={6}
                xs={6}
                sm={6}
                sx={{ marginBottom: "12px" }}
              >
                <InnerCardOne>
                  <CardContents>
                    <PointedTextContainer
                      TitleOfCointainer={"Late Owls"}
                      DataInArrayOfObjectForm={lateOwlArray}
                    />
                  </CardContents>
                </InnerCardOne>
              </InnerGridFour>
            </Grid>
          ) : null}
          <Grid item lg={12} md={12} xs={12} sm={12} sx={{height: '100%'}}>
            <InnerCardOne>
              <CardContent sx={{ paddingBottom: "0px !important" }}>
                <ActivityTypography>
                  {adminLogin ? "Employee Activity" : "My Activity"}
                </ActivityTypography>
                <hr />
                <EmpTaskTable showButtun={false} />
              </CardContent>
            </InnerCardOne>
          </Grid>
        </Grid>
        <Grid item lg={4} md={12} xs={12} sm={12}>
          <EventCard>
            {" "}
            <EventCardContent>
              <EventTypography>
                Event Details
              </EventTypography>
              <hr />
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sm={12}
                sx={{ marginBottom: "0px" }}
              >
                <EventCalendar
                  festivalData={festivalData}
                  eventData={eventData}
                />
              </Grid>
              <hr style={{ margin: "0px" }} />
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sm={12}
                sx={{ marginBottom: "0px" }}
              >
                <EventShow
                  setFestivalData={setFestivalData}
                  setEventData={setEventData}
                />
              </Grid>
            </EventCardContent>
          </EventCard>
        </Grid>
      </Grid>
      {leaveBalanace?.length > 0 ? (
        <InnerGridTwo
          container
          item
          lg={12}
          md={12}
          xs={12}
          sm={12}>
          <LeaveBalanceCard>
            <LeaveBalanceTable leaveBalanace={leaveBalanace} />
          </LeaveBalanceCard>
        </InnerGridTwo>
      ) : null}

      {adminLogin ? (
        <InnerGridThree
          container
          item
          spacing={2}
        >
          <Grid item lg={4} md={12} xs={12} sm={12}>
            <DailyCheckInsCard>
              <DailyCheckInsTypography>
                Daily CheckIns
              </DailyCheckInsTypography>
              <Chart employeeData={employeeChartData} />
            </DailyCheckInsCard>
          </Grid>
          <Grid item lg={8} md={12} xs={12} sm={12}>
            <WeeklyCheckInsCard>
              <WeeklyCheckInsTypography>
                Weekly CheckIns
              </WeeklyCheckInsTypography>
              <FilterChart weeklyData={employeeWeekChartData} />
            </WeeklyCheckInsCard>
          </Grid>
        </InnerGridThree>
      ) : null}
    </Box>
  );
}

export default CommonDashBoard;
