import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import {
  extractDTime,
  extractDTimeWithOutAMPM,
} from "../ReusableComponents/UtilityFunctions";
import CommonDashBoard from "./CommonDashBoard";
import {
  getEmployeLeaveBalance,
  getEmployeeBirthDay,
  getEmployeeTime,
  getEmployeesPunchInCount,
  getLeaveAndAttendance,
  getWeekChartData,
  getWorkingDaysInThisMonthData,
} from "../../Services/DashboardServices";
import "./Dashboard.scss";

function CenterComponent() {
  const user = useSelector((state) => state.selectedActiveView.role);
  const employeeLogin = user === "employee";
  const adminLogin = user === "admin";

  const [absentData, setAbsentData] = useState([]);
  const [upComingAbsentData, setUpComingAbsentData] = useState([]);
  const [upComingBirthDay, setUpComingBirthDay] = useState([]);
  const [employeeInTime, setEmployeeInTime] = useState([]);
  const [employeeChartData, setEmployeeChartData] = useState([]);
  const [employeeWeekChartData, setEmployeeWeekChartData] = useState([]);
  const [workingDaysInThisMonth, setWorkingDaysInThisMonth] = useState([]);
  const [leaveBalanace, setLeaveBalanace] = useState([]);
  const [presentDaysData, setPresentDaysData] = useState([]);
  const [employeesCount, setEmployeesCount] = useState(0);
  const [punchInCount, setPunchInCount] = useState(0);

  const memoizedGetLeaveAndAttendance = useMemo(() => {
    const getData = async () => {
      try {
        const response = await getLeaveAndAttendance();
        if (response.status === 200) {
          const validLeaveData = response.data.leaveData.filter(leave =>
            leave.status !== "Cancelled" && leave.status !== "Rejected"
          );
          const validUpcomingLeaves = response.data.nextLeaves.filter(leave =>
            leave.status !== "Cancelled" && leave.status !== "Rejected"
          );
          setAbsentData(validLeaveData);
          setUpComingAbsentData(validUpcomingLeaves);
        } else {
          setAbsentData([]);
          setUpComingAbsentData([]);
        }
      } catch (error) {
        console.error("Error fetching leave data:", error);
      }
    };
    return getData;
  }, []);

  const memoizedEmployeeTime = useMemo(() => {
    const getData = async () => {
      try {
        const response = await getEmployeeTime();
        if (response.status === 200) {
          const dataArray = response.data.dataArray;
          const newdata = dataArray.sort((a, b) => {
            if (a.TimerStart === null && b.TimerStart !== null) {
              return 1;
            }
            if (a.TimerStart !== null && b.TimerStart === null) {
              return -1;
            }
            if (a.TimerStart === null && b.TimerStart === null) {
              return 0;
            }
            return a.TimerStart - b.TimerStart;
          });
          setEmployeeInTime(newdata);
          const staticChartData = newdata?.map(
            ({ name, TimerStart, Time }) => ({
              name,
              time:
                TimerStart === "12:00"
                  ? "12:00"
                  : extractDTimeWithOutAMPM(TimerStart),
              realTime: Time === "absent" ? "absent" : extractDTime(Time),
            })
          );
          setEmployeeChartData(staticChartData);
        } else {
          setEmployeeInTime([]);
          setEmployeeChartData([]);
        }
      } catch (error) {
      }
    };
    return getData;
  }, []);

  const memoizedEmployeeBirthDay = useMemo(() => {
    const getData = async () => {
      try {
        const response = await getEmployeeBirthDay();
        if (response.status === 200) {
          setEmployeesCount(response.data.employeesCount);
          setUpComingBirthDay(response.data.BirthDays);
        } else {
          setEmployeesCount(0);
          setUpComingBirthDay([]);
        }
      } catch (error) {
      }
    };
    return getData;
  }, []);

  const memoizedEmployeLeaveBalance = useMemo(() => {
    const getData = async () => {
      try {
        const response = await getEmployeLeaveBalance();
        if (response.status === 200) {
          setLeaveBalanace(response.data.array);
        } else {
          setLeaveBalanace([]);
        }
      } catch (error) {
      }
    };
    return getData;
  }, []);

  const memoizedWorkingDaysInThisMonthData = useMemo(() => {
    const getWorkingDaysInThisMonth = async () => {
      try {
        const response = await getWorkingDaysInThisMonthData();
        if (response.status === 200) {
          setWorkingDaysInThisMonth(response.data.WorkingDaysInThisMonth);
          setPresentDaysData(response.data.PresentDaysData);
        } else {
          setWorkingDaysInThisMonth([]);
          setPresentDaysData([]);
        }
      } catch (error) {
      }
    };
    return getWorkingDaysInThisMonth;
  }, []);

  const memoizedWeekChartData = useMemo(() => {
    const getData = async () => {
      try {
        const response = await getWeekChartData();
        if (response.status === 200) {
          setEmployeeWeekChartData(response.data.weekData);
        } else {
          setEmployeeWeekChartData([]);
        }
      } catch (error) {
      }
    };
    return getData;
  }, []);

  const memoizedEmployeesPunchInCount = useMemo(() => {
    const getAdminData = async () => {
      try {
        const response = await getEmployeesPunchInCount();
        if (response.status === 200) {
          setPunchInCount(response.data.punchInCount);
        } else {
          setPunchInCount(0);
        }
      } catch (error) {
      }
    };
    return getAdminData;
  }, []);

  useEffect(
    () => {
      if (adminLogin) {
        memoizedEmployeeBirthDay();
        memoizedEmployeLeaveBalance();
        memoizedEmployeesPunchInCount();
      } else if (employeeLogin) {
        memoizedEmployeeBirthDay();
        memoizedEmployeLeaveBalance();
        memoizedEmployeesPunchInCount();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  useEffect(
    () => {
      if (adminLogin) {
        memoizedEmployeeTime();
        memoizedWeekChartData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  useEffect(
    () => {
      if (adminLogin) {
        memoizedWorkingDaysInThisMonthData();
        memoizedGetLeaveAndAttendance();
      } else if (employeeLogin) {
        memoizedWorkingDaysInThisMonthData();
        memoizedGetLeaveAndAttendance();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <Box>
      <CommonDashBoard
        absentData={absentData}
        count={punchInCount}
        upComingAbsentData={upComingAbsentData}
        upComingBirthDay={upComingBirthDay}
        employeeInTime={employeeInTime}
        employeeChartData={employeeChartData}
        employeeWeekChartData={employeeWeekChartData}
        workingDaysInThisMonth={workingDaysInThisMonth}
        presentDaysData={presentDaysData}
        userData={employeesCount}
        leaveBalanace={leaveBalanace}
      />
    </Box>
  );
}

export default CenterComponent;
