import {
  Typography,
  Button,
  styled,
  Box,
  DialogActions,
  IconButton,
  TextField
} from "@mui/material";

export const TitleTypography = styled(Typography)(() => ({
  color: "var(--primary-color)",
  fontWeight: "bold",
}));

export const TextTypography = styled(Typography)(() => ({
  color: "var(--secondary-text-color)",
}));

export const CancelButton = styled(Button)(({ isdarktheme }) => ({
  color: isdarktheme === "true" ? "#e0e0e0cc" : "#b2b5b9",
  textTransform: "capitalize",
  fontWeight: "bold",
  borderRadius: "5px",
}));

export const DeleteButton = styled(Button)(() => ({
  borderRadius: "5px",
  textTransform: "capitalize",
}));

export const SaveButton = styled(Button)(() => ({
  textTransform: "capitalize",
}));

export const AddButton = styled(Button)(() => ({
  textTransform: "capitalize",
}));

export const InnerBoxOneDialog = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
}));

export const InnerBoxTwoDialog = styled(Box)(({ isdarktheme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: isdarktheme === "true" ? "#ff000046" : "#ff000221",
  borderRadius: "50%",
  padding: "8px",
}));

export const CloseIconButton = styled(IconButton)(
  ({ isdarktheme, ismobile }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    padding: ismobile === "true" ? "8px 0px" : "0px 0px 0px 6%",
  })
);

export const MainBoxAddDialog = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "@media (max-width: 600px)": {
    maxWidth: "100%",
  },
}));

export const MainBoxDialog = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "500px",
  "@media (max-width: 600px)": {
    maxWidth: "100%",
  },
}));

export const DialogsActions = styled(DialogActions)(({ displayError }) => ({
  background: "var(--highlight-color)",
  boxShadow: "0px 2px 10px var(--dark-highlight-color)",
  display: "flex",
  justifyContent: displayError && "space-between",
  alignItems: "center",
  "@media (max-width: 600px)": {
    flexDirection: displayError && "column",
    justifyContent: displayError && "flex-end",
  },
}));

export const InputTextField = styled(TextField)(({ isdarktheme }) => ({
  ".MuiOutlinedInput-input": {
    color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    borderColor: "white",
  },
  ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
  },
  ".MuiInputLabel-root": {
    color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
  },
  ".MuiInputLabel-root.Mui-focused": {
    color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
  },
  svg: {
    fill: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
  },
  color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#484848",
  "& fieldset legend > span": {
    paddingLeft: "2px",
    paddingRight: "0px",
  },
}));