import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Button,
  Grid,
  TextField,
  MenuItem,
  Box,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputAdornment,
  IconButton,
  useMediaQuery
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import {
  InputField,
  InputFieldProps,
  errorMessageDesign,
  commonStyle
} from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import { renderAsterisk } from "../../ReusableComponents/UtilityFunctions.js";
import { genderCategory, passwordRegex } from "../../Constant/constant";
import SignupImage from "./../../../assets/img/AdminSignup.png";
import { ReactComponent as BrandLogo } from "../../../assets/img/icons/EmployeeVueLogo.svg";
import DialogComponent from '../../ReusableComponents/DialogBoxComponents/DialogComponent'
import { upsertSignUp } from "../../../Services/Authentication";
import { setToast, setToastMsg, setToastType } from "../../../Slices/toastSlice";
import { InnerBoxFour, InnerBoxOne, InnerBoxThree, InnerBoxTwo, InputSelectBox, InputsBoxs, MainGrid, SignUpBox, SignUpButton } from "../../../Styles/Authentication/SignUpStyle";
import { FooterMainBox, InputBox, LinkTypography, TextTypography, SpaceTypography } from "../../../Styles/Authentication/LoginStyle";
import "./Signup.scss";

const Signup = () => {
  const is_JT_theme_Dark =
    window.localStorage.getItem("is_JT_theme_Dark") === "true" ? true : false;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    fname: "",
    email: "",
    phonenumber: "",
    position: "",
    password: "",
    confirmpassword: "",
    gender: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const validateForm = (e) => {
    setErrorMessage("");
    setFormErrors("");
    const errors = {};
    if (!formData.fname) {
      errors.fname = "Firstname is required";
    } else if (!/^[a-zA-Z\s]*$/.test(formData.fname)) {
      errors.fname = "Name should contain only alphabetic characters";
    }
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Please include an '@' in the email address.";
    }
    if (!formData.phonenumber) {
      errors.phonenumber = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phonenumber)) {
      errors.phonenumber = "Phone number must be 10 digits";
    }
    if (!formData.position) {
      errors.position = "Position is required";
    }
    if (!formData.gender) {
      errors.gender = "Gender is required";
    }
    if (!formData.password) {
      errors.password = "Password is required";
    } else if (!passwordRegex.test(formData.password)) {
      errors.password =
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long";
    }
    if (!formData.confirmpassword) {
      errors.confirmpassword = "Confirm password is required";
    } else if (!passwordRegex.test(formData.confirmpassword)) {
      errors.confirmpassword =
        "Confirm Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long";
    } else if (formData.confirmpassword !== formData.password) {
      errors.confirmpassword = "Passwords do not match";
    }
    setFormErrors(errors);
    const noErrors = Object.keys(errors).length === 0;
    if (noErrors) {
      handleSubmit(e);
    }
  };

  const handleChange = (event) => {
    setErrorMessage("");
    setFormErrors((prevFormErrors) => ({ ...prevFormErrors, [name]: "" }));
    const { name, value } = event.target;
    if (name === "phonenumber") {
      if (/^[0-9]+$/.test(value)) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
      if (!/^\d{10}$/.test(value)) {
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          [name]: "Phone number must be 10 digits",
        }));
      } else {
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          [name]: "",
        }));
      }
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
    if (name === "password") {
      if (!passwordRegex.test(value)) {
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          [name]:
            "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long",
        }));
      }
      else {
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          [name]: "",
        }));
      }
    }
    if (name === "confirmpassword" && value !== formData.password) {
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [name]: "Passwords do not match",
      }));
    }
  };

  const handleCloseDialog = () => {
    return navigate("/");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const postData = JSON.stringify(formData);
      const response = await upsertSignUp(postData)
      if (response.status === 201) {
        setFormData({
          fname: "",
          email: "",
          phonenumber: "",
          position: "",
          password: "",
          confirmpassword: "",
          gender: "",
        });
        setShowDialog(true);
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.localStorage.removeItem("Token");
          navigate("/");
        } else if (error.response.status === 409) {
          setErrorMessage(error.response.data.message);
        } else if (error.response.status === 500) {
          setErrorMessage(error.response.data.message);
        }
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (e.target.name === "confirmpassword") {
        validateForm();
      } else {
        focusNextTab(e.target);
      }
    }
  };

  const focusNextTab = (currentElement) => {
    const allTabbableElements = Array.from(
      document.querySelectorAll(
        'input, button, textarea, select, a[href], [tabindex]:not([tabindex="-1"])'
      )
    ).filter((element) => !element.disabled && !element.readOnly);
    const currentIndex = allTabbableElements.indexOf(currentElement);
    const nextIndex = (currentIndex + 1) % allTabbableElements.length;
    allTabbableElements[nextIndex].focus();
  };

  const subFooter = () => {
    return (
      <>
        <FooterMainBox>
          <Box sx={{ paddingRight: "8px" }}>
            <LinkTypography
              component="a"
              href="#"
              onClick={() => navigate("/")}
            >
              Log In
            </LinkTypography>
          </Box>
          {!isMobile && (
            <Box
              sx={{
                color: is_JT_theme_Dark
                  ? "var(--darkTheme-inputfield-color)"
                  : "var(--lightTheme-inputfield-color)",
              }}
            >
              |
            </Box>
          )}
          <Box sx={{ paddingLeft: "8px" }}>
            <LinkTypography
              component="a"
              href="#"
              onClick={() => navigate("/forgot-password")}
            >
              Forgot Password?
            </LinkTypography>
          </Box>
        </FooterMainBox>
        <TextTypography isjtthemedark={is_JT_theme_Dark ? "true" : "false"}>
          By using TEAMSPACE, you confirm that you have read and agree to
          our{" "}
          <Link
            className="link"
            to="/privacy-policy"
            state={{ from: "notlogIn" }}
          >
            Privacy Policy
          </Link>{" "}
          and{" "}
          <Link className="link" to="/privacy-policy">
            Term of Service
          </Link>{" "}
        </TextTypography>
      </>
    );
  };

  const renderForm = () => {
    return (<>
      <Box
        sx={{
          background: is_JT_theme_Dark ? "#121e2e" : "#ffffff",
        }}
      >
        <DialogTitle
          sx={{
            color: is_JT_theme_Dark ? "#e0e0e0e3" : "#544f5a",
            fontWeight: "bold",
          }}
        >
          Sign-up Successsful
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            sx={{
              color: is_JT_theme_Dark ? "#e0e0e0cc" : "#5e6e82",
            }}
          >
            You have successfully signed up!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseDialog}
            color="primary"
            autoFocus
          >
            Login now
          </Button>
        </DialogActions>
      </Box>
    </>)
  }

  return (
    <SignUpBox
      className="admin-signup"
      isjtthemedark={is_JT_theme_Dark ? "true" : "false"}
    >
      <InnerBoxOne isjtthemedark={is_JT_theme_Dark ? "true" : "false"}>
        <InnerBoxTwo>
          <MainGrid
            container
            item
            isjtthemedark={is_JT_theme_Dark ? "true" : "false"}
          >
            <Grid item sx={12} sm={12} md={12} lg={6}>
              {!isMobile && (
                <InnerBoxThree>
                  <Box>
                    <img
                      src={SignupImage}
                      alt="signup"
                      className="signup-image"
                    />
                  </Box>
                </InnerBoxThree>
              )}
            </Grid>
            <Grid
              item
              sx={12}
              sm={12}
              md={12}
              lg={6}
              style={{
                background: is_JT_theme_Dark
                  ? "var(--darkTheme-before-login-sideblock-bg)"
                  : "var(--lightTheme-before-login-bg)",
              }}
            >
              <InnerBoxFour>
                <Box>
                  <BrandLogo
                    className={
                      is_JT_theme_Dark ? "brandLogoDarkThemeclass" : ""
                    }
                    style={{
                      textAlign: "center",
                      maxWidth: "200px",
                      padding: "0 0 0 0",
                      // margin: "0px 0px 0px 100px",
                      display: "block",
                      margin: "auto"
                    }}
                  />
                  <SpaceTypography
                    variant="5"
                    isjtthemedark={is_JT_theme_Dark ? "true" : "false"}
                  >TEAMSPACE</SpaceTypography>
                  <Box component="form" onKeyDown={handleKeyPress} sx={{ marginTop: "30px" }}>
                    <InputBox isjtthemedark={is_JT_theme_Dark ? "true" : "false"}>
                      <TextField
                        inputProps={{ sx: InputFieldProps(is_JT_theme_Dark) }}
                        sx={{ ...InputField, ...commonStyle }}
                        type="text"
                        // label="Full Name"
                        name="fname"
                        placeholder="Enter a Fullname"
                        value={formData.fname}
                        onChange={handleChange}
                        // required
                        label={
                          <>
                            Full Name{renderAsterisk()}
                          </>
                        }
                      />
                      {formErrors.fname && (
                        <Typography
                          sx={{
                            marginTop: "-20px",
                            ...errorMessageDesign,
                          }}
                        >
                          {formErrors.fname}
                        </Typography>
                      )}
                    </InputBox>
                    <InputsBoxs isjtthemedark={is_JT_theme_Dark ? "true" : "false"}>
                      <TextField
                        inputProps={{ sx: InputFieldProps(is_JT_theme_Dark) }}
                        sx={{ ...InputField, ...commonStyle }}
                        // label="E-mail"
                        type="email"
                        name="email"
                        placeholder="Enter an email"
                        value={formData.email}
                        onChange={handleChange}
                        // required
                        label={
                          <>
                            E-mail{renderAsterisk()}
                          </>
                        }
                      />
                      {formErrors.email && (
                        <Typography
                          sx={{
                            marginTop: "-20px",
                            ...errorMessageDesign,
                          }}
                        >
                          {formErrors.email}
                        </Typography>
                      )}
                    </InputsBoxs>
                    <InputsBoxs isjtthemedark={is_JT_theme_Dark ? "true" : "false"}>
                      <TextField
                        inputProps={{ sx: InputFieldProps(is_JT_theme_Dark) }}
                        sx={{ ...InputField, ...commonStyle }}
                        // label="Phone Number"
                        type="text"
                        name="phonenumber"
                        placeholder="Enter a phone number"
                        value={formData.phonenumber}
                        onChange={handleChange}
                        // required
                        label={
                          <>
                            Phone Number{renderAsterisk()}
                          </>
                        }
                      />
                      {formErrors.phonenumber && (
                        <Typography
                          sx={{
                            marginTop: "-20px",
                            ...errorMessageDesign,
                          }}
                        >
                          {formErrors.phonenumber}
                        </Typography>
                      )}
                    </InputsBoxs>
                    <InputSelectBox isjtthemedark={is_JT_theme_Dark ? "true" : "false"}>
                      <TextField
                        select
                        inputProps={{ sx: InputFieldProps(is_JT_theme_Dark) }}
                        sx={{ ...InputField, ...commonStyle }}
                        // label="Gender"
                        type="text"
                        name="gender"
                        placeholder="select Gender"
                        value={formData.gender}
                        onChange={handleChange}
                        label={
                          <>
                            Gender{renderAsterisk()}
                          </>
                        }
                      // required
                      >
                        {genderCategory?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                      {formErrors.gender && (
                        <Typography
                          sx={{
                            marginTop: "-20px",
                            ...errorMessageDesign,
                          }}
                        >
                          {formErrors.gender}
                        </Typography>
                      )}
                    </InputSelectBox>
                    <InputSelectBox isjtthemedark={is_JT_theme_Dark ? "true" : "false"}>
                      <FormControl fullWidth={true}>
                        <TextField
                          select
                          inputProps={{ sx: InputFieldProps(is_JT_theme_Dark) }}
                          sx={{ marginBottom: "20px", ...commonStyle }}
                          labelid="position"
                          name="position"
                          value={formData.position}
                          // label="Position"
                          onChange={handleChange}
                          // required
                          label={
                            <>
                              Position{renderAsterisk()}
                            </>
                          }
                        >
                          <MenuItem value="">Select a Position</MenuItem>
                          <MenuItem value="CEO">CEO</MenuItem>
                          <MenuItem value="HR">HR</MenuItem>
                        </TextField>
                      </FormControl>
                      {formErrors.position && (
                        <Typography
                          sx={{
                            marginTop: "-20px",
                            ...errorMessageDesign,
                          }}
                        >
                          {formErrors.position}
                        </Typography>
                      )}
                    </InputSelectBox>
                    <InputsBoxs isjtthemedark={is_JT_theme_Dark ? "true" : "false"}>
                      <TextField
                        inputProps={{ sx: InputFieldProps(is_JT_theme_Dark) }}
                        sx={{ ...InputField, ...commonStyle }}
                        type={showPassword ? "text" : "password"}
                        // label="Password"
                        name="password"
                        placeholder="Enter password"
                        value={formData.password}
                        onChange={handleChange}
                        // required
                        label={
                          <>
                            Password{renderAsterisk()}
                          </>
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOffIcon
                                    sx={{
                                      color: is_JT_theme_Dark
                                        ? "var(--darkTheme-inputfield-color)"
                                        : "var(--lightTheme-inputfield-color)",
                                    }}
                                  />
                                ) : (
                                  <VisibilityIcon
                                    sx={{
                                      color: is_JT_theme_Dark
                                        ? "var(--darkTheme-inputfield-color)"
                                        : "var(--lightTheme-inputfield-color)",
                                    }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {formErrors.password && (
                        <Typography
                          sx={{
                            marginTop: "-20px",
                            ...errorMessageDesign,
                          }}
                        >
                          {formErrors.password}
                        </Typography>
                      )}
                    </InputsBoxs>
                    <InputsBoxs isjtthemedark={is_JT_theme_Dark ? "true" : "false"}>
                      <TextField
                        inputProps={{ sx: InputFieldProps(is_JT_theme_Dark) }}
                        sx={{ ...InputField, ...commonStyle }}
                        type={showConfirmPassword ? "text" : "password"}
                        // label="Confirm Password"
                        name="confirmpassword"
                        placeholder="Confirm password"
                        value={formData.confirmpassword}
                        onChange={handleChange}
                        // required
                        label={
                          <>
                            Confirm Password{renderAsterisk()}
                          </>
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOffIcon
                                    sx={{
                                      color: is_JT_theme_Dark
                                        ? "var(--darkTheme-inputfield-color)"
                                        : "var(--lightTheme-inputfield-color)",
                                    }}
                                  />
                                ) : (
                                  <VisibilityIcon
                                    sx={{
                                      color: is_JT_theme_Dark
                                        ? "var(--darkTheme-inputfield-color)"
                                        : "var(--lightTheme-inputfield-color)",
                                    }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {formErrors.confirmpassword && (
                        <Typography
                          sx={{
                            marginTop: "-20px",
                            ...errorMessageDesign,
                          }}
                        >
                          {formErrors.confirmpassword}
                        </Typography>
                      )}
                    </InputsBoxs>
                    <Typography sx={{ color: "red" }}>
                      {errorMessage}
                    </Typography>
                    <SignUpButton
                      onClick={validateForm}
                      variant="contained"
                    >
                      Sign Up
                    </SignUpButton>
                    {subFooter()}
                  </Box>
                </Box>
              </InnerBoxFour>
            </Grid>
          </MainGrid>
        </InnerBoxTwo>
      </InnerBoxOne>
      <DialogComponent
        open={showDialog}
        onClose={handleCloseDialog}
        maxWidth="xs"
        fullwidth={true}
        renderChildern={renderForm}
      />
    </SignUpBox>
  );
};

export default Signup;