import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  IconButton,
  InputAdornment,
  // TextField,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Close } from "@mui/icons-material";

import { setToast, setToastMsg, setToastType } from "../../../Slices/toastSlice";
import {
  InputFieldChangePassword,
  InputFieldProps,
  commonStyle
} from "../CustomDesignMUI/CustomMUI";
import { renderAsterisk } from "../UtilityFunctions.js";
import DialogComponent from "../../ReusableComponents/DialogBoxComponents/DialogComponent";
import { passwordRegex } from "../../Constant/constant";
import { upsertChangePassword } from '../../../Services/Authentication'
import { errorMessage } from "../../../Services/axiosInstance.js";
import { ChangePasswordTypography, CloseIconButton, ErrorMessageTypography, ErrorTypography, MainBox, SaveButton, InputTextField } from "../../../Styles/Authentication/ChangePasswordStyle.js";

function ChangePassword({ open, close }) {
  const is_JT_theme_Dark =
  window.localStorage.getItem("is_JT_theme_Dark") === "true";
  const dispatch = useDispatch();

  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const iconColor = isDarkTheme ? "#e0e0e0cc" : "#5e6e82";

  const [displayError, setDisplayError] = useState("");
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const handleInputChange = (event) => {
    setDisplayError("");
    const { name, value } = event.target;
    setPassword((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "oldPassword") {
      setErrors((prevData) => ({
        ...prevData,
        [name]: "",
      }));
    }
    if (name === "newPassword" || name === "confirmPassword") {
      if (!passwordRegex.test(value)) {
        setErrors((prevData) => ({
          ...prevData,
          [name]:
            "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long",
        }));
      } else {
        setErrors((prevData) => ({
          ...prevData,
          [name]: "",
        }));
      }
    }
    if (name === "confirmPassword") {
      if (password.newPassword !== value) {
        setErrors((prevData) => ({
          ...prevData,
          [name]: "Passwords do not match.",
        }));
      } else {
        setErrors((prevData) => ({
          ...prevData,
          [name]: "",
        }));
      }
    }
  };

  const handleSubmit = async () => {
    const error = [];
    if (!password.oldPassword) {
      error.oldPassword = "Old password required.";
    }
    if (!password.newPassword) {
      error.newPassword = "New password required.";
    } else if (!passwordRegex.test(password.newPassword)) {
      error.newPassword =
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long";
    }
    if (password.confirmPassword !== password.newPassword) {
      error.confirmPassword = "Passwords do not match.";
    } else if (!passwordRegex.test(password.confirmPassword)) {
      error.confirmPassword =
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long";
    }
    setErrors(error);
    const noErrors = Object.keys(error).length === 0;
    if (noErrors) {
      try {
        const postData = JSON.stringify(password);
        const response = await upsertChangePassword(postData);
        if (response.status === 200) {
          close();
          if (response.data.success === false) {
            setDisplayError(response.data.message);
          } else {
            setErrors({
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            })
            setPassword({
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
          }
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message));
          dispatch(setToastType("success"));
        }
      } catch (error) {
        setDisplayError(errorMessage ?? "")
      }
    }
  };

  const renderForm = () => {
    return (<>
      <Box>
        <CloseIconButton
          onClick={() => {
            close();
            setErrors({
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            })
          }}
          isdarktheme={isDarkTheme ? "true" : "false"}
        >
          <Close />
        </CloseIconButton>
      </Box>
      <MainBox isdarktheme={isDarkTheme ? "true" : "false"}>
        <ChangePasswordTypography iconcolor={iconColor}>
          Change Password
        </ChangePasswordTypography>
        <Box component="form">
          <Box sx={{ marginTop: "20px" }}>
            <InputTextField
              inputProps={{ sx: InputFieldProps(is_JT_theme_Dark) }}
              sx={{ ...InputFieldChangePassword, ...commonStyle }}
              type={showPassword.oldPassword ? "text" : "password"}
              // label="Old Password"
              name="oldPassword"
              aria-describedby="passwordHelp"
              placeholder="Enter Old Password"
              value={password.oldPassword}
              onChange={handleInputChange}
              isdarktheme={isDarkTheme ? "true" : "false"}
              label={
                <>
                  Old Password{renderAsterisk()}
                </>
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowPassword((prevData) => ({
                          ...prevData,
                          oldPassword: !showPassword.oldPassword,
                        }))
                      }
                      edge="end"
                    >
                      {showPassword.oldPassword ? (
                        <VisibilityOffIcon sx={{ color: iconColor }} />
                      ) : (
                        <VisibilityIcon sx={{ color: iconColor }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <ErrorTypography>
              {errors.oldPassword}
            </ErrorTypography>
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <InputTextField
              inputProps={{ sx: InputFieldProps(is_JT_theme_Dark) }}
              // required
              sx={{ ...InputFieldChangePassword, ...commonStyle }}
              type={showPassword.newPassword ? "text" : "password"}
              isdarktheme={isDarkTheme ? "true" : "false"}
              // label="New Password"
              name="newPassword"
              label={
                <>
                  New Password{renderAsterisk()}
                </>
              }
              aria-describedby="passwordHelp"
              placeholder="Enter New Password"
              value={password.newPassword}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowPassword((prevData) => ({
                          ...prevData,
                          newPassword: !showPassword.newPassword,
                        }))
                      }
                      edge="end"
                    >
                      {showPassword.newPassword ? (
                        <VisibilityOffIcon sx={{ color: iconColor }} />
                      ) : (
                        <VisibilityIcon sx={{ color: iconColor }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <ErrorTypography>
              {errors.newPassword}
            </ErrorTypography>
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <InputTextField
              inputProps={{ sx: InputFieldProps(is_JT_theme_Dark) }}
              sx={{ ...InputFieldChangePassword, ...commonStyle }}
              type={showPassword.confirmPassword ? "text" : "password"}
              isdarktheme={isDarkTheme ? "true" : "false"}
              name="confirmPassword"
              placeholder="Enter Confirm Password"
              label={
                <>
                  Confirm New Password{renderAsterisk()}
                </>
              }
              value={password.confirmPassword}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowPassword((prevData) => ({
                          ...prevData,
                          confirmPassword: !showPassword.confirmPassword,
                        }))
                      }
                      edge="end"
                    >
                      {showPassword.confirmPassword ? (
                        <VisibilityOffIcon sx={{ color: iconColor }} />
                      ) : (
                        <VisibilityIcon sx={{ color: iconColor }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <ErrorTypography>
              {errors.confirmPassword}
            </ErrorTypography>
          </Box>
          <ErrorMessageTypography>
            {displayError}
          </ErrorMessageTypography>
          <SaveButton
            onClick={handleSubmit}
            variant="contained"
          >
            Save New Password
          </SaveButton>
        </Box>
      </MainBox >
    </>)
  }

  return (
    <DialogComponent open={open} onClose={close} renderChildern={renderForm} maxWidth="md" />
  );
}

export default ChangePassword;
