import { Box, Button, DialogActions, FormControlLabel, IconButton, TextField, Typography, styled } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"

export const MainBox = styled(Box)(({ isdarktheme }) => ({
    background: isdarktheme === "true" ? "#121e2e" : "#ffffff",
    padding: "13px"
}))

export const InnerBoxOne = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
}))

export const HeaderBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
        maxWidth: "100%",
    },
}))

export const InnerBoxTwo = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const TitleBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#62626280",
    borderRadius: "1000px",
    padding: "8px 5px",
    marginRight: "15px",
}))

export const InnerBoxThree = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    justifyContent: "left",
    marginTop: "10px",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    '@media (max-width: 600px)': {
        width: "100%",
    },
}))

export const ErrorTypography = styled(Typography)(() => ({
    color: "red",
    marginTop: "0px",
}))

export const InnerBoxFour = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    marginTop: "10px",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    '@media (max-width: 600px)': {
        marginTop: "10px",
    },
}))

export const InnerBoxFive = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
}))

export const FormControlLabels = styled(FormControlLabel)(() => ({
    color: 'var(--secondary-text0-color)',
    ".MuiCheckbox-root": {
        color: "var(--secondary-text0-color) !important",
        "&.Mui-checked": {
            color: "var(--primary-color) !important",
        },
    },
}))

export const DialogsActions = styled(DialogActions)(() => ({
    background: "var(--highlight-color)",
    boxShadow: "0px 2px 10px var(--dark-highlight-color)",
}))

export const CancelButton = styled(Button)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    textTransform: "capitalize",
    fontWeight: "bold",
    marginRight: "0px",
    borderRadius: "5px",
}))

export const CustomButton = styled(Button)(() => ({
    borderRadius: "5px",
    textTransform: "capitalize",
}))

export const DateTextField = styled(TextField)(({ isdarktheme }) => ({
    width: "100%",
    color: isdarktheme === "true" ? "white" : "black",
    background: isdarktheme === "true" ? "#142840" : "#ffffff",
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
        filter: isdarktheme === "true" ? "invert(1)" : "none",
    },
    "& fieldset legend > span": {
        paddingLeft: "2px",
        paddingRight: "0px",
    },
    ".MuiOutlinedInput-input": {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
        borderColor: "white",
    },
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    ".MuiInputLabel-root": {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    ".MuiInputLabel-root.Mui-focused": {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
        borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
}));

export const CloseIconButton = styled(IconButton)(({ isdarktheme, ismobile }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    padding: ismobile === "true" ? "10px 0px" : "12px 0px",
}))

export const CustomTextField = styled(TextField)(({ isdarktheme }) => ({
    ".MuiOutlinedInput-input": {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
        borderColor: "white",
    },
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    ".MuiInputLabel-root": {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    ".MuiInputLabel-root.Mui-focused": {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
        borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    "& fieldset legend > span": {
        paddingLeft: "2px",
        paddingRight: "0px",
    },
}))

export const CustomDatePicker = styled(DatePicker)(({ isdarktheme }) => ({
    ".MuiOutlinedInput-input": {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
        borderColor: "white",
    },
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    ".MuiInputLabel-root": {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    ".MuiInputLabel-root.Mui-focused": {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
        borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    "& fieldset legend > span": {
        paddingLeft: "2px",
        paddingRight: "0px",
    },
}))