import { CardContent, Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import Timer from "./Timer";
import AttendanceTableComp from "./AttendanceTableComp";
import StatProgressBars from "./StatProgressBars";
import TodayActivity from "./TodayActivity";
import VerticalBarGraph from "./VerticalBarGraph";
import DateRangePickerDialog from "./DateRangePickerDialog";
import Loader from "../../Loader/Loader";
import { MainBox, LoadingBox, MainCard, TitleTypography, InnerCardOne, InnerBoxOne, CustomButton } from "../../../Styles/EmployeeManagement/Employee/AttendenceStyle"

function Attendence() {
  const isMobile = useMediaQuery('(max-width:600px)');

  const [dateRangeDialog, setDateRangeDialog] = useState(false);
  const [dateRange, setDateRange] = React.useState({});
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      !(Object.keys(dateRange).length === 0 && dateRange.constructor === Object)
    ) {
      const startDateFormatted = dayjs(dateRange.startDate).format(
        "DD/MM/YYYY"
      );
      const endDateFormatted = dayjs(dateRange.endDate).format("DD/MM/YYYY");
      setStartDate(startDateFormatted);
      setEndDate(endDateFormatted);
    }
  }, [dateRange]);

  return (
    <MainBox>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <Grid container item spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <MainCard>
              <CardContent>
                <Timer setIsLoading={setIsLoading} />
              </CardContent>
            </MainCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MainCard>
              <CardContent>
                <StatProgressBars />
              </CardContent>
            </MainCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MainCard>
              <CardContent>
                <TodayActivity />
              </CardContent>
            </MainCard>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <InnerCardOne>
              <InnerBoxOne sx={{
                display: isMobile && Object.keys(dateRange).length === 0 &&
                  dateRange.constructor === Object && "flex"
              }} ismobile={isMobile ? "true" : "false"}>
                <TitleTypography ismobile={isMobile ? "true" : "false"}>
                  Attendance Records
                </TitleTypography>
                {Object.keys(dateRange).length === 0 &&
                  dateRange.constructor === Object ? (
                  <>
                    <CustomButton
                      variant="outlined"
                      size="medium"
                      sx={{ justifyContent: "flex-end" }}
                      onClick={() => {
                        setDateRangeDialog(true);
                      }}
                    >
                      Filter
                    </CustomButton>
                  </>
                ) : (
                  <>
                    <CustomButton
                      ismobile={isMobile ? "true" : "false"}
                      variant="contained"
                      size="medium"
                      onClick={() => {
                        setDateRange({});
                        setStartDate("");
                        setEndDate("");
                      }}
                    >
                      {startDate === endDate
                        ? `Date: ${startDate}`
                        : `Date Range: ${startDate} to ${endDate}`}
                    </CustomButton>
                  </>
                )}
              </InnerBoxOne>
              <AttendanceTableComp
                startDate={startDate}
                endDate={endDate}
              />
            </InnerCardOne>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MainCard>
              <CardContent>
                <VerticalBarGraph />
              </CardContent>
            </MainCard>
          </Grid>
        </Grid>
      )}
      <DateRangePickerDialog
        open={dateRangeDialog}
        setOpenDialog={setDateRangeDialog}
        dialogHeading={"Date Range"}
        setDateRange={setDateRange}
      />
    </MainBox>
  );
}

export default Attendence;
