import { Box, Card, Grid, TextField, styled, Typography } from "@mui/material"
import CircleIcon from "@mui/icons-material/Circle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import CategoryIcon from "@mui/icons-material/Category";

export const LoadingBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh",
}))

export const SubTaskTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    fontWeight: "bold",
    marginRight: "16px",
    flexShrink: 0,
    '@media (max-width: 600px)': {
        marginRight: "10px",
    },
}))

export const TypographyActivity = styled(Typography)(() => ({
    zIndex: 1000,
    color: "white",
    fontSize: "15px",
    padding: "3px 7px",
    borderRadius: "5px",
    backgroundColor: "#1976d2"
}));

export const MainBox = styled(Box)(({ isdarktheme }) => ({
    backgroundColor: isdarktheme === "true" ? "#0b1727" : "#ffffff",
    overflowX: "hidden",
    overflowY: "hidden",
    height: "container",
    borderRadius: "inherit",
}))

export const InnerBoxOne = styled(Box)(({ isdarktheme, ismobile }) => ({
    cursor: "grab",
    backgroundColor: isdarktheme === "true"
        ? "#142840"
        : "#ffffff",
    position: "sticky",
    margin: "4px 8px 4px 8px",
    top: 0,
    zIndex: 99,
    display: ismobile !== "true" && "flex",
    flexDirection: ismobile === "true" ? "column" : "row",
    borderRadius: "5px",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 13px 10px -20px #111",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
}))

export const InnerBoxTwo = styled(Box)(({ ismobile, isdarktheme }) => ({
    backgroundColor: isdarktheme === "true"
        ? "#142840"
        : "#ffffff",
    margin: ismobile === "true" ? "5px 0px 0px 5px" : "5px 0px 0px 18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
}))

export const TitleBox = styled(Box)(({ isdarktheme }) => ({
    color: isdarktheme === "true"
        ? "#e0e0e0e3"
        : "#544f5a",
    fontWeight: "bold",
    marginRight: "10px",
    fontSize: "20px",
    display: "flex",
}))

export const ApprovedBox = styled(Box)(() => ({
    padding: "2px 0px 4px 15px",
    display: "flex",
}))

export const DueDateBox = styled(Box)(({ theme, ismobile }) => ({
    fontSize: ismobile === "true" ? "15px" : "12px",
    paddingRight: "20px",
    display: "flex",
    alignItems: "center",
    marginBottom: ismobile === "true" && "2px",
    // justifyContent: ismobile === "true" && "space-between"
}))

export const EditDueDateBox = styled(Box)(({ theme, ismobile }) => ({
    paddingTop: theme.spacing(2), // Use theme spacing for consistent spacing
    '& .MuiFormControl-root.MuiTextField-root': {
        display: "contents",
    },
    '& .MuiIconButton-edgeEnd.MuiIconButton-sizeMedium': {
        padding: "0px !important",
    },
    marginTop: ismobile === "true" && "6px",
    marginBottom: ismobile === "true" && "6px"
}));

export const CreatedOnBox = styled(Box)(({ isdarktheme, theme, ismobile }) => ({
    color: isdarktheme === "true"
        ? "#e0e0e0cc"
        : "#5e6e82",
    fontSize: ismobile === "true" ? "15px" : "12px",
    paddingTop: theme.spacing(1)
    // paddingTop: "5px",
}))

export const CloseBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    marginTop: "5px",
    "@media (max-width: 600px)": {
        marginBottom: "15px",
        padding: "0px"
    },
}))

export const MainGrid = styled(Grid)(() => ({
    overflowX: "hidden",
    padding: "4px 8px 8px 8px",
    position: "relative",
    zIndex: 0,
}))

export const MainCard = styled(Card)(({ cardstyle, isMobile }) => ({
    height: isMobile ? "70vh" : "80vh",
    overflowY: "scroll",
    ...cardstyle,
    ".MuiCardContent-root:last-child": {
        paddingBottom: "8px",
    },
}))

export const TaskTitleTextField = styled(TextField)(({ isdarktheme }) => ({
    fontSize: '28px',
    fontWeight: 'bold',
    paddingLeft: '10px',
    color: isdarktheme === "true" ? '#e6e6e6' : 'black',
    padding: "10px",
    ".MuiOutlinedInput-input": {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
        borderColor: "white",
    },
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    ".MuiInputLabel-root": {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    ".MuiInputLabel-root.Mui-focused": {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
        borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
}))

export const TicketNoBox = styled(Box)(({ isdarktheme }) => ({
    flex: '1 1 auto',
    width: '100%',
    fontSize: '22px',
    fontWeight: 'bold',
    paddingLeft: '10px',
    color: isdarktheme === "true" ? '#e6e6e6' : 'black',
    cursor: 'pointer',
}))

export const CategoryIcons = styled(CategoryIcon)(() => ({
    fontSize: "10px",
    marginTop: "4px",
    paddingRight: "2px",
    fill: "orange",
}))

export const CircleIcons = styled(CircleIcon)(() => ({
    fontSize: "14px",
    marginTop: "4px",
    paddingRight: "2px",
    fill: "#CC7722",
}))

export const TaskTypeCircleIcon = styled(CircleIcon)(() => ({
    fontSize: "14px",
    marginTop: "4px",
    paddingRight: "2px",
    fill: "purple",
}))

export const HoursAccessTimeIcon = styled(AccessTimeIcon)(() => ({
    fontSize: "15px",
    color: "var(--primary-text-color)",
    mr: 0.5,
    mt: 0.5,
    fill: "green",
}))

export const DoneIcons = styled(DoneIcon)(() => ({
    paddingLeft: "10px",
    color: "var(--start-color)",
    marginTop: "0px",
    position: "relative",
}))

export const ClearIcons = styled(ClearIcon)(() => ({
    paddingRight: "10px",
    color: "var(--late-owl-point-color)",
    marginTop: "0px",
    position: "relative",
}))

export const ActualHourBox = styled(Box)(({ isdarktheme }) => ({
    width: "30px",
    height: "15px",
    borderRadius: "50%",
    backgroundColor: isdarktheme === "true" ? "#142840" : "#ffffff",
    display: "flex",
    padding: "2px 0px 0px 4px",
}))

export const PriorityTextField = styled(TextField)(() => ({
    ".MuiOutlinedInput-input":
    {
        padding: "10px 0px 5px 10px",
        borderColor: "white",
    },
}))

export const DescriptionTextField = styled(TextField)(({ isDarkTheme }) => ({
    ".MuiOutlinedInput-input":
    {
        color: isDarkTheme
            ? "#e0e0e0cc !important"
            : "#5e6e82 !important",
    },
}))

export const SideBox = styled(Box)(({ isdarktheme }) => ({
    position: "fixed",
    top: 0,
    right: 0,
    width: "45%",
    // height: "100vh",
    background: isdarktheme === "true"
        ? "#0b1727"
        : "#ffffff",
    borderRadius: "0.5%",
    zIndex: 999,
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
    overflowY: "auto",
}))

export const CollapseTypography = styled(Typography)(({ isDarkTheme }) => ({
    backgroundColor: "white",
    padding: "4px 8px",
    borderRadius: "4px",
    boxShadow: 2,
}))