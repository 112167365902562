import { Box, Button, Grid, styled } from "@mui/material"

export const SignUpBox = styled(Box)(({ isjtthemedark }) => ({
    height: "100vh",
    background: isjtthemedark === "true"
        ? "var(--darkTheme-before-login-bg)"
        : "var(--lightTheme-before-login-bg)",
}))

export const InnerBoxOne = styled(Box)(({ isjtthemedark }) => ({
    display: "flex",
    justifyContent: "center",
    background: isjtthemedark === "true"
        ? "var(--darkTheme-before-login-bg)"
        : "var(--lightTheme-before-login-bg)",
    "@media (max-width: 600px)": {
        background: isjtthemedark === "true"
            ? "var(--darkTheme-before-login-sideblock-bg)"
            : "var(--lightTheme-before-login-bg)",
    },
}))

export const InnerBoxTwo = styled(Box)(() => ({
    display: "flex",
    margin: "20px 0px 20px 0px",
    justifyContent: "center",
    alignItems: "center",
    width: "1000px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.14)",
    "@media (max-width: 600px)": {
        boxShadow: "none",
    },
}))

export const MainGrid = styled(Grid)(({ isjtthemedark }) => ({
    display: "flex",
    alignContent: "center",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: isjtthemedark === "true"
        ? "var(--darkTheme-before-login-img-bg)"
        : "var(--lightTheme-before-login-img-bg)",
}))

export const InnerBoxThree = styled(Box)(() => ({
    flex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const InnerBoxFour = styled(Box)(() => ({
    height: "100%",
    flex: "1",
    padding: "60px 60px 40px 40px",
    "@media (max-width: 600px)": {
        padding: "0px 60px 0px 40px",
    },
}))

export const InputsBoxs = styled(Box)(({ isjtthemedark }) => ({
    marginTop: "15px",
    ".MuiOutlinedInput-input": {
        color: isjtthemedark === "true"
            ? "var(--darkTheme-inputfield-color) !important"
            : "var(--lightTheme-inputfield-color) !important",
    },
    ".MuiFormLabel-root": {
        color: isjtthemedark === "true"
            ? "var(--darkTheme-inputfield-color) !important"
            : "var(--lightTheme-inputfield-color) !important",
    },
}))

export const InputSelectBox = styled(Box)(({ isjtthemedark }) => ({
    marginTop: "15px",
    ".MuiOutlinedInput-input": {
        color: isjtthemedark === "true"
            ? "var(--darkTheme-inputfield-color) !important"
            : "var(--lightTheme-inputfield-color) !important",
    },
    ".MuiFormLabel-root": {
        color: isjtthemedark === "true"
            ? "var(--darkTheme-inputfield-color) !important"
            : "var(--lightTheme-inputfield-color) !important",
    },
    ".MuiSvgIcon-root": {
        fill: isjtthemedark === "true"
            ? "var(--darkTheme-inputfield-color) !important"
            : "var(--lightTheme-inputfield-color) !important",
    },
}))

export const SignUpButton = styled(Button)(() => ({
    width: "100%",
    marginTop: "10px",
    background: "var(--secondary-color)",
    color: "var(--secondary-color",
    fontWeight: "bold",
    "&:hover": {
        background: "red",
        color: "var(--secondary-color)",
    },
}))

