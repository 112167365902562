import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  InputAdornment,
  Typography,
} from "@mui/material";

import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../../Slices/toastSlice";
import {
  viewEducationBox,
  viewProfileTitle,
} from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import { setAllData } from "../../../Slices/UserDataSlice";
import { setAllLeaveData } from "../../../Slices/leaveData";
import {
  resetEmployeeDetail,
  setEmployeeAdded,
  set_family,
} from "../../../Slices/employeeDetailSlice";
import ExitDialog from "../ExitDialog";
import { renderAsterisk } from "../../ReusableComponents/UtilityFunctions.js";
import { errorMessage } from "../../../Services/axiosInstance";
import { upsertFamily } from "../../../Services/EmployeeManageServices";
import { MainBox, TitleBox, AddRecordBox, InputTextField, PhoneTextField } from "../../../Styles/UpdateEmployee/UpdateFamilyStyle";
import { FooterBox } from "../../../Styles/UpdateEmployee/UpdatePersonalStyle";
import { CancelButton, SaveButton, SaveNextButton } from "../../../Styles/UpdateEmployee/UpdateContactStyle.js";

const DisplayFamily = (props) => {
  const { saveNextButtonCallback, previousButtonCallback } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const employee_id = useSelector((state) => state.addEmployee.personal.id);
  const family = useSelector((state) => state.addEmployee.family);

  const [displayError, setDisplayError] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState("");
  const [openExitDialog, setOpenExitDialog] = useState(false);
  const [familyDetailsArray, setFamilyDetailsArray] = useState(
    family ? family : []
  );
  const [familyDetailsErrorsArray, setFamilyDetailsErrorsArray] = useState([]);

  const nullRecord = () => {
    setFamilyDetailsArray(family ? family : []);
    setFamilyDetailsErrorsArray([]);
  };

  const reset = () => {
    nullRecord();
  };

  const handleCloseCancelDialog = async () => {
    setOpenExitDialog(false);
  };

  const getEmptyFamilyRecord = () => ({
    FirstName: "",
    lastName: "",
    Relation: "",
    Email: "",
    Phone_Number: "",
    Date_Of_Birth: "",
  });

  const handleInputChange1 = (index, event) => {
    const { name, value } = event.target;
    setFamilyDetailsArray((prevArray) =>
      prevArray?.map((record, i) =>
        i === index ? { ...record, [name]: value } : record
      )
    );
  };

  const removeRecord = (index) => {
    setFamilyDetailsArray((prevArray) =>
      prevArray.filter((_, i) => i !== index)
    );
    setFamilyDetailsErrorsArray((prevErrorsArray) =>
      prevErrorsArray.filter((_, i) => i !== index)
    );
  };

  const getEmptyFamilyError = () => ({
    FirstName: "",
    lastName: "",
    Relation: "",
    Email: "",
    Phone_Number: "",
    Date_Of_Birth: "",
  });

  const addRecord = () => {
    if (validateAllForms()) {
      setFamilyDetailsArray((prevArray) => [
        ...prevArray,
        getEmptyFamilyRecord(),
      ]);
      setFamilyDetailsErrorsArray((prevErrorsArray) => [
        ...prevErrorsArray,
        getEmptyFamilyError(),
      ]);
    }
  };

  const validateAllForms = () => {
    const allErrors = familyDetailsArray?.map((record, index) =>
      validateForm(index)
    );
    setFamilyDetailsErrorsArray(allErrors);
    return allErrors.every((errors) =>
      Object.values(errors).every((error) => !error)
    );
  };

  const validateForm = (index) => {
    const record = familyDetailsArray[index];
    const errors = getEmptyFamilyError();

    if (!record.FirstName) {
      errors.FirstName = "First name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(record.FirstName)) {
      errors.FirstName = "First name should not contain numeric values";
    }

    if (!record.lastName) {
      errors.lastName = "Last name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(record.lastName)) {
      errors.lastName = "Last name should not contain numeric values";
    }

    if (!record.Relation) {
      errors.Relation = "Family relation is required";
    } else if (!/^[a-zA-Z\s]*$/.test(record.Relation)) {
      errors.Relation = "Family relation should not contain numeric values";
    }

    if (!record.Email) {
      errors.Email = "Email address is required";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(record.Email)
    ) {
      errors.Email = "Invalid email format";
    }

    if (!record.Phone_Number) {
      errors.Phone_Number = "Mobile number is required";
    } else if (!/^\d{10}$/.test(record.Phone_Number)) {
      errors.Phone_Number = "Mobile number must be exactly 10 digits";
    } else if (!/^[6-9]\d{9}$/.test(record.Phone_Number)) {
      errors.Phone_Number = "Mobile number is not valid";
    }

    if (!record.Date_Of_Birth) {
      errors.Date_Of_Birth = "Date of birth is required";
    }

    return errors;
  };

  const save = async () => {
    setDisplayError("");
    if (displaySuccess === "employee added.") {
    } else {
      try {
        // const response = await axios.post(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/family`,
        //   JSON.stringify({ employee_id, familyDetailsArray }),
        //   postHeader
        // );
        const postData = JSON.stringify({ employee_id, familyDetailsArray });
        const response = await upsertFamily(postData)
        if (response.status === 200) {
          dispatch(setAllData(response.data.user));
          setDisplaySuccess("")
          navigate("/dashboard");
          dispatch(resetEmployeeDetail());
          dispatch(setAllLeaveData(response.data.leave));
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Employee data updated successfully."));
          dispatch(setToastType("success"));
          dispatch(set_family(familyDetailsArray));
        }
      } catch (error) {
        setDisplayError(errorMessage ?? "")
      }
    }
  };

  const saveAndNext2 = async () => {
    setDisplayError("");
    if (displaySuccess === "employee added.") {
      saveNextButtonCallback();
    } else {
      try {
        // const response = await axios.post(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/family`,
        //   JSON.stringify({ employee_id, familyDetailsArray }),
        //   postHeader
        // );
        const postData = JSON.stringify({ employee_id, familyDetailsArray });
        const response = await upsertFamily(postData)
        if (response.status === 200) {
          dispatch(setAllData(response.data.user));
          dispatch(setAllLeaveData(response.data.leave));
          saveNextButtonCallback();
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Employee data updated successfully."));
          dispatch(setToastType("success"));
          dispatch(set_family(familyDetailsArray));
        }
      } catch (error) {
        setDisplayError(errorMessage ?? "")
      }
    }
  };

  return (
    <MainBox>
      <TitleBox>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Family Details
          </Typography>
        </Box>
        <Box></Box>
      </TitleBox>
      <hr />
      <AddRecordBox>
        <Typography sx={{ ...viewProfileTitle }}>Family Records</Typography>
        <Button
          variant="outlined"
          sx={{ color: "var(--primary-color)", fontWeight: "bold" }}
          onClick={() => addRecord()}
        >
          + Add Record
        </Button>
      </AddRecordBox>
      <Box>
        {Array.isArray(familyDetailsArray) &&
          familyDetailsArray?.map((record, index) => (
            <Box key={index} sx={viewEducationBox}>
              <Box sx={{ marginBottom: "15px" }}>
                <InputTextField
                  type="text"
                  name="FirstName"
                  // label="First Name"
                  value={familyDetailsArray[index].FirstName}
                  onChange={(event) => handleInputChange1(index, event)}
                  label={
                    <>
                      First Name
                      {renderAsterisk()}
                    </>
                  }
                />
                {familyDetailsErrorsArray[index]?.FirstName && (
                  <Typography color="error">
                    {familyDetailsErrorsArray[index].FirstName}
                  </Typography>
                )}

                <InputTextField
                  name="lastName"
                  type="text"
                  value={familyDetailsArray[index].lastName}
                  // label="Last Name"
                  onChange={(event) => handleInputChange1(index, event)}
                  label={
                    <>
                      Last Name
                      {renderAsterisk()}
                    </>
                  }
                />
                {familyDetailsErrorsArray[index]?.lastName && (
                  <Typography color="error">
                    {familyDetailsErrorsArray[index].lastName}
                  </Typography>
                )}

                <InputTextField
                  type="text"
                  name="Relation"
                  value={familyDetailsArray[index].Relation}
                  // label="Family Relation"
                  onChange={(event) => handleInputChange1(index, event)}
                  label={
                    <>
                      Family Relation
                      {renderAsterisk()}
                    </>
                  }
                />
                {familyDetailsErrorsArray[index]?.Relation && (
                  <Typography color="error">
                    {familyDetailsErrorsArray[index].Relation}
                  </Typography>
                )}

                <InputTextField
                  type="email"
                  name="Email"
                  value={familyDetailsArray[index].Email}
                  // label="Family Email"
                  onChange={(event) => handleInputChange1(index, event)}
                  label={
                    <>
                      Family Email
                      {renderAsterisk()}
                    </>
                  }
                />
                {familyDetailsErrorsArray[index]?.Email && (
                  <Typography color="error">
                    {familyDetailsErrorsArray[index].Email}
                  </Typography>
                )}

                <PhoneTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                  type="text"
                  name="Phone_Number"
                  value={familyDetailsArray[index].Phone_Number}
                  // label="Mobile Number"
                  label={
                    <>
                      Mobile Number
                      {renderAsterisk()}
                    </>
                  }
                  onChange={(event) => handleInputChange1(index, event)}
                  inputProps={{ maxLength: 10 }}
                />
                {familyDetailsErrorsArray[index]?.Phone_Number && (
                  <Typography color="error">
                    {familyDetailsErrorsArray[index].Phone_Number}
                  </Typography>
                )}

                <InputTextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start" />,
                  }}
                  isdarktheme={isDarkTheme ? "true" : "false"}
                  name="Date_Of_Birth"
                  type="date"
                  value={familyDetailsArray[index].Date_Of_Birth}
                  // label="D.O.B"
                  onChange={(event) => handleInputChange1(index, event)}
                  label={
                    <>
                      D.O.B
                      {renderAsterisk()}
                    </>
                  }
                />
                {familyDetailsErrorsArray[index]?.Date_Of_Birth && (
                  <Typography color="error">
                    {familyDetailsErrorsArray[index].Date_Of_Birth}
                  </Typography>
                )}
              </Box>
              {/* Remove button for record */}
              <Button
                variant="outlined"
                color="error"
                sx={{ fontWeight: "bold" }}
                onClick={() => removeRecord(index)}
              >
                Remove
              </Button>
            </Box>
          ))}
      </Box>
      <Typography sx={{ color: "red", marginTop: "20px" }}>
        {displayError}
      </Typography>
      <Typography sx={{ color: "green", marginTop: "20px" }}>
        {displaySuccess}
      </Typography>
      <Box>
        <FooterBox>
          <Button
            sx={{
              fontWeight: "bold",
              color: "gray",
            }}
            onClick={() => {
              previousButtonCallback();
            }}
          >
            Back
          </Button>
          <CancelButton
            onClick={() => {
              setOpenExitDialog(true);
              dispatch(setEmployeeAdded(""));
            }}
          >
            Cancel
          </CancelButton>
          <ExitDialog
            open={openExitDialog}
            reset={reset}
            onClose={handleCloseCancelDialog}
          />
          <SaveButton
            onClick={() => {
              if (validateAllForms()) {
                save();
                dispatch(setEmployeeAdded(""));
              }
            }}
          >
            Save
          </SaveButton>
          <SaveNextButton
            variant="contained"
            onClick={() => {
              if (validateAllForms()) {
                saveAndNext2();
              }
            }}
          >
            Save & Next
          </SaveNextButton>
        </FooterBox>
      </Box>
    </MainBox>
  );
};

export default DisplayFamily;
