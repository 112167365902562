import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  CardContent,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  Zoom,
  useMediaQuery,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dayjs from "dayjs";

import { setAllEmployeeAttendenceData } from "../../../Slices/UserDataSlice.js";
import { setToast, setToastMsg, setToastType } from "../../../Slices/toastSlice.js";
import { formatDateSlashTo_, renderAsterisk } from "../../ReusableComponents/UtilityFunctions.js";
import { editTimer } from "../../../Services/AttendanceManageServices.js";
import { errorMessage } from "../../../Services/axiosInstance.js";
import { CustomDateTimePicker, SaveButton, DateTypography, EditTimeMainBox, EditTimeMainCard, EditTimeTitleBox, Timelines } from "../../../Styles/UpdateEmployee/UpdateAttendanceStyle.js";
import { CancelButton } from "../../../Styles/UpdateEmployee/UpdateContactStyle.js";

function EditTimer({ change, record, empId, presentDate }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  const [id, setId] = useState("");
  const [errorReason, setErrorReason] = useState("");
  const [errorStart, setErrorStart] = useState("");
  const [errorEnd, setErrorEnd] = useState("");
  const [displayError, setDisplayError] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState("");
  const [selectedRecordReason, setSelectedRecordReason] = useState("");
  const [selectedDate, setSelectedDate] = useState(0);
  const [disableSave, setDisableSave] = useState(false);
  const [startTimeSelected, setStartTimeSelected] = useState(
    dayjs("2022-04-17T15:30")
  );
  const [endTimeSelected, setEndTimeSelected] = useState(
    dayjs("2022-04-17T15:30")
  );
  const [edit, setEdit] = useState(false);
  const records = record;

  const handleInputChange = (event) => {
    setErrorReason("");
    setSelectedRecordReason(event.target.value);
  };

  function extractTime(timestamp) {
    if (timestamp === 0) {
      return "00:00";
    }
    const dateTime = new Date(timestamp);
    const time = dateTime.toLocaleTimeString([], {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    }); // Extract time part
    return time;
  }

  function formatDateTime(timestamp) {
    if (timestamp === 0) {
      const dateTime = new Date();
      const year = dateTime.getFullYear();
      const month = String(dateTime.getMonth() + 1).padStart(2, "0");
      const day = String(dateTime.getDate()).padStart(2, "0");
      const hours = String(dateTime.getHours()).padStart(2, "0");
      const minutes = String(dateTime.getMinutes()).padStart(2, "0");
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    } else {
      const dateTime = new Date(timestamp);
      const year = dateTime.getFullYear();
      const month = String(dateTime.getMonth() + 1).padStart(2, "0");
      const day = String(dateTime.getDate()).padStart(2, "0");
      const hours = String(dateTime.getHours()).padStart(2, "0");
      const minutes = String(dateTime.getMinutes()).padStart(2, "0");
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    }
  }

  const updatedRecord = (index) => {
    setDisplayError("");
    setDisplaySuccess("");
    setEdit(true);
    setId(records[index]._id);
    setSelectedDate(dayjs(formatDateTime(records[index].startTime)));
    setSelectedRecordReason(records[index].reason);
    setStartTimeSelected(dayjs(formatDateTime(records[index].startTime)));
    setEndTimeSelected(dayjs(formatDateTime(records[index].endTime)));
  };

  const saveTime = async () => {
    const isSamestart =
      dayjs(startTimeSelected).isSame(selectedDate, "day") &&
      dayjs(startTimeSelected).isSame(selectedDate, "month") &&
      dayjs(startTimeSelected).isSame(selectedDate, "year");
    const isSameEnd =
      dayjs(endTimeSelected).isSame(selectedDate, "day") &&
      dayjs(endTimeSelected).isSame(selectedDate, "month") &&
      dayjs(endTimeSelected).isSame(selectedDate, "year");
    const startTimestamp = startTimeSelected.valueOf();
    const endTimestamp = endTimeSelected.valueOf();
    if (!isSamestart) {
      setErrorStart("Invalid Date.");
    } else if (!isSameEnd) {
      setErrorEnd("Invalid Date.");
    } else if (!selectedRecordReason) {
      setErrorReason("Reason Required.");
    } else if (startTimestamp > endTimestamp) {
      setErrorStart("Invalid Time.");
    } else if (!endTimestamp) {
      setErrorEnd("Invalid Time.");
    } else if (!startTimestamp) {
      setErrorStart("Invalid Time.");
    } else {
      setErrorStart("");
      if (errorReason === "" && errorEnd === "" && errorStart === "") {
        // return true;
        const totalTime = endTimestamp - startTimestamp;
        try {
          setDisableSave(true)
          const postData = JSON.stringify({ id, totalTime, endTimestamp, startTimestamp, selectedRecordReason, empId, });
          const response = await editTimer(postData)
          if (response.status === 201 || response.status === 200) {
            setDisplaySuccess(response.data.message);
            setEdit(false);
            dispatch(setAllEmployeeAttendenceData(response.data.attendence));
            setDisableSave(false)
            dispatch(setToast(true));
            dispatch(setToastMsg(response.data.message));
            dispatch(setToastType("success"));
          }
        } catch (error) {
          setDisplayError(errorMessage ?? "")
          setDisableSave(false)
        }
      }
    }
  };
  return (
    <EditTimeMainBox>
      <EditTimeTitleBox>
        {/* Box for items on the left */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={change}>
            <ArrowBackIcon color="primary" />
          </IconButton>

          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Update Timer
          </Typography>
        </Box>
        {/* Box for items on the right */}
      </EditTimeTitleBox>
      <EditTimeMainCard>
        <CardContent sx={{ padding: isMobile ? "16px 0px" : "16px" }}>
          <Box sx={{ margin: "0px 10px 0px 10px" }}>
            <Box sx={{ color: "var(--secondary-text-color)" }}>
              <DateTypography
                variant="h6">
                Date:- {formatDateSlashTo_(presentDate)}
              </DateTypography>
            </Box>
            <Box>
              {records?.length === 0 ?
                <Typography sx={{ display: 'flex', justifyContent: 'center', color: 'var(--secondary-text-color)' }}>Not Punched In</Typography>
                : null}
              {records.map((record, index) => (
                <Timelines sx={{ margin: isMobile ? "7px 0px 16px 0px" : "16px 0px 16px 0px" }} align="left" key={index}>
                  <TimelineItem key={index}>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent key={index}>
                      <Box key={index}>
                        <TextField
                          disabled
                          label="Start Time"
                          value={extractTime(record.startTime)}
                          style={{ padding: isMobile ? "0px 0px 15px 0px" : "0px 10px 10px 0px" }}
                        />
                        <TextField
                          disabled
                          label="End Time"
                          value={extractTime(record.endTime)}
                          style={{ padding: isMobile ? "0px 0px 15px 0px" : "0px 10px 10px 0px" }}
                        />
                        <TextField
                          label="Reason"
                          disabled
                          value={record.reason}
                        />
                        <Tooltip
                          title="Edit Data"
                          arrow
                          disableInteractive
                          TransitionComponent={Zoom}
                        >
                          <IconButton
                            onClick={() => updatedRecord(index)}
                            sx={{ color: "var( --secondary-text-color)" }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                </Timelines>
              ))}
            </Box>
            <hr />
            {edit ? (
              <>
                <Box sx={{ margin: "20px 0px 0px 0px" }}>
                  <Box sx={{ flexDirection: isMobile ? "column" : "row" }} display="flex">
                    <Box sx={{ padding: isMobile ? "10px 0px 10px 20px" : "10px 10px 10px 10px" }}>
                      <CustomDateTimePicker
                        // label="Start Time"
                        value={startTimeSelected}
                        onChange={(newValue) => {
                          setErrorStart("");
                          setStartTimeSelected(newValue);
                        }}
                        label={
                          <>
                            Start Time
                            {renderAsterisk()}
                          </>
                        }
                      />
                      <Typography sx={{ color: "red" }}>
                        {errorStart}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ padding: isMobile ? "10px 0px 10px 20px" : "10px 10px 10px 10px" }}
                    >
                      <CustomDateTimePicker
                        // label="End Time"
                        value={endTimeSelected}
                        onChange={(newValue) => {
                          setEndTimeSelected(newValue);
                          setErrorEnd("");
                        }}
                        label={
                          <>
                            End Time
                            {renderAsterisk()}
                          </>
                        }
                      />
                      <Typography sx={{ color: "red" }}>{errorEnd}</Typography>
                    </Box>
                    <Box
                      sx={{ padding: isMobile ? "10px 0px 10px 20px" : "10px 10px 10px 10px" }}
                    >
                      <TextField
                        // label="Reason"
                        value={selectedRecordReason}
                        onChange={handleInputChange}
                        label={
                          <>
                            Reason
                            {renderAsterisk()}
                          </>
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          "& fieldset legend > span": {
                            paddingLeft: "2px",
                            paddingRight: "0px",
                          },
                        }}
                      />
                      <Typography sx={{ color: "red" }}>
                        {errorReason}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      padding: "10px 0px 0px 0px",
                      display: "flex",
                      justifyContent: isMobile && "flex-end"
                    }}
                  >
                    <SaveButton
                      sx={{ padding: isMobile ? "3px 8px" : "6px 16px", marginRight: "5px" }}
                      variant="contained"
                      onClick={saveTime}
                      disabled={disableSave}
                    >
                      Save
                    </SaveButton>

                    {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                      {saveMessage && (
                        <Typography
                          sx={{
                            mr: 2,
                            color: isDarkTheme ? "#e0e0e0" : "#5e6e82",
                          }}
                        >
                          {saveMessage}
                        </Typography>
                      )}
                      {displayError && (
                        <Typography sx={{ mr: 2, color: "red" }}>
                          {displayError}
                        </Typography>
                      )}
                    </Box> */}
                    <CancelButton
                      disabled={disableSave}
                      isdarktheme={isDarkTheme ? "true" : "false"}
                      sx={{ padding: isMobile ? "3px 8px" : "6px 16px" }}
                      onClick={() => { setEdit(false); setDisableSave(false) }}
                    >
                      Cancel
                    </CancelButton>
                  </Box>
                </Box>
              </>
            ) : null}
            <Typography sx={{ color: "red" }}>{displayError}</Typography>
            <Typography sx={{ color: "green" }}>{displaySuccess}</Typography>
          </Box>{" "}
        </CardContent>
      </EditTimeMainCard>
    </EditTimeMainBox>
  );
}

export default EditTimer;
