import {
  Typography,
  Button,
  styled,
  IconButton,
  Box,
  DialogActions,
  TextField,
  Card,
  LinearProgress,
} from "@mui/material";

export const TitleTypography = styled(Typography)(() => ({
  color: "var(--primary-color)",
  fontWeight: "bold",
}));

export const TextTypography = styled(Typography)(() => ({
  color: "var(--secondary-text-color)",
}));

export const CancelButton = styled(Button)(({ isdarktheme }) => ({
  color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
  textTransform: "capitalize",
  fontWeight: "bold",
  borderRadius: "5px",
}));

export const SaveButton = styled(Button)(() => ({
  // fontWeight: "bold",
  textTransform: "capitalize",
  // fontSize: "18px",
}));

export const AddButton = styled(Button)(() => ({
  textTransform: "capitalize",
}));

export const InnerBoxOneDialog = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const HeaderBox = styled(Box)(({}) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "10px",
  marginTop: "10px",
  "@media (max-width: 600px)": {
    justifyContent: "flex-start",
    flexDirection: "column",
  },
}));

export const ClearIconButton = styled(IconButton)(({ isDarkTheme }) => ({
  position: "absolute",
  right: "20px",
  top: "50%",
  transform: "translateY(-50%)",
  color: isDarkTheme ? "#e0e0e0e3" : "var(--primary-text-color)",
  "& .MuiSvgIcon-root": {
    fontSize: "16px",
  },
}));

export const FilterBox = styled(Box)(({ ismobile }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  marginBottom: ismobile ? "10px" : "0px",
  flexDirection: ismobile ? "column" : "row",
}));

export const MainBoxDialog = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "@media (max-width: 600px)": {
    maxWidth: "100%",
  },
}));

export const InnerBoxTwoDialog = styled(Box)(({ isdarktheme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: isdarktheme === "true" ? "#ff000046" : "#ff000221",
  borderRadius: "1000px",
  padding: "8px",
}));

export const CloseIconButton = styled(IconButton)(
  ({ isdarktheme, ismobile }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    padding: "0px 0px",
  })
);

export const ErrorTypography = styled(Typography)(() => ({
  color: "red",
  paddingLeft: "20",
  marginTop: "0px",
}));
export const DialogsActions = styled(DialogActions)(({ displayError }) => ({
  background: "var(--highlight-color)",
  boxShadow: "0px 2px 10px var(--dark-highlight-color)",
  display: "flex",
  justifyContent: displayError && "space-between",
  alignItems: "center",
  "@media (max-width: 600px)": {
    flexDirection: displayError && "column",
    justifyContent: displayError && "flex-end",
  },
}));

export const InputTextField = styled(TextField)(({ isdarktheme }) => ({
  ".MuiOutlinedInput-input": {
    color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    borderColor: "white",
  },
  ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
  },
  ".MuiInputLabel-root": {
    color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
  },
  ".MuiInputLabel-root.Mui-focused": {
    color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
  },
  svg: {
    fill: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
  },
  color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#484848",
  "& fieldset legend > span": {
    paddingLeft: "2px",
    paddingRight: "0px",
  },
}));

export const SideBox = styled(Box)(({ isdarktheme }) => ({
  position: "fixed",
  top: 0,
  right: 0,
  width: "40%",
  height: "100vh",
  background: isdarktheme === "true" ? "#0b1727" : "#ffffff",
  borderRadius: "5px",
  zIndex: 999,
  boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
  overflowY: "auto",
  "@media (max-width: 600px)": {
    width: "100%",
  },
}));

export const MainCard = styled(Card)(() => ({
  boxShadow: 4,
  width: "100%",
  marginTop: "10px",
  background: "var(--background-table-sidebar-card-color)",
}));

export const MainBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "0px 13px 10px -20px #111",
  borderBottom: "1px solid var(--table-border-color)",
  color: "var(--secondary-text-color)",
  marginBottom: "5px",
}));

export const CloseBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  padding: "0px 0px 5px 0px",
}));

export const InnerBoxOne = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const ComplateSprintBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  margin: "0px 5px 0px 5px",
  "@media (max-width: 600px)": {
    padding: "0px 0px 0px 0px",
  },
}));

export const AddSprintBox = styled(Box)(() => ({
  padding: "5px 0px 0px 18px",
  display: "flex",
  "@media (max-width: 600px)": {
    padding: "0px 0px 0px 0px",
  },
}));

export const CompletedSprint = styled(Button)(() => ({
  backgroundColor: "var(--secondary-color)",
  "&:hover": {
    color: "none",
    backgroundColor: "var(--secondary-color-H)",
  },
  color: "#ffffff",
  marginLeft: "5px",
}));

export const EditIconButton = styled(IconButton)(({ isCompleted }) => ({
  color: "var(--secondary-text-color)",
  cursor: isCompleted ? "not-allowed !important" : "pointer",
  "&.Mui-disabled": {
    color: "var(--secondary-text-color)",
    opacity: 0.4,
  },
}));

export const DeleteIconButton = styled(IconButton)(
  ({ isdarktheme, isCompleted }) => ({
    color: isdarktheme === "true" ? "#d94a38" : "#c62828",
    cursor: isCompleted ? "not-allowed !important" : "pointer",
    "&.Mui-disabled": {
      color: "#d94a38",
      opacity: 0.4,
    },
  })
);

export const LinearsProgress = styled(LinearProgress)(() => ({
  margin: "5px 10px 5px 0px",
  borderRadius: "5px",
  width: "100%",
  height: "7px",
  backgroundColor: "var(--secondary-highlight-color)",
  ".MuiLinearProgress-bar": {
    backgroundColor: "var(--secondary-color)",
  },
  "@media (max-width: 600px)": {
    width: "100%",
  },
}));

export const InnerBoxThree = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "500px",
  "@media (max-width: 600px)": {
    maxWidth: "100%",
  },
}));

export const InnerBoxFour = styled(Box)(() => ({
  display: "flex",
  // alignItems: "center",
  justifyContent: "center",
  marginTop: "0px",
}));

export const InnerBoxFive = styled(Box)(({ isdarktheme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: isdarktheme === "true" ? "#ff000046" : "#ff000221",
  borderRadius: "50%",
  padding: "8px",
  marginRight: "10px",
}));

export const CloseButton = styled(IconButton)(({ isdarktheme }) => ({
  color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "5px",
  right: "-5px",
  fontWeight: "bold",
  textTransform: "capitalize",
  marginRight: "15px",
  fontSize: "2px",
  "&:hover": {
    background: "var(--highlight-color)",
  },
}));

export const CommanTabBox = styled(Box)(() => ({
  "@media (max-width: 600px)": {
    display: "flex",
    flexDirection: "row",
    flexShrink: "0",
    minWidth: "100%",
    justifyContent: "center",
  },
}));

export const InnerSliderBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  overflowY: "hidden",
}));

export const ActiveSprintButton = styled(Button)(({ selectedbutton }) => ({
  fontWeight: "bold",
  borderRadius: "0px",
  color:
    selectedbutton === "Active Sprints"
      ? "#338BA8"
      : "var(--secondary-text-color)",
  borderBottom:
    selectedbutton === "Active Sprints" ? "2px solid #338BA8" : "none",
  margin: "0px 5px 0px 5px",
}));

export const ArchivedSprintButton = styled(Button)(({ selectedbutton }) => ({
  fontWeight: "bold",
  borderRadius: "0px",
  color:
    selectedbutton === "Archived Sprints"
      ? "#338BA8"
      : "var(--secondary-text-color)",
  borderBottom:
    selectedbutton === "Archived Sprints" ? "2px solid #338BA8" : "none",
  margin: "0px 5px 0px 5px",
}));

export const AddsprintBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  paddingRight: "15px",
  "@media (max-width: 600px)": {
    paddingRight: "0px",
  },
}));

export const SliderBox = styled(Box)(() => ({
  "@media (max-width: 600px)": {
    padding: "10px 0px 0px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    overflow: "hidden",
    position: "relative",
  },
}));
