import { Box, Typography, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const LoadingBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "65vh",
}))

export const MainBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "0px 13px 10px -20px #111",
  borderBottom: "1px solid var(--table-border-color)",
  marginBottom: "20px",
}))

export const TitleTypography = styled(Typography)(() => ({
  color: "var(--primary-color)",
  fontWeight: "bold",
  marginLeft: "15px",
  '@media (max-width: 600px)': {
    marginLeft: "4px",
  },
}))

export const InnerBoxOne = styled(Box)(() => ({
  width: "100%",
  maxHeight: "fit-content"
}))

export const AddWorkFlowBox = styled(Box)(() => ({
  marginBottom: "6px",
  display: "flex",
  alignItems: "center",
  paddingRight: "15px",
  '@media (max-width: 600px)': {
    paddingRight: "4px",
  },
}))


export const DataGrids = styled(DataGrid)(({ dataGridHeight }) => ({
  height: dataGridHeight ? "230px" : "fit-content",
  ".MuiDataGrid-filler": {
    height: "0px !important",
    display: "none",
  },
  color: "var(--primary-text-color)",
  textTransform: "capitalize",
  ".MuiDataGrid-columnHeaderTitle": {
    textTransform: "none !important",
  },
  backgroundColor: "var(--subAccordian-background-color)",
  ".MuiDataGrid-cell ": {
    cursor: "pointer",
    alignContent: "center",
  },
  ".MuiDataGrid-cell:focus": {
    outline: "none",
  },
  ".MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  ".MuiDataGrid-columnHeader": {
    height: "48px !important",
  },
  ".MuiInput-underline:before": {
    borderBottom: "1px solid var(--primary-text-color) !important",
  },
  ".MuiSvgIcon-fontSizeSmall": {
    fill: "var(--primary-text-color) !important",
  },
  ".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
  {
    color: "var(--primary-text-color) !important",
  },
  ".MuiDataGrid-overlay": {
    color: "var(--primary-text-color) !important",
    backgroundColor: "var(--subAccordian-background-color)",
  },
  ".MuiDataGrid-actionsCell": { type: "actions" },
  ".MuiDataGrid-scrollbar": {
    height: "auto !important",
  },
  "--DataGrid-containerBackground": "var(--primary-highlight-color)"
}));

