import { Box, Grid, Button, Typography, styled } from "@mui/material"

export const MainBox = styled(Box)(({ isjtthemedark }) => ({
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    background: isjtthemedark === "true"
        ? "var(--darkTheme-before-login-bg)"
        : "var(--lightTheme-before-login-bg)",
    overflow: "auto",
    "@media (max-width: 600px)": {
        background: isjtthemedark === "true"
            ? "var(--darkTheme-before-login-sideblock-bg)"
            : "var(--lightTheme-before-login-bg)",
    },
}))

export const LoadingBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "90vh",
}))

export const MainGrid = styled(Grid)(({ isjtthemedark }) => ({
    display: "flex",
    alignContent: "center",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: isjtthemedark === "true"
        ? "var(--darkTheme-before-login-img-bg)"
        : "var(--lightTheme-before-login-img-bg)",
}))

export const InnerBoxOne = styled(Box)((isjtthemedark) => ({
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    width: "1000px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.14)",
    "@media (max-width: 600px)": {
        boxShadow: "none",
    },
}))

export const InnerBoxTwo = styled(Box)(({ isjtthemedark }) => ({
    flex: "1",
    display: "flex",
    background: isjtthemedark === "true"
        ? "var(--darkTheme-before-login-img-bg)"
        : "var(--lightTheme-before-login-img-bg)",
    alignItems: "center",
    justifyContent: "center",
}))

export const LoginBox = styled(Box)(() => ({
    flex: "1"
}))

export const MainFormBox = styled(Box)(({ isjtthemedark }) => ({
    padding: "60px 60px 40px 40px",
    background: isjtthemedark === "true"
        ? "var(--darkTheme-before-login-sideblock-bg)"
        : "--lightTheme-before-login-bg",
    height: "100%",
    "@media (max-width: 600px)": {
        padding: "0px 60px 0px 40px",
    },
}))


export const InputBox = styled(Box)(({ isjtthemedark }) => ({
    ".MuiOutlinedInput-input": {
        color: isjtthemedark === "true"
            ? "var(--darkTheme-inputfield-color) !important"
            : "var(--lightTheme-inputfield-color) !important",
    },
    ".MuiFormLabel-root": {
        color: isjtthemedark === "true"
            ? "var(--darkTheme-inputfield-color) !important"
            : "var(--lightTheme-inputfield-color) !important",
    },
}))

export const SpaceTypography = styled(Typography)(({ isjtthemedark }) => ({
    letterSpacing: 3,
    fontSize: "1.2em",
    fontWeight: "bold",
    textAlign: "center",
    color: isjtthemedark === "true"
        ? "var(--secondary-text-color)"
        : "var(--primary-text-color)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}))

export const ErrorTypography = styled(Typography)(() => ({
    color: "red",
    fontSize: "14px",
    padding: "0",
    marginTop: "-20px",
    marginBottom: "10px"
}))

export const ErrorMessageTypography = styled(Typography)(() => ({
    color: "red",
    typography: "subtitle2",
    textAlign: "left",
}))

export const LoginButton = styled(Button)(() => ({
    width: "100%",
    background: "var(--secondary-color)",
    color: "var(--darkTheme-inputfield-color)",
    fontWeight: "bold",
    "&:hover": {
        background: "var(--primary-color)",
        color: "var(--darkTheme-inputfield-color)",
    },
}))

export const FooterMainBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "10px",
    "@media (max-width: 600px)": {
        flexDirection: "column",
        justifyContent: "start",
    }
}))

export const LinkTypography = styled(Typography)(() => ({
    typography: "subtitle2",
    fontWeight: "bold",
    color: "var(--primary-color)",
    cursor: "pointer",
    textDecorationLine: "initial",
    "&:hover": { textDecorationLine: "underline" },
}))


export const TextTypography = styled(Typography)(({ isjtthemedark }) => ({
    typography: "subtitle2",
    padding: "10px 0 0 0",
    textAlign: "center",
    color: isjtthemedark === "true"
        ? "var(--darkTheme-inputfield-color)"
        : "var(--lightTheme-inputfield-color)",
}))