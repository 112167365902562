import React, { useRef, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  Grid,
  Slide,
  Switch,
  Typography,
  Tooltip,
  Zoom,
  DialogTitle,
  useMediaQuery,
  IconButton,
  DialogContent
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { Close } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import DialogComponent from "../ReusableComponents/DialogBoxComponents/DialogComponent.js";
import AddProject from "./AddProject.js";
import Loader from "../Loader/Loader.js";
import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../Slices/toastSlice.js";
import { errorMessage } from "../../Services/axiosInstance.js";
import {
  changeProjectStatus,
  getAllProjectData,
} from "../../Services/ProjectManageServices.js";
import { MainBoxDialog } from "../../Styles/KnowledgeSharing/KnowledgeSharingStyle";
import {
  MainBox,
  SideBox,
  CancelButton,
  ActiveArchiveButtton,
  ErrorTypography,
  // InnerBoxFive,
  // InnerBoxFour,
  // InnerBoxThree,
  CloseIconButton,
  ActiveProjectButton,
  ProjectNameBox,
  InnerBoxTwo,
  SwitchBox,
  InnerBoxOne,
  NoDataTypography,
  LoadingBox,
  ArchivedProjectButton,
  AddProjectBox,
  AddProjectButtonBox,
  SliderBox,
  CommanTabBox,
  InnerSliderBox,
  AddProjectButton,
  InnerBoxOneDialog,
  InnerBoxTwoDialog
} from "../../Styles/ProjectManagement/ProjectStyle.js";
import usePermissions from "../RolePermission/usePermissions.js";
import { DialogsActions } from "../../Styles/Authentication/LogOutStyle.js";

function Project() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.selectedActiveView.role);
  const currentAdminLogedinId = useSelector(
    (state) => state.adminData.adminData?.empId
  );
  const currentEmpLogedinId = useSelector(
    (state) => state.addEmployee.current?.empId
  );
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const { hasPermission } = usePermissions();

  const [change, setChange] = useState(false);
  const [displayError, setDisplayError] = useState("");
  const [selectedButton, setSelectedButton] = useState("Active Projects");
  const [isExistingData, setIsExistingData] = useState(false);
  const [existingData, setExistingData] = useState({});
  // const [pID, setPID] = useState("");
  const [finalFlterProject, setFinalFilterProject] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const scrollRef = useRef(null);

  const adminLogin = user === "admin";
  const currentPersonID = adminLogin
    ? currentAdminLogedinId
    : currentEmpLogedinId;

  const toggleForm = () => {
    setIsExistingData(false);
    setExistingData({});
    setShowForm(!showForm);
  };

  const showDailog = (index) => {
    setOpenDialog(true);
  };

  const onClose = () => {
    setOpenDialog(false);
  };

  const updateproject = async () => {
    try {
      setDisplayError("");
      const id = existingData?._id;
      // const response = await axios.put(
      // 	`${CLOUD_FUNCTIONS_ORIGIN}/admin/updateProject`,
      // 	{ id },
      // 	postHeader
      // );
      const response = await changeProjectStatus({ id });
      if (response.status === 200) {
        setOpenDialog(false);
        if (change === true) {
          setChange(false);
        } else {
          setChange(true);
        }
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "");
    }
  };

  const getScrollAmount = () => {
    if (scrollRef.current) {
      return scrollRef.current.offsetWidth * 1.05;
    }
    return 700;
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -getScrollAmount(),
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: getScrollAmount(),
        behavior: "smooth",
      });
    }
  };

  // const renderFormViewProject = () => {
  //   return (
  //     <>
  //       <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
  //         <Box>
  //           <CloseIconButton
  //             onClick={onClose}
  //             isdarktheme={isDarkTheme ? "true" : "false"}
  //           >
  //             <Close />
  //           </CloseIconButton>
  //         </Box>
  //         <InnerBoxThree>
  //           <Box>
  //             <DialogTitle
  //               sx={{
  //                 fontWeight: "bold",
  //                 fontSize: { xs: "1.2rem", sm: "1.5rem" },
  //               }}
  //             >
  //               <InnerBoxFour>
  //                 <InnerBoxFive isdarktheme={isDarkTheme ? "true" : "false"} />
  //                 {/* {dialogIcon}
	// 							</Box> */}
  //                 <Box>
  //                   <Box
  //                     sx={{
  //                       color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
  //                       fontSize: { xs: "1rem", sm: "1.2rem" },
  //                     }}
  //                   >
  //                     {existingData.status === "Active"
  //                       ? `Confirm Archive ${existingData.project} Project`
  //                       : `Confirm Active ${existingData.project} project`}
  //                   </Box>
  //                   <Typography
  //                     sx={{
  //                       color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
  //                       fontSize: { xs: "0.9rem", sm: "1rem" },
  //                     }}
  //                   >
  //                     {existingData.status === "Active"
  //                       ? `Are you sure you want to Archive Project?`
  //                       : `Are you sure you want to Active Project?`}
  //                   </Typography>
  //                 </Box>
  //               </InnerBoxFour>
  //             </DialogTitle>
  //           </Box>
  //         </InnerBoxThree>
  //         <DialogsActions>
  //           <CancelButton
  //             onClick={onClose}
  //             isdarktheme={isDarkTheme ? "true" : "false"}
  //           >
  //             Cancel
  //           </CancelButton>
  //           <ActiveArchiveButtton
  //             variant="contained"
  //             onClick={() => {
  //               updateproject();
  //             }}
  //             component={Link}
  //             color="error"
  //           >
  //             {existingData.status === "Active" ? "Archive" : "Active"}
  //           </ActiveArchiveButtton>
  //         </DialogsActions>
  //       </Box>
  //       <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
  //         <ErrorTypography>{displayError}</ErrorTypography>
  //       </Box>
  //     </>
  //   );
  // };

  const renderFormViewProject = () => {
    return (
      <>
        <Box
          sx={{
            background: isDarkTheme ? "#121e2e" : "#ffffff",
            padding: "13px",
          }}
        >
          <MainBoxDialog>
            <Box>
              <DialogTitle
                sx={{
                  fontWeight: "bold",
                  padding: "0px",
                  fontSize: { xs: "1rem", sm: "1.5rem" },
                }}
              >
                <InnerBoxOneDialog>
                  <Box sx={{ padding: "0px", marginRight: "10px" }}>
                    <InnerBoxTwoDialog
                      isdarktheme={isDarkTheme ? "true" : "false"}
                    />
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        fontSize: { xs: "1rem", sm: "1.2rem" },
                        color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
                      }}
                    >
                      {existingData.status === "Active"
                        ? `Confirm Archive ${existingData.project} Project`
                        : `Confirm Active ${existingData.project} Project`}
                    </Box>
                  </Box>
                </InnerBoxOneDialog>
              </DialogTitle>
            </Box>
            <Box>
              <CloseIconButton
                onClick={onClose}
                isdarktheme={isDarkTheme ? "true" : "false"}
              >
                <Close />
              </CloseIconButton>
            </Box>
          </MainBoxDialog>
          <DialogContent sx={{ padding: "6px 4px 5px 4px" }}>
            <Typography
              sx={{
                color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
                fontSize: { xs: "0.9rem", sm: "1rem" },
              }}
            >
              {existingData.status === "Active"
                ? `Are you sure you want to Archive Project?`
                : `Are you sure you want to Active Project?`}
            </Typography>
          </DialogContent>
          <DialogsActions
            sx={{ padding: "6px 0px 0px 0px" }}
            displayError={displayError}
          >
            <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
              <ErrorTypography>{displayError}</ErrorTypography>
            </Box>
            <Box>
              <CancelButton
                isdarktheme={isDarkTheme ? "true" : "false"}
                onClick={onClose}
              >
                Cancel
              </CancelButton>
              <ActiveArchiveButtton
                variant="contained"
                onClick={updateproject}
                component={Link}
                color="error"
              >
                {existingData.status === "Active" ? "Archive" : "Active"}
              </ActiveArchiveButtton>
            </Box>
          </DialogsActions>
        </Box>
      </>
    );
  };

  const memoizedAdminFetchData = useMemo(() => {
    const getproject = async (e) => {
      setIsLoading(true);
      try {
        // const response = await axios.get(
        // 	`${CLOUD_FUNCTIONS_ORIGIN}/admin/getProject`,
        // 	getHeader
        // );
        const response = await getAllProjectData();
        if (response.status === 200) {
          const projects = response.data.AllProjects;
          setIsLoading(false);
          if (
            hasPermission("Active Projects") &&
            selectedButton === "Active Projects"
          ) {
            const data = projects?.filter((state) => state.status === "Active");
            const filteredProjects = data.filter((project) => {
              // Check if the leader's empId matches with currentPersonID
              if (project.leaderName.empId === currentPersonID) {
                return true;
              }
              // Check if any member's empId matches with currentPersonID
              const isMember = project.memberOfDevTeam.some(
                (member) => member.empId === currentPersonID
              );
              return isMember;
            });
            adminLogin
              ? setFinalFilterProject(data)
              : setFinalFilterProject(filteredProjects);
          } else {
            if (hasPermission("Archived Projects")) {
              const data = projects?.filter(
                (state) => state.status !== "Active"
              );
              const filteredProjects = data.filter((project) => {
                // Check if the leader's empId matches with currentPersonID
                if (project.leaderName.empId === currentPersonID) {
                  return true;
                }
                // Check if any member's empId matches with currentPersonID
                const isMember = project.memberOfDevTeam.some(
                  (member) => member.empId === currentPersonID
                );
                return isMember;
              });
              adminLogin
                ? setFinalFilterProject(data)
                : setFinalFilterProject(filteredProjects);
            }
          }
        } else {
          setFinalFilterProject([]);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    return getproject;
    // eslint-disable-next-line
  }, [selectedButton, currentPersonID, adminLogin]);

  useEffect(() => {
    memoizedAdminFetchData();
  }, [change, memoizedAdminFetchData, currentPersonID]);
  // const setHeadingAndDescription = () => {
  //   if (filterProject[index]?.status === "Active") {
  //     setDialogheading("Confirm Archive the Project");
  //   } else {
  //     setDialogheading("Confirm Active the project");
  //   }
  //   if (filterProject[index] === "Active") {
  //     setDialogDescription("Are you sure you want to Archive this Project?");
  //   } else {
  //     setDialogDescription("Are you sure you want to Active this Project?");
  //   }
  // };

  return (
    <Box sx={{ padding: "0px 4px 8px 7px" }}>
      {/* Active Project & Archived Project && + Add Project Button BAR */}
      {/* Box for items on the left */}
      {isMobile && adminLogin ? (
        <>
          <MainBox sx={{
            '@media (max-width: 600px)': {
              flexDirection: "column",
              alignItems: "flex-end",
            }
          }}>
            <AddProjectButtonBox>
              {hasPermission("Add Project") ? (
                <AddProjectBox>
                  <Tooltip
                    title="Add New Project"
                    arrow
                    disableInteractive
                    TransitionComponent={Zoom}
                  >
                    <AddProjectButton
                      variant="contained"
                      color="primary"
                      onClick={toggleForm}
                    >
                      + Add Project
                    </AddProjectButton>
                  </Tooltip>
                </AddProjectBox>
              ) : (
                <></>
              )}
            </AddProjectButtonBox>
            {/* <Box sx={{ padding: "5px 0px 0px 18px", display: "flex" }}> */}
            <SliderBox
            >
              <IconButton onClick={scrollLeft} style={{
                marginRight: 12,
                color: isDarkTheme === "true" ? "#e0e0e0cc" : "#5e6e82",
              }}>
                <ChevronLeftIcon />
              </IconButton>
              <InnerSliderBox
                ref={scrollRef}
              // display="flex"
              // flexDirection="row"
              // overflowX="auto"
              >
                <CommanTabBox>
                  {hasPermission("Active Projects") && (
                    <ActiveProjectButton
                      onClick={() => setSelectedButton("Active Projects")}
                      selectedbutton={selectedButton}
                    >
                      Active Projects
                    </ActiveProjectButton>
                  )}
                </CommanTabBox>
                <CommanTabBox>
                  {hasPermission("Archived Projects") && (
                    <ArchivedProjectButton
                      onClick={() => setSelectedButton("DeActive Projects")}
                      selectedbutton={selectedButton}
                    >
                      Archived Projects
                    </ArchivedProjectButton>
                  )}
                </CommanTabBox>
              </InnerSliderBox>
              <IconButton onClick={scrollRight} style={{
                marginLeft: 12,
                color: isDarkTheme === "true" ? "#e0e0e0cc" : "#5e6e82",
              }}>
                <ChevronRightIcon />
              </IconButton>
            </SliderBox>
            {/* </Box> */}
          </MainBox>
        </>
      ) : (
        <>
          <MainBox>
            <Box sx={{
              padding: "5px 0px 0px 18px", display: "flex",
              '@media (max-width: 600px)': {
                padding: "0px 0px 0px 0px"
              }
            }}>
              <Box>
                {hasPermission("Active Projects") && (
                  <ActiveProjectButton
                    onClick={() => setSelectedButton("Active Projects")}
                    selectedbutton={selectedButton}
                  >
                    Active Projects
                  </ActiveProjectButton>
                )}
                {hasPermission("Archived Projects") && (
                  <ArchivedProjectButton
                    onClick={() => setSelectedButton("DeActive Projects")}
                    selectedbutton={selectedButton}
                  >
                    Archived Projects
                  </ArchivedProjectButton>
                )}
              </Box>
            </Box>
            {hasPermission("Add Project") ? (
              <AddProjectBox>
                <Tooltip
                  title="Add New Project"
                  arrow
                  disableInteractive
                  TransitionComponent={Zoom}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={toggleForm}
                  >
                    + Add Project
                  </Button>
                </Tooltip>
              </AddProjectBox>
            ) : (
              <></>
            )}
          </MainBox>
        </>
      )}

      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <Box sx={{ margin: "20px 2px 2px 10px", bottom: 2 }}>
          <Grid container spacing={2}>
            {finalFlterProject.length === 0 ? (
              <Box sx={{ padding: "4%" }}>
                <NoDataTypography
                  color="var(--secondary-text-color)"
                  variant="body0"
                >
                  No Project Allocated!!
                </NoDataTypography>
              </Box>
            ) : (
              finalFlterProject?.map((data, index) => (
                <Grid key={index} item lg={2} sm={3} xs={12}>
                  <InnerBoxOne>
                    <InnerBoxTwo
                      onClick={() => {
                        // setHeadingAndDescription();
                        setShowForm(true);
                        setIsExistingData(true);
                        // setExistingData(allProjects[index]);
                        setExistingData(data);
                        // setPID(data._id);
                      }}
                    >
                      {adminLogin ? (
                        <SwitchBox>
                          <Switch
                            checked={data?.status === "Active"}
                            onChange={() => {
                              setShowForm(false);
                              setIsExistingData(false);
                              setExistingData(data);
                              showDailog(index);
                            }}
                          />
                        </SwitchBox>
                      ) : (
                        <></>
                      )}
                      <ProjectNameBox>
                        <Box sx={{ fontWeight: "bold" }}>{data?.project}</Box>
                        <Box padding={{ padding: "5px" }}>
                          <CircleIcon
                            sx={{
                              fill: data?.status === "Active" ? "green" : "red",
                              fontSize: "12px",
                              margin: "5px 4px 0px 0px",
                            }}
                          />
                          {data?.status}
                        </Box>
                      </ProjectNameBox>
                    </InnerBoxTwo>
                  </InnerBoxOne>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      )}

      <Backdrop open={showForm} onClick={toggleForm} />
      <Slide direction="left" in={showForm} mountOnEnter unmountOnExit>
        <SideBox isdarktheme={isDarkTheme ? "true" : "false"}>
          <AddProject
            setIsLoading={setIsLoading}
            setChange={setChange}
            change={change}
            onClose={toggleForm}
            isExistingData={isExistingData}
            setIsExistingData={setIsExistingData}
            existingData={existingData}
            setExistingData={setExistingData}
          />
        </SideBox>
      </Slide>
      {/* <ViewProject
				pID={pID}
				existingData={existingData}
				change={change}
				setChange={setChange}
				open={openDialog}
				setOpenDialog={setOpenDialog}
			/> */}
      <Box>
        <DialogComponent
          renderChildern={renderFormViewProject}
          maxWidth="280px"
          sx={{
            ".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper":
              {
                backgroundColor: isDarkTheme
                  ? "#0b1727 !important"
                  : "#ffffff !important",
              },
            width: !isMobile && "30vw",
            "@media (max-width: 600px)": {
              width: "100%",
            },
            height: "auto",
          }}
          onClose={onClose}
          open={openDialog}
        />
      </Box>
    </Box>
  );
}

export default Project;
