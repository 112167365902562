import { axiosInstance } from "./axiosInstance";

export const getEmployeeTaskData = () =>
  axiosInstance.get("/user/employeeTaskList");

export const getAdminEmployeeTaskData = () =>
  axiosInstance.get("/admin/employeeTaskList");

export const getEmployeeFetchTask = (employeeId) =>
  axiosInstance.get(`/admin/fetchEmployeeTask/${employeeId}`);

export const upsertTask = (getUser, postData) =>
  axiosInstance.post(`/${getUser}/task`, postData);

export const editTaskDescription = (getUser, postData) =>
  axiosInstance.put(`/${getUser}/updateTaskDescription`, postData);

export const editTaskPlannedHours = (getUser, postData) =>
  axiosInstance.put(`/${getUser}/updateTaskPlannedHours`, postData);

export const upsertComment = (getUser, postData) =>
  axiosInstance.post(`/${getUser}/addComment`, postData);

export const editApprovedStatus = (getUser, postData) =>
  axiosInstance.put(`/${getUser}/updateApprovedStatus`, postData);

export const editTask = (getUser, postData) =>
  axiosInstance.put(`/${getUser}/updateTask`, postData);

export const getTaskById = (taskId) =>
  axiosInstance.get(`/user/getTaskById/${taskId}`);

export const getAllTaskData = (apiUser) =>
  axiosInstance.get(`/${apiUser}/getTask`);

export const upsertEmployeeEffecicency = (postData) =>
  axiosInstance.post(`/admin/getEffecicency`, postData);

export const addTags = (postData) =>
  axiosInstance.post(`/admin/addTags`, postData);

export const fetchCategories = () => axiosInstance.get("/admin/getCategories");

export const addCategory = (postData) =>
  axiosInstance.post("/admin/addCategory", postData);

export const updateCategory = (id, postData) =>
  axiosInstance.put(`/admin/updateCategory/${id}`, postData);

export const deleteCategory = (id) =>
  axiosInstance.delete(`/admin/deleteCategory/${id}`);

export const getAllTaskTypes = () =>
  axiosInstance.get("/admin/getAllTaskTypes");

export const upsertTaskType = (postData) =>
  axiosInstance.post(`/admin/upsertTaskType`, postData);

export const deleteTaskType = (id) =>
  axiosInstance.delete(`/admin/deleteTaskType/${id}`);

export const getTaskType = () => axiosInstance.get("/admin/getTaskType");

export const getSprint = (status) =>
  axiosInstance.get(`/user/getSprints`, { params: { status } });

export const addSprint = (postData) =>
  axiosInstance.post("/user/addSprint", postData);

export const editSprints = (id, postData) =>
  axiosInstance.put(`/user/editSprint/${id}`, postData);

export const deleteSprint = (postData) =>
  axiosInstance.post(`/user/deleteSprint`, postData);

export const changeSprintStatus = (id, postData) =>
  axiosInstance.put(`/admin/changeSprintStatus/${id}`, postData);

export const completedSprint = (postData) =>
  axiosInstance.post("/user/completedSprint", postData);

export const getAllTags = () => axiosInstance.get("/admin/getTags");

export const updateSprintStatus = (id) =>
  axiosInstance.put(`/admin/changeSprintStatus`, id);

export const getSprintTasks = (sprintId, empId) =>
  axiosInstance.get("/user/getSprintTasks", { params: { sprintId, empId } });

// export const getWorkflowById = (workflowId) => axiosInstance.get(`/admin/workflow/getById/${workflowId}`);
export const getWorkflowById = (workflowId) =>
  axiosInstance.get("admin/getAllWorkflow");

// export const updateTaskStatus = (taskId, statusData) => axiosInstance.patch(`/admin/task/updateStatus/${taskId}`, statusData);
export const updateTaskStatus = (taskId, statusData) => {
  return axiosInstance.post(`/user/updateTaskStatus/${taskId}`, statusData);
};
