import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Accordion,
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import LeaveAssignCard from "./LeaveAssignCard";
import { sucessButton } from "../ReusableComponents/CustomDesignMUI/CustomMUI";
import TableComponentForleaveAssigned from "./TableComponentForleaveAssigned";
import { renderAsterisk } from "../ReusableComponents/UtilityFunctions";
import {
  GetLeaveBalanceData,
  upsertYearLeaveAccordian,
} from "../../Services/LeaveManageServices";
import { getAllEmployeeName } from "../../Services/EmployeeManageServices";
import { MainBox, AccordionsSummary, AssignleaveButton, InnerBoxFive, AccordionsDetails, InnerBoxFour, InnerBoxThree, InnerBoxOne, ErrorTypography, ClearButton, InnerBoxTwo } from "../../Styles/CompanyAndLeavePolicy/AssignLeavesStyle"

function AssignLeaves() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:600px)');

  const [distributedData, setDistributedData] = useState([]);
  const [accordianData, setAccordianData] = useState([]);
  const [openDailogBox, setOpenDailogBox] = useState(false);
  const [change, setChange] = useState(false);
  const [selectedDataDailog, setSelectedDataDailog] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [dateSelectError, setDateSelectError] = useState("");
  const [minYear, setMinYear] = useState({
    startYear: null,
    endYear: null,
  });
  const [dateRange, setDateRange] = useState({
    startYear: null,
    endYear: null,
  });

  const assignLeaveDialogHandler = (data) => {
    setOpenDailogBox(true);
    setSelectedDataDailog(data);
  };

  const handleYearChange = (newValue, picker) => {
    setDateSelectError("");
    if (picker === "start") {
      const newStartYear = newValue;
      const newEndYear = newStartYear?.add(1, "year");
      setDateRange({
        startYear: newStartYear,
        endYear: newEndYear,
      });
    } else if (picker === "end") {
      const newEndYear = newValue;
      const newStartYear = newEndYear?.subtract(1, "year");
      setDateRange({
        startYear: newStartYear,
        endYear: newEndYear,
      });
    }
  };

  const setYearLeaveAccordian = async () => {
    const start = dateRange.startYear?.$y;
    const end = dateRange.endYear?.$y;
    if (start) {
      try {
        const postData = JSON.stringify({ start, end });
        const response = await upsertYearLeaveAccordian(postData);
        if (response.status === 200) {
          setDateRange({ startYear: null, endYear: null });
          if (change) {
            setChange(false);
          } else {
            setChange(true);
          }
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message));
          dispatch(setToastType("success"));
        }
      } catch (error) {
      }
    } else {
      setDateSelectError("Select Year");
    }
  };

  const checkYear = (year) => {
    const [startYear] = year.split("-");
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); // January is 0, December is 11
    let yearToCheck;
    // Check if the current date is before March 31st of the current year
    if (
      currentMonth < 2 ||
      (currentMonth === 2 && currentDate.getDate() < 31)
    ) {
      yearToCheck = currentYear - 1;
    } else {
      yearToCheck = currentYear;
    }
    if (startYear === String(yearToCheck)) {
      return true;
    } else {
      return false;
    }
  };

  const fetchEmployeeName = async () => {
    try {
      const response = await getAllEmployeeName();
      if (response.status === 200) {
        setEmployeeList(response.data.namesData);
      } else {
        setEmployeeList([]);
      }
    } catch (error) {
    }
  };

  const memoizedGetLeaveBalanceData = useMemo(() => {
    const getData = async () => {
      try {
        const response = await GetLeaveBalanceData();
        if (response.status === 200 || response.status === 201) {
          const accordian = response.data.accordian;
          const reverseaccordian = accordian.reverse();
          setAccordianData(reverseaccordian);
          setDistributedData(response.data.records);
        } else {
          setAccordianData([]);
          setDistributedData([]);
        }
      } catch (error) {
      }
    };
    return getData;
  }, []);

  useEffect(() => {
    if (accordianData.length > 0) {
      if (accordianData[0]?.year) {
        const [start, end] = accordianData[0]?.year.split("-");
        const newStart = (parseInt(start, 10) + 1).toString();
        const newEnd = (parseInt(end, 10) + 1).toString();
        setMinYear({
          startYear: newStart,
          endYear: newEnd,
        });
      }
    } else if (distributedData?.year) {
      const [start, end] = distributedData?.year.split("-");
      const newStart = parseInt(start, 10).toString();
      const newEnd = parseInt(end, 10).toString();
      setMinYear({
        startYear: newStart,
        endYear: newEnd,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accordianData]);

  useEffect(() => {
    fetchEmployeeName();
  }, []);

  useEffect(() => {
    memoizedGetLeaveBalanceData();
  }, [memoizedGetLeaveBalanceData, change]);

  return (
    <Box>
      <Box>
        <MainBox>
          <InnerBoxOne>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ marginLeft: !isMobile && "10px", marginRight: "10px" }}
                // label="Start Year"
                openTo="year"
                views={["year"]}
                value={dateRange.startYear}
                onChange={(newValue) => handleYearChange(newValue, "start")}
                renderInput={(params) => <TextField {...params} />}
                minDate={dayjs(minYear.startYear)}
                slotProps={{
                  openPickerButton: {
                    color: "primary",
                  },
                }}
                label={
                  <>
                    Start Year
                    {renderAsterisk()}
                  </>
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <hr
                style={{
                  borderRightWidth: "12px",
                  borderColor: "var(--primary-text-color)",
                }}
              />
              <DatePicker
                sx={{ marginLeft: "10px", marginRight: !isMobile && "20px" }}
                label="End Year"
                openTo="year"
                views={["year"]}
                value={dateRange.endYear}
                onChange={(newValue) => handleYearChange(newValue, "end")}
                renderInput={(params) => <TextField {...params} />}
                minDate={dayjs(minYear.endYear)}
                slotProps={{
                  openPickerButton: {
                    color: "primary",
                  },
                }}
              />
            </LocalizationProvider>
          </InnerBoxOne>
          {isMobile ? (
            <Box className="button-container">
              <ClearButton
                // color="primary"
                // variant="contained"
                onClick={() => {
                  setDateSelectError("");
                  setDateRange({
                    startYear: null,
                    endYear: null,
                  });
                }}
              >
                Clear
              </ClearButton>
              <InnerBoxTwo>
                <Button
                  variant="contained"
                  sx={sucessButton}
                  onClick={() => setYearLeaveAccordian()}
                >
                  Add Year
                </Button>
              </InnerBoxTwo>
            </Box>
          ) : (
            <>
              <ClearButton
                // color="primary"
                // variant="contained"
                onClick={() => {
                  setDateSelectError("");
                  setDateRange({
                    startYear: null,
                    endYear: null,
                  });
                }}
              >
                Clear
              </ClearButton>
              <InnerBoxTwo>
                <Button
                  variant="contained"
                  sx={sucessButton}
                  onClick={() => setYearLeaveAccordian()}
                >
                  Add Year
                </Button>
              </InnerBoxTwo>
            </>
          )
          }
        </MainBox>
        <ErrorTypography>
          {dateSelectError}
        </ErrorTypography>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        {accordianData?.map((data, index) => (
          <Accordion key={index} sx={{ marginBottom: "10px" }}>
            <AccordionsSummary
              key={index}
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "var(--primary-text-color)",
                  }}
                />
              }
              aria-controls={`panel-content`}
              id={`panel-header`}
            >
              <InnerBoxThree>
                <Box>{data.year}</Box>
                <Box sx={{ display: "flex" }}>
                  {/* <Box sx={{ display: "flex" }}>
										<Typography>
											<ArrowUpwardIcon
												style={{
													color: "var(--start-color)",
												}}
											/>
										</Typography>
										<Typography>
											{data?.percentageLeft}
										</Typography>
									</Box> */}
                  <InnerBoxFour>
                    {/* <Typography>
											<ArrowDownwardIcon
												style={{
													color: "var(--late-owl-point-color)",
												}}
											/>
										</Typography> */}
                    <Typography>
                      {Math.round(
                        (100 -
                          parseFloat(data?.percentageLeft ?? 0) +
                          Number.EPSILON) *
                        100
                      ) / 100}
                      /100
                      {/* {100 - data?.percentageLeft}/100 */}
                    </Typography>
                  </InnerBoxFour>
                </Box>
              </InnerBoxThree>
            </AccordionsSummary>
            <AccordionsDetails key={index}>
              {checkYear(data.year) && data.percentageLeft !== 0 ? (
                <InnerBoxFive>
                  <AssignleaveButton
                    onClick={() => {
                      assignLeaveDialogHandler(data);
                    }}
                    variant="contained"
                  >
                    + Assign leave
                  </AssignleaveButton>
                </InnerBoxFive>
              ) : null}
              {data.records.length > 0 ? (
                <Box sx={{ width: "100%" }}>
                  <TableComponentForleaveAssigned
                    showEmployeelist={true}
                    array={data.records}
                    employeeList={employeeList}
                    selectedDataDailog={data}
                    setChange={setChange}
                    change={change}
                  // employees={employees}
                  />
                </Box>
              ) : (
                `Leaves can be assign after April ${data.year.split("-")[0]}`
              )}
            </AccordionsDetails>
          </Accordion>
        ))}
      </Box>
      <Box>
        <LeaveAssignCard
          selectDates={true}
          setChange={setChange}
          change={change}
          selectedDataDailog={selectedDataDailog}
          open={openDailogBox}
          setOpenDailogBox={setOpenDailogBox}
          employeeList={employeeList}
        />
      </Box>
    </Box>
  );
}

export default AssignLeaves;
