import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  Box,
  Tooltip,
  Button,
  Typography,
  Slide,
  Backdrop,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import TaskDialogBox from "./DetailTask";
import AddTaskForm from "./AddTaskForm";
import {
  TaskTypeCell,
  getIcon,
} from "../ReusableComponents/UtilityFunctions";
import {
  getWorkflowById,
  updateTaskStatus,
} from "../../Services/TaskManageServices";
import { SideBox } from "../../Styles/TaskManagement/TaskManagementStyle";
import { TitleTypography } from "../../Styles/TaskManagement/TaskCategoryStyle";
import {
  BoardViewBox,
  BoardViewCard,
  UserNameAvatar,
  MainBoardViewBox,
  CardHeaderBox,
  AddTaskBoxButton,
  InnerCardBox,
  TaskTitleTypography,
  TaskPriorityBox,
  CalendarTodayIcons,
} from "../../Styles/TaskManagement/TaskManagementStyle";
import "../../assets/styles/DataGridStyles.css";

const BoardView = ({
  filteredData,
  setFilteredData,
  columns,
  setColumns,
  typeOptions
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const isShowTask = useSelector((state) => state.taskData.isShowTask);
  const employeeLogin = useSelector(
    (state) => state.selectedActiveView.role === "employee"
  );
  const currentEmployeeId = useSelector(
    (state) => state.addEmployee.current?.empId
  );
  const empId = employeeLogin ? currentEmployeeId : "";

  const [change, setChange] = useState(false);
  const [taskAdded, setTaskAdded] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const sprintId = new URLSearchParams(location?.search).get("sprintId");

  const handleDetailClick = (id) => {
    setOpenDialog(true);
    setTaskId(id);
    navigate(`?sprintId=${sprintId}&tNo=${id}`, { replace: true });
  };

  const handleOpen = (workflow) => {
    setSelectedWorkflow(workflow);
    toggleForm();
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const renderTaskTypeCell = (type, height, width, fontSize) => {
    const taskTypeName = typeOptions?.find(
      (item) => item?._id === type
    )?.taskTypeName;
    return (
      <TaskTypeCell
        fontSize={fontSize}
        taskTypeName={taskTypeName}
        height={height}
        width={width}
      />
    );
  };

  const updateColumns = (
    prevColumns,
    taskId,
    sourceId,
    destinationId,
    responseTask = null
  ) => {
    return prevColumns?.map((workflow) => {
      const updatedStatuses = workflow?.statuses?.map((status) => {
        if (status?.id === sourceId) {
          return {
            ...status,
            tasks: status.tasks.filter((task) => task?._id !== taskId),
          };
        } else if (status?.id === destinationId && responseTask) {
          return {
            ...status,
            tasks: [...status.tasks, { ...responseTask }],
          };
        }
        return status;
      });

      return {
        ...workflow,
        statuses: updatedStatuses,
      };
    });
  };

  const fetchWorkflowData = async () => {
    try {
      const workflowsResponse = await getWorkflowById();
      const workflows = workflowsResponse.data.workflows;
      const allStatusColumns = workflows?.map((workflow) => ({
        workflowId: workflow._id,
        workflowName: workflow.workFlowName,
        statuses: workflow.statusData.map((status) => ({
          id: status._id,
          name: status.status,
          color: status.color,
          tasks: [],
        })),
      }));
      setColumns(allStatusColumns);
      const updatedColumns = allStatusColumns.map((workflow) => {
        const updatedStatuses = workflow.statuses.map((status) => {
          const filteredTasks = filteredData?.filter(
            (task) => task?.statusValue === status?.id
          );
          return {
            ...status,
            tasks: filteredTasks.map((task) => ({
              ...task,
              statusColor: status.color,
            })),
          };
        });
        return {
          ...workflow,
          statuses: updatedStatuses,
        };
      });
      setColumns(updatedColumns);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onDragEnd = async (result) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId) {
      return;
    }
    const newStatusId = destination.droppableId;
    const taskId = result.draggableId;
    const destinationColumn = columns
      .flatMap((workflow) => workflow.statuses)
      .find((status) => status.id === newStatusId);
    if (!destinationColumn) return;
    const statusData = {
      statusId: newStatusId,
      status: destinationColumn.name,
      statusColor: destinationColumn.color,
    };
    setColumns((prevColumns) =>
      updateColumns(prevColumns, taskId, source.droppableId, null)
    );
    try {
      const response = await updateTaskStatus(taskId, statusData);
      const updatedRows = filteredData?.map((item) => {
        if (item?._id === taskId) {
          return { ...response?.data?.task };
        }
        return item;
      });
      setFilteredData(updatedRows);
      setColumns((prevColumns) =>
        updateColumns(
          prevColumns,
          taskId,
          null,
          destination.droppableId,
          response.data?.task
        )
      );
    } catch (error) {
      console.error("Error updating task status:", error);
    }
  };

  useEffect(() => {
    fetchWorkflowData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sprintId, empId, isShowTask]);

  return (
    <BoardViewBox>
      {columns?.map((workflow) => (
        <MainBoardViewBox mb={4} key={workflow.workflowId}>
          <TitleTypography
            sx={{
              marginBottom: 2,
              fontSize: "21px",
            }}
          >
            {workflow.workflowName}
          </TitleTypography>

          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, workflow.workflowId)}
          >
            <Box display="flex" gap={1} minWidth="100%">
              {workflow.statuses.map((column) => (
                <Droppable key={column.id} droppableId={column.id}>
                  {(provided) => (
                    <BoardViewCard
                      isdarktheme={isDarkTheme ? "true" : "false"}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      sx={{
                        padding: 1,
                      }}
                    >
                      <CardHeaderBox
                        sx={{
                          backgroundColor: column.color,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            marginRight: "8px",
                          }}
                        >
                          {column.name}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: "14px",
                          }}
                        >
                          {column.tasks.length}
                        </Typography>
                      </CardHeaderBox>
                      <AddTaskBoxButton>
                        <Button
                          sx={{ width: "100%", height: "25px" }}
                          onClick={() => handleOpen(workflow.workflowId)}
                        >
                          + Add Task
                        </Button>
                      </AddTaskBoxButton>
                      <Box
                        sx={{
                          height: "360px",
                          overflowY: "auto",
                        }}
                      >
                        {column.tasks.map((task, index) => (
                          <Draggable
                            key={task._id || `task-${index}`}
                            draggableId={
                              task._id ? task._id.toString() : `task-${index}`
                            }
                            index={index}
                          >
                            {(provided) => (
                              <InnerCardBox
                                isdarktheme={isDarkTheme ? "true" : "false"}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={() => handleDetailClick(task.ticketNo)}
                                sx={{
                                  padding: 2,
                                  marginTop: 1,
                                }}
                              >
                                <TaskTitleTypography
                                  variant="subtitle1"
                                  fontSize="14px"
                                  color="var(--secondary-text-color)"
                                  sx={{
                                    WebkitLineClamp: 2,
                                  }}
                                >
                                  {task.title}
                                </TaskTitleTypography>
                                <Typography
                                  variant="caption"
                                  color="var(--secondary-text-color)"
                                  gutterBottom
                                >
                                  {task.id}
                                </Typography>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  mt={1}
                                  gap={0.5}
                                >
                                  <Tooltip title="Actual Hours">
                                    <AccessTimeIcon
                                      sx={{
                                        color: "grey",
                                        fontSize: "16px",
                                      }}
                                    />
                                  </Tooltip>
                                  <Typography
                                    variant="body2"
                                    color="var(--secondary-text-color)"
                                    fontSize="12px"
                                    sx={{
                                      lineHeight: "unset",
                                    }}
                                  >
                                    {task.actualHours}
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  mt={1}
                                  gap={0.8}
                                >
                                  <Box
                                    sx={{
                                      width: 15,
                                      height: 15,
                                      lineHeight: "unset",
                                    }}
                                    title=""
                                  >
                                    {renderTaskTypeCell(task.type, 15, 15, 13)}
                                  </Box>
                                  <Typography
                                    variant="caption"
                                    color="var(--secondary-text-color)"
                                    fontSize="12px"
                                    lineHeight="unset"
                                  >
                                    {task.ticketNo}
                                  </Typography>
                                </Box>
                                <Tooltip title={task.priority}>
                                  <TaskPriorityBox>
                                    {getIcon(task.priority)}
                                  </TaskPriorityBox>
                                </Tooltip>
                                <Tooltip
                                  title={formatDate(task.dueDate) || "Unknown"}
                                >
                                  <CalendarTodayIcons />
                                </Tooltip>
                                <Tooltip title={task.name || "Unknown"}>
                                  <UserNameAvatar>
                                    {task.name
                                      ?.split(" ")
                                      .map((n) => n[0])
                                      .join("")
                                      .toUpperCase()}
                                  </UserNameAvatar>
                                </Tooltip>
                              </InnerCardBox>
                            )}
                          </Draggable>
                        ))}
                      </Box>
                      {provided.placeholder}
                    </BoardViewCard>
                  )}
                </Droppable>
              ))}
            </Box>
          </DragDropContext>
        </MainBoardViewBox>
      ))}
      <TaskDialogBox
        open={openDialog}
        setOpenDialog={setOpenDialog}
        taskId={taskId}
        onClose={() => handleDetailClick()}
      />
      <Box>
        <Slide direction="left" in={showForm} mountOnEnter unmountOnExit>
          <SideBox>
            <AddTaskForm
              workflow={selectedWorkflow}
              onClose={toggleForm}
              setChange={setChange}
              change={change}
              taskAdded={taskAdded}
              setTaskAdded={setTaskAdded}
            />
          </SideBox>
        </Slide>
        <Backdrop open={showForm} onClick={toggleForm} />
      </Box>
    </BoardViewBox>
  );
};

export default BoardView;
