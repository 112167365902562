import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Button,
  IconButton,
  DialogTitle,
  DialogContent,
  Box,
  Tooltip,
  Typography,
  Slide,
  CardContent,
  Backdrop,
  Zoom,
  Chip,
  TextField,
  MenuItem,
  useMediaQuery,
  Grid,
  Checkbox,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Add, Edit, Delete, Close, Visibility } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArchiveIcon from "@mui/icons-material/Archive";
import dayjs from "dayjs";

import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import DialogComponent from "../ReusableComponents/DialogBoxComponents/DialogComponent";
import { numberArray } from "../Constant/constant";
import {
  getSprint,
  addSprint,
  editSprints,
  deleteSprint,
  completedSprint,
  updateSprintStatus,
} from "../../Services/TaskManageServices";
import {
  CancelButton,
  CloseIconButton,
  InnerBoxOneDialog,
  InnerBoxTwoDialog,
  ErrorTypography,
  DialogsActions,
  InputTextField,
  SideBox,
  CloseBox,
  MainBox,
  MainCard,
  InnerBoxOne,
  ComplateSprintBox,
  CompletedSprint,
  EditIconButton,
  DeleteIconButton,
  LinearsProgress,
  ActiveSprintButton,
  ArchivedSprintButton,
  AddsprintBox,
  SliderBox,
  CommanTabBox,
  InnerSliderBox,
} from "../../Styles/TaskManagement/TaskSprintStyle";
import {
  DeleteButton,
  MainBoxDialog,
} from "../../Styles/KnowledgeSharing/KnowledgeSharingStyle";
import { renderAsterisk } from "../ReusableComponents/UtilityFunctions";

const TaskSprint = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.selectedActiveView.role);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const isMobile = useMediaQuery("(max-width:600px)");
  const scrollRef = useRef(null);

  const [sprints, setSprints] = useState([]);
  const [alternativeSprint, setAlternativeSprint] = useState("");
  const today = new Date();
  const endDate = new Date(today);

  const formatDate = (dateObj) => {
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getFullYear();
    return `${day}-${month}-${year}`;
  };

  endDate.setDate(today.getDate() + 7);
  const [newSprint, setNewSprint] = useState({
    name: `Sprint ${formatDate(today)} to ${formatDate(endDate)}`,
    duration: 1,
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: "",
    goal: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [error, setError] = useState({});
  const [displayError] = useState("");
  const [sprintMessage, setSprintMessage] = useState("");
  const [dataGridHeight, setDataGridHeight] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  // const [totalCount, setTotalCount] = useState(0);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [selectedButton, setSelectedButton] = useState("Active Sprints");
  const [editSprint, setEditSprint] = useState({
    name: "",
    duration: 1,
    startDate: "",
    endDate: "",
    goal: "",
  });

  const adminLogin = user === "admin";

  const showDailog = () => {
    setOpenDialog(true);
  };

  const onClose = () => {
    setOpenDialog(false);
  };

  const onClose2 = (isEditMode) => {
    if (isEditMode) {
      setEditDialogOpen(false);
      setEditSprint({
        name: "",
        duration: 1,
        startDate: "",
        endDate: "",
        goal: "",
      });
    } else {
      setAddDialogOpen(false);
      setNewSprint({
        name: `Sprint ${formatDate(today)} to ${formatDate(endDate)}`,
        duration: 1,
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: "",
        goal: "",
      });
    }
    setError({});
    setSprintMessage("");
  };

  const handleOpenAddDialog = () => {
    setAddDialogOpen(true);
  };

  const hanldeDeleteDialog = () => {
    setDeleteIndex(null);
    setAlternativeSprint("");
    setIsConfirmed(false);
    setError({});
    setDeleteDialogOpen(false);
  };

  const handleRowSelectionChange = (newSelectionModel) => {
    setSelectedRows(newSelectionModel.slice(-1));
  };

  const handleOpenDeleteDialog = (rowData) => {
    setDeleteIndex(rowData._id);
    setDeleteDialogOpen(true);
  };

  const getScrollAmount = () => {
    if (scrollRef.current) {
      return scrollRef.current.offsetWidth * 1.05;
    }
    return 700;
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -getScrollAmount(),
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: getScrollAmount(),
        behavior: "smooth",
      });
    }
  };

  const handleEditSprint = (rowData) => {
    setEditSprint({
      ...rowData,
      startDate: convertToDate(rowData.startDate),
      endDate: convertToDate(rowData.endDate),
    });
    setEditIndex(rowData._id);
    setEditDialogOpen(true);
  };

  const getAllSprint = async (status) => {
    try {
      const response = await getSprint(status);
      if (response.status === 200 || response.status === 201) {
        setSprints(response.data.sprints);
      } else {
        setSprints([]);
      }
    } catch (error) {
      console.error("Error fetching Sprint:", error);
    }
  };

  const updateStatus = async () => {
    try {
      const id = editSprint?._id;
      const response = await updateSprintStatus({ id });

      if (response.status === 200) {
        setOpenDialog(false);
        setSprints((prevData) =>
          prevData?.filter((sprint) => sprint?._id !== id)
        );
        setEditSprint((prev) => ({
          ...prev,
          status: prev?.status === "active" ? "archived" : "active",
        }));
        dispatch(setToast(true));
        dispatch(
          setToastMsg(
            response.data.message ?? "Sprint status updated successfully."
          )
        );
        dispatch(setToastType("success"));
      }
    } catch (error) {
      console.error("Error toggling sprint status:", error);
    }
  };

  const handleAddSprint = () => {
    const { name, duration, startDate, goal } = newSprint;
    const error = [];
    if (!newSprint.name) {
      error.name = "Sprint name is required.";
    }
    if (!newSprint.startDate) {
      error.startDate = "StartDate is required.";
    }
    if (!newSprint.goal) {
      error.goal = "Goal is required.";
    }
    setError(error);

    if (name.trim() && startDate && goal.trim()) {
      setSprintMessage("Sprint Added Successfully.");
      const calculatedEndDate = new Date(
        new Date(startDate).getTime() + duration * 7 * 24 * 60 * 60 * 1000
      );
      const formattedStartDate = formatDate(new Date(startDate));
      const formattedEndDate = formatDate(calculatedEndDate);

      const sprintData = {
        name,
        duration,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        goal,
      };

      addSprint(sprintData)
      .then((response) => {
        if (response.status === 402) {
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message || "Error occurred"));
          dispatch(setToastType("error"));
        } else {
          setSprints([...sprints, { ...response.data.sprint, taskCount: 0 }]);
          onClose2();
          dispatch(setToast(true));
          dispatch(setToastMsg("Sprint Added Successfully"));
          dispatch(setToastType("success"));
        }
      })
        .catch((error) => console.error("Error adding sprint:", error));
    } else {
      console.error("All fields are required: Name, Start Date, and Goal");
    }
  };

  function convertToDate(dateString) {
    if (!dateString || typeof dateString !== "string") {
      console.error("Invalid date string");
      return null;
    }
    const parts = dateString.split("-");
    if (parts?.length !== 3) {
      console.error("Invalid date format");
      return null;
    }
    const reformattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    const parsedDate = new Date(reformattedDate);
    if (isNaN(parsedDate)) {
      console.error("Invalid date value");
      return null;
    }
    return parsedDate;
  }

  const handleSaveEdit = () => {
    const { name, duration, startDate, goal } = editSprint;
    const error = [];
    if (!editSprint.name) {
      error.name = "Sprint name is required.";
    }
    if (!editSprint.startDate) {
      error.startDate = "StartDate is required.";
    }
    if (!editSprint.goal) {
      error.goal = "Goal is required.";
    }
    setError(error);

    if (name.trim() && startDate && goal.trim()) {
      setSprintMessage("Sprint Updated Successfully.");
      const updatedEndDate = new Date(
        new Date(startDate).getTime() + duration * 7 * 24 * 60 * 60 * 1000
      ).toISOString();
      editSprints(editIndex, {
        name,
        duration,
        startDate,
        endDate: updatedEndDate,
        goal,
      })
        .then((response) => {
          const updatedSprints = sprints?.map((sprint) => {
            if (sprint._id === editIndex) {
              return {
                ...response.data.sprint,
                taskCount: sprint?.taskCount,
              };
            }
            return sprint;
          });
          setSprints(updatedSprints);
          const isEditMode = editIndex ? true : false;
          onClose2(isEditMode);
          dispatch(setToast(true));
          dispatch(setToastMsg("Sprint Updated Successfully"));
          dispatch(setToastType("success"));
        })
        .catch((error) => console.error("Error updating sprint:", error));
    } else {
      console.error("All fields are required!");
    }
  };

  const handleConfirmDelete = async (sprintData) => {
    const sprintId = deleteIndex;
    const errors = {};
    if (sprintData?.taskCount !== 0 && !isConfirmed && !alternativeSprint) {
      errors.sprintOptions = "Please select one option!";
      setError(errors);
      return;
    }
    try {
      const postData = {
        id: sprintId,
        isConfirmed,
        alternativeSprint,
        taskCount: sprintData?.taskCount,
      };
      if (sprintData?.taskCount === 0 || isConfirmed || alternativeSprint) {
        const response = await deleteSprint(JSON.stringify(postData));
        if (response.status === 200 || response.status === 201) {
          const updatedSprints = sprints?.filter(
            (sprint) => sprint?._id !== sprintId
          );
          if (alternativeSprint) {
            const updatedSprintsWithTaskCount = updatedSprints?.map(
              (sprint) => {
                if (sprint?._id === alternativeSprint) {
                  return {
                    ...sprint,
                    taskCount: sprint?.taskCount + sprintData?.taskCount,
                  };
                }
                return sprint;
              }
            );
            setSprints(updatedSprintsWithTaskCount);
          } else {
            setSprints(updatedSprints);
          }
          setDeleteDialogOpen(false);
          dispatch(setToast(true));
          dispatch(
            setToastMsg(response.data.message ?? "Sprint deleted successfully")
          );
          dispatch(setToastType("success"));
        } else {
          setDeleteDialogOpen(true);
        }
      } else {
        console.error(
          "Error: The delete action could not be completed due to missing data."
        );
      }
    } catch (error) {
      console.error("Error in delete process:", error);
    }
  };

  const handleCompletedSprint = async () => {
    try {
      const postData = { sprintId: selectedRows[0], isCompleted: true };
      const response = await completedSprint(JSON.stringify(postData));
      if (response.status === 200 || response.status === 201) {
        setSprints((prevData) =>
          prevData?.map((sprint) =>
            sprint?._id === selectedRows[0]
              ? { ...sprint, isCompleted: true }
              : sprint
          )
        );
        dispatch(setToast(true));
        dispatch(
          setToastMsg(
            response.data.message ?? "Sprint marked as completed successfully."
          )
        );
        dispatch(setToastType("success"));
        setSelectedRows([]);
      } else if (response.status === 203) {
        dispatch(setToast(true));
        dispatch(
          setToastMsg(
            response.data.message ??
            "Sprint contains tasks that are not completed."
          )
        );
        dispatch(setToastType("warning"));
        setSelectedRows([]);
      }
    } catch (error) { }
  };

  const renderUpdateStatus = () => {
    return (
      <>
        <Box
          sx={{
            background: isDarkTheme ? "#121e2e" : "#ffffff",
            padding: "13px",
          }}
        >
          <MainBoxDialog>
            <Box>
              <DialogTitle
                sx={{
                  fontWeight: "bold",
                  padding: "0px",
                  fontSize: { xs: "1.2rem", sm: "1.5rem" },
                }}
              >
                <InnerBoxOneDialog>
                  <Box sx={{ padding: "0px", marginRight: "10px" }}>
                    <InnerBoxTwoDialog
                      isdarktheme={isDarkTheme ? "true" : "false"}
                    >
                      <ArchiveIcon color="error" />
                    </InnerBoxTwoDialog>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        fontSize: { xs: "1rem", sm: "1.2rem" },
                        color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
                      }}
                    >
                      {editSprint.status === "active"
                        ? `Confirm Archive ${editSprint.name ?? ""} sprint`
                        : `Confirm Active ${editSprint.name ?? ""} sprint`}
                    </Box>
                  </Box>
                </InnerBoxOneDialog>
              </DialogTitle>
            </Box>
            <Box>
              <CloseIconButton
                onClick={onClose}
                isdarktheme={isDarkTheme ? "true" : "false"}
              >
                <Close />
              </CloseIconButton>
            </Box>
          </MainBoxDialog>
          <DialogContent sx={{ padding: "6px 4px 5px 4px" }}>
            <Typography
              sx={{
                color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
              }}
            >
              {editSprint.status === "active"
                ? `Are you sure you want to Archive this Sprint?`
                : `Are you sure you want to Active this Sprint?`}
            </Typography>
          </DialogContent>
          <DialogsActions
            sx={{ padding: "6px 0px 0px 0px" }}
            displayError={displayError}
          >
            <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
              <ErrorTypography>{displayError}</ErrorTypography>
            </Box>
            <Box>
              <CancelButton
                isdarktheme={isDarkTheme ? "true" : "false"}
                onClick={onClose}
              >
                Cancel
              </CancelButton>
              <DeleteButton
                variant="contained"
                onClick={updateStatus}
                color="error"
              >
                {editSprint.status === "active" ? "Archive" : "Active"}
              </DeleteButton>
            </Box>
          </DialogsActions>
        </Box>
      </>
    );
  };

  const renderSprintDialogBox = (isEditMode) => {
    const handleSave = isEditMode ? handleSaveEdit : handleAddSprint;
    return (
      <Box sx={{ padding: "22px 4px 8px 10px", height: "container" }}>
        <MainBox>
          <Box sx={{ padding: "5px 0px 5px 18px", display: "flex" }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {isEditMode ? "Edit Task Sprint" : "Add Task Sprint"}
            </Typography>
            {isEditMode && editSprint?.taskCount > 0 && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: 100, padding: "0 10px" }}>
                  <LinearsProgress
                    variant="determinate"
                    value={
                      editSprint?.taskCount
                        ? (editSprint.completedTasks / editSprint.taskCount) *
                        100
                        : 0
                    }
                  />
                </Box>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {editSprint.completedTasks}/{editSprint.taskCount}
                </Typography>
              </Box>
            )}
          </Box>
          <CloseBox>
            <IconButton onClick={() => onClose2(isEditMode)}>
              <CloseIcon color="primary" />
            </IconButton>
          </CloseBox>
        </MainBox>
        <Box sx={{ padding: "8px 4px 8px 10px", marginRight: "8px" }}>
          <MainCard>
            <CardContent>
              <InputTextField
                // label="Sprint Name"
                value={isEditMode ? editSprint.name : newSprint.name }
                isdarktheme={isDarkTheme ? "true" : "false"}
                onChange={(e) => {
                  isEditMode
                    ? setEditSprint({ ...editSprint, name: e.target.value })
                    : setNewSprint({ ...newSprint, name: e.target.value });
                  if (error["name"] && e.target.value !== "") {
                    setError((prevError) => {
                      const newError = { ...prevError };
                      delete newError["name"];
                      return newError;
                    });
                  }
                }}
                label={
                  <>
                    Sprint Name
                    {renderAsterisk()}
                  </>
                }
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <ErrorTypography>{error?.name}</ErrorTypography>
              <InputTextField
                label="Duration (in weeks)"
                type="number"
                isdarktheme={isDarkTheme ? "true" : "false"}
                value={isEditMode ? editSprint.duration : newSprint.duration}
                onChange={(e) =>
                  isEditMode
                    ? setEditSprint({
                      ...editSprint,
                      duration: Number(e.target.value),
                    })
                    : setNewSprint({
                      ...newSprint,
                      duration: Number(e.target.value),
                    })
                }
                fullWidth
                margin="normal"
              />
              <InputTextField
                // label="Start Date"
                type="date"
                name="startDate"
                isdarktheme={isDarkTheme ? "true" : "false"}
                sx={{
                  '& input[type="date"]::-webkit-calendar-picker-indicator': {
                    filter: isDarkTheme ? "invert(1)" : "none",
                  },
                }}
                value={
                  isEditMode
                    ? dayjs(editSprint.startDate, "DD-MM-YYYY").format(
                      "YYYY-MM-DD"
                    )
                    : dayjs(newSprint.startDate, "DD-MM-YYYY").format(
                      "YYYY-MM-DD"
                    )
                }
                onChange={(e) => {
                  isEditMode
                    ? setEditSprint({
                      ...editSprint,
                      startDate: e.target.value,
                    })
                    : setNewSprint({ ...newSprint, startDate: e.target.value });
                  if (error["startDate"] && e.target.value !== "") {
                    setError((prevError) => {
                      const newError = { ...prevError };
                      delete newError["startDate"];
                      return newError;
                    });
                  }
                }}
                label={
                  <>
                    Start Date
                    {renderAsterisk()}
                  </>
                }
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                margin="normal"
              />
              <ErrorTypography>{error.startDate}</ErrorTypography>
              {/* <InputTextField
                label="End Date"
                type="date"
                name="endDate"
                isdarktheme={isDarkTheme ? "true" : "false"}
                sx={{
                  '& input[type="date"]::-webkit-calendar-picker-indicator': {
                    filter: isDarkTheme ? "invert(1)" : "none",
                  },
                }}
                onChange={(e) => {
                  isEditMode
                    ? setEditSprint({ ...editSprint, endDate: e.target.value })
                    : setNewSprint({ ...newSprint, endDate: e.target.value });
                }}
                value={
                  isEditMode
                    ? dayjs(editSprint.endDate, "DD-MM-YYYY").format(
                      "YYYY-MM-DD"
                    )
                    : dayjs(newSprint.endDate, "DD-MM-YYYY").format(
                      "YYYY-MM-DD"
                    )
                }
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
              /> */}
              <InputTextField
                // label="Goal"
                value={isEditMode ? editSprint.goal : newSprint.goal}
                isdarktheme={isDarkTheme ? "true" : "false"}
                onChange={(e) => {
                  isEditMode
                    ? setEditSprint({ ...editSprint, goal: e.target.value })
                    : setNewSprint({ ...newSprint, goal: e.target.value });
                  if (error["goal"] && e.target.value !== "") {
                    setError((prevError) => {
                      const newError = { ...prevError };
                      delete newError["goal"];
                      return newError;
                    });
                  }
                }}
                fullWidth
                margin="normal"
                multiline
                rows={3}
                label={
                  <>
                    Goal
                    {renderAsterisk()}
                  </>
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <ErrorTypography>{error.goal}</ErrorTypography>
              {isEditMode && adminLogin && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={switchChecked}
                      onChange={() => {
                        showDailog();
                      }}
                      color="primary"
                    />
                  }
                />
              )}
              <DialogComponent
                open={openDialog}
                onClose={onClose}
                renderChildern={renderUpdateStatus}
              // open={renderUpdateStatus}
              />
              <InnerBoxOne>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {sprintMessage && (
                    <Typography
                      sx={{
                        mr: 2,
                        color: isDarkTheme ? "#e0e0e0" : "#5e6e82",
                      }}
                    >
                      {sprintMessage}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CancelButton onClick={() => onClose2(isEditMode)}>
                    Cancel
                  </CancelButton>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                  >
                    {isEditMode ? "Update" : "Add"}
                  </Button>
                </Box>
              </InnerBoxOne>
            </CardContent>
          </MainCard>
        </Box>
      </Box>
    );
  };

  const renderDeleteDialogBox = () => {
    const sprintData = sprints?.find((item) => item?._id === deleteIndex);
    return (
      <Box
        sx={{
          background: isDarkTheme ? "#121e2e" : "#ffffff",
          padding: "8px",
        }}
      >
        <MainBoxDialog>
          <Box>
            <DialogTitle
              sx={{
                fontWeight: "bold",
                padding: "0px 0px",
                fontSize: { xs: "1.2rem", sm: "1.5rem" },
              }}
            >
              <InnerBoxOneDialog>
                <Box sx={{ padding: "8px 5px" }}>
                  <InnerBoxTwoDialog
                    isdarktheme={isDarkTheme ? "true" : "false"}
                  />
                </Box>
                <Box>
                  <Box
                    sx={{
                      fontSize: { xs: "1rem", sm: "1.2rem" },
                      color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
                    }}
                  >
                    Confirm Delete Sprint
                  </Box>
                </Box>
              </InnerBoxOneDialog>
            </DialogTitle>
          </Box>
          <Box>
            <CloseIconButton
              onClick={hanldeDeleteDialog}
              isdarktheme={isDarkTheme ? "true" : "false"}
            >
              <Close />
            </CloseIconButton>
          </Box>
        </MainBoxDialog>
        <DialogContent sx={{ padding: "6px 4px 5px 7px" }}>
          <Typography
            sx={{
              color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
            }}
          >
            Are you sure you want to Delete{" "}
            <span
              style={{
                textDecoration: "underline",
              }}
            >
              {sprintData?.name}
            </span>{" "}
            sprint?
          </Typography>
          {sprintData?.taskCount > 0 && (
            <>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "4px",
                }}
              >
                <Typography
                  sx={{
                    color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
                    marginRight: "10px",
                    fontSize: isMobile ? "14px" : "16px",
                  }}
                >
                  Are you sure this sprint will be deleted along with its tasks?
                </Typography>
                <Checkbox
                  checked={isConfirmed}
                  onChange={(e) => {
                    setIsConfirmed(e.target.checked);
                    if (e?.target?.checked) {
                      setError({});
                    }
                  }}
                  sx={{
                    color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
                    "&.Mui-checked": {
                      color: isDarkTheme ? "#00e676" : "#1976d2",
                    },
                  }}
                />
              </Grid>
              <Typography
                sx={{
                  color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
                }}
              >
                {/* As you are Deleting{" "}
              <span
                style={{
                  textDecoration: "underline",
                }}
              >
                {sprintData?.name}
              </span>{" "}
              Sprint.
              <br /> */}
                Please select alternate sprint to change for all tasks if any :
              </Typography>
              <Grid sx={{ marginTop: "2%" }} item xs={6}>
                <TextField
                  fullWidth
                  select
                  label="Sprint"
                  name={"sprint"}
                  value={alternativeSprint}
                  onChange={(e) => {
                    setAlternativeSprint(e.target.value);
                    setError({});
                  }}
                  variant="outlined"
                  sx={{
                    width: isMobile ? "100%" : "80%",
                    padding: "0px 0px",
                    "& .MuiOutlinedInput-input": {
                      padding: alternativeSprint === "" ? "12px 7px" : "8px",
                    },
                  }}
                  InputProps={{
                    style: { overflow: "auto" },
                  }}
                >
                  {sprints
                    ?.filter(
                      (item) =>
                        item?.name !== sprintData?.name && !item?.isCompleted
                    )
                    ?.map((option) => (
                      <MenuItem key={option?._id} value={option?._id}>
                        {option?.name}
                      </MenuItem>
                    ))}
                </TextField>
                {/* <Typography color="error">{error.sprint}</Typography> */}
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogsActions displayError={error?.sprintOptions}>
          <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
            <ErrorTypography>{error?.sprintOptions}</ErrorTypography>
          </Box>
          <Box>
            <CancelButton
              isdarktheme={isDarkTheme ? "true" : "false"}
              onClick={hanldeDeleteDialog}
              color="secondary"
            >
              Cancel
            </CancelButton>
            <DeleteButton
              variant="contained"
              onClick={() => {
                handleConfirmDelete(sprintData);
              }}
              color="error"
            >
              Delete
            </DeleteButton>
          </Box>
        </DialogsActions>
      </Box>
    );
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "duration", headerName: "Duration (weeks)", flex: 0.5 },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
    },
    {
      field: "taskCount",
      headerName: "Task Counts",
      flex: 0.5,
    },
    {
      field: "completedTasks",
      headerName: "Tasks",
      flex: 1,
      renderCell: (params) => {
        const completedTasks = params.row.completedTasks || 0;
        const taskCount = params.row.taskCount || 0;
        return `${completedTasks} / ${taskCount}`;
      },
    },
    { field: "goal", headerName: "Goal", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            {params?.row?.isCompleted ? (
              <Chip
                label="Completed"
                style={{
                  backgroundColor: "var(--approved-color)",
                  color: "white",
                }}
                size="small"
                sx={{ fontWeight: "bold", fontSize: "11px" }}
              />
            ) : (
              <Chip
                label="In Progress"
                style={{
                  backgroundColor: "var(--pending-color)",
                  color: "white",
                }}
                size="small"
                sx={{ fontWeight: "bold", fontSize: "11px" }}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const handleViewAllSprints = (sprintId) => {
          navigate(`/task-sprint/alltasks?sprintId=${sprintId}`);
        };

        return (
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            {adminLogin ? <><Tooltip title="Edit">
              <EditIconButton
                onClick={() => handleEditSprint(params?.row)}
                isCompleted={params?.row?.isCompleted}
                disabled={params?.row?.isCompleted}
              >
                <Edit />
              </EditIconButton>
            </Tooltip>
              <Tooltip title="Delete">
                <DeleteIconButton
                  onClick={() => handleOpenDeleteDialog(params?.row)}
                  isCompleted={params?.row?.isCompleted}
                  disabled={params?.row?.isCompleted}
                  isdarktheme={isDarkTheme ? "true" : "false"}
                >
                  <Delete />
                </DeleteIconButton>
              </Tooltip></> : null}
            <Tooltip title="View All Sprints">
              <IconButton
                onClick={() => handleViewAllSprints(params.row.id)}
                sx={{ color: "var(--primary-text-color)" }}
                disabled={params.row.taskCount === 0}
              >
                <Visibility />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    getAllSprint("active");
  }, []);

  useEffect(() => {
    if (editSprint?.status) {
      setSwitchChecked(editSprint.status === "active");
    }
  }, [editSprint]);

  return (
    <Box>
      {isMobile ? (
        <>
          <MainBox
            sx={{
              "@media (max-width: 600px)": {
                flexDirection: "column",
                alignItems: "flex-end",
              },
            }}
          >
            <AddsprintBox>
              {selectedRows?.length > 0 && (
                <ComplateSprintBox>
                  <Tooltip
                    title="Completed Sprint"
                    arrow
                    disableInteractive
                    TransitionComponent={Zoom}
                  >
                    <CompletedSprint
                      variant="contained"
                      color="primary"
                      onClick={() => handleCompletedSprint()}
                      sx={{ marginBottom: 1 }}
                    >
                      Completed Sprint
                    </CompletedSprint>
                  </Tooltip>
                </ComplateSprintBox>
              )}
              <Tooltip
                title="Add New Project"
                arrow
                disableInteractive
                TransitionComponent={Zoom}
              >
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  onClick={handleOpenAddDialog}
                  sx={{ marginBottom: 1 }}
                >
                  Add Sprint
                </Button>
              </Tooltip>
            </AddsprintBox>
            <SliderBox>
              <IconButton
                onClick={scrollLeft}
                style={{
                  marginRight: 12,
                  color: isDarkTheme === "true" ? "#e0e0e0cc" : "#5e6e82",
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
              <InnerSliderBox
                ref={scrollRef}
                // display="flex"
                // flexDirection="row"
                // overflowX="auto"
              >
                <CommanTabBox>
                  <ActiveSprintButton
                    onClick={() => {
                      getAllSprint("active");
                      setSelectedButton("Active Sprints");
                    }}
                    selectedbutton={selectedButton}
                  >
                    Active Sprints
                  </ActiveSprintButton>
                </CommanTabBox>
                <CommanTabBox>
                  <ArchivedSprintButton
                    onClick={() => {
                      getAllSprint("archived");
                      setSelectedButton("Archived Sprints");
                    }}
                    selectedbutton={selectedButton}
                  >
                    Archived Sprints
                  </ArchivedSprintButton>
                </CommanTabBox>
              </InnerSliderBox>
              <IconButton
                onClick={scrollRight}
                style={{
                  marginLeft: 12,
                  color: isDarkTheme === "true" ? "#e0e0e0cc" : "#5e6e82",
                }}
              >
                <ChevronRightIcon />
              </IconButton>
            </SliderBox>
          </MainBox>
        </>
      ) : (
        <>
          <MainBox>
            <Box
              sx={{
                padding: "5px 0px 0px 18px",
                display: "flex",
                "@media (max-width: 600px)": {
                  padding: "0px 0px 0px 0px",
                },
              }}
            >
              <Box>
                <ActiveSprintButton
                  onClick={() => {
                    getAllSprint("active");
                    setSelectedButton("Active Sprints");
                  }}
                  selectedbutton={selectedButton}
                >
                  Active Sprints
                </ActiveSprintButton>
                <ArchivedSprintButton
                  onClick={() => {
                    getAllSprint("archived");
                    setSelectedButton("Archived Sprints");
                  }}
                  selectedbutton={selectedButton}
                >
                  Archived Sprints
                </ArchivedSprintButton>
              </Box>
            </Box>
            <AddsprintBox>
              {selectedRows?.length > 0 && (
                <ComplateSprintBox>
                  <Tooltip
                    title="Completed Sprint"
                    arrow
                    disableInteractive
                    TransitionComponent={Zoom}
                  >
                    <CompletedSprint
                      variant="contained"
                      color="primary"
                      onClick={() => handleCompletedSprint()}
                      sx={{ marginBottom: 1 }}
                    >
                      Completed Sprint
                    </CompletedSprint>
                  </Tooltip>
                </ComplateSprintBox>
              )}
              <Tooltip
                title="Add New Project"
                arrow
                disableInteractive
                TransitionComponent={Zoom}
              >
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  onClick={handleOpenAddDialog}
                  sx={{ marginBottom: 1 }}
                >
                  Add Sprint
                </Button>
              </Tooltip>
            </AddsprintBox>
          </MainBox>
        </>
      )}
      <Box
        sx={{
          width: "100%",
          maxHeight: "fit-content",
          overflowX: "auto",
          marginTop: "3%",
          "@media (max-width: 600px)": {
            marginTop: "10%",
          },
        }}
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              width: "290%",
            },
          }}
        >
          <DataGrid
            disableColumnMenu
            rowHeight={43}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            aria-selected="false"
            rows={sprints?.map((sprint) => ({
              ...sprint,
              id: sprint._id,
              startDate: new Date(sprint.startDate)
                .toLocaleDateString("en-GB")
                .replace(/\//g, "-"),
              endDate: new Date(sprint.endDate)
                .toLocaleDateString("en-GB")
                .replace(/\//g, "-"),
            }))}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize:
                    parseInt(window.localStorage.getItem("paginationNo"), 10) ||
                    10,
                },
              },
              columns: {
                columnVisibilityModel: {
                  completedTasks: false,
                },
              },
            }}
            onRowCountChange={(params) => {
              if (params === 0) {
                setDataGridHeight(true);
              } else {
                setDataGridHeight(false);
              }
            }}
            checkboxSelection
            onPaginationModelChange={(pageSize) => {
              window.localStorage.setItem("paginationNo", pageSize.pageSize);
            }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              handleRowSelectionChange(newRowSelectionModel);
            }}
            rowSelectionModel={selectedRows}
            disableRowSelectionOnClick
            onPageSizeChange={(pageSize) => {
              window.localStorage.setItem("paginationNo", pageSize);
            }}
            isRowSelectable={(params) => !params.row?.isCompleted}
            pageSizeOptions={numberArray}
            components={{
              Toolbar: GridToolbar,
            }}
            sx={{
              height: dataGridHeight ? "230px" : "fit-content",
              ".MuiDataGrid-filler": {
                height: "0px !important",
                display: "none",
              },
              color: "var(--primary-text-color)",
              textTransform: "capitalize",
              ".MuiDataGrid-columnHeaderTitle": {
                textTransform: "none !important",
              },
              backgroundColor: "var(--subAccordian-background-color)",
              ".MuiDataGrid-cell ": {
                alignContent: "center",
              },
              ".MuiDataGrid-cell:focus": {
                outline: "none",
              },
              ".MuiDataGrid-columnHeader": {
                height: "48px !important",
              },
              ".MuiInput-underline:before": {
                borderBottom: "1px solid var(--primary-text-color) !important",
              },
              ".MuiSvgIcon-fontSizeSmall": {
                fill: "var(--primary-text-color) !important",
              },
              ".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
              {
                color: "var(--primary-text-color) !important",
              },
              ".MuiDataGrid-overlay": {
                color: "var(--primary-text-color) !important",
                backgroundColor: "var(--subAccordian-background-color)",
              },
              ".MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
              ".MuiDataGrid-actionsCell": { type: "actions" },
              ".MuiCheckbox-root": {
                color: "var(--primary-text-color) !important",
                "&.Mui-checked": {
                  color: "var(--primary-color) !important",
                },
              },
              ".MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                visibility: "hidden",
              },
              ".MuiDataGrid-scrollbar": {
                height: "auto !important",
              },
              "--DataGrid-containerBackground":
                "var(--primary-highlight-color)",
            }}
            getRowClassName={(params) => {
              return params.row?.isCompleted ? "completed-row" : "";
            }}
          />
        </Box>
      </Box>
      <Backdrop
        open={isAddDialogOpen}
        onClick={() => setAddDialogOpen(false)}
      />
      <Slide direction="left" in={isAddDialogOpen} mountOnEnter unmountOnExit>
        <SideBox isdarktheme={isDarkTheme ? "true" : "false"}>
          {renderSprintDialogBox(false)}
        </SideBox>
      </Slide>

      <Backdrop
        open={isEditDialogOpen}
        onClick={() => setEditDialogOpen(false)}
      />
      <Slide direction="left" in={isEditDialogOpen} mountOnEnter unmountOnExit>
        <SideBox isdarktheme={isDarkTheme ? "true" : "false"}>
          {renderSprintDialogBox(true)}
        </SideBox>
      </Slide>
      <DialogComponent
        open={isDeleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        maxWidth="280px"
        sx={{
          marginLeft: isMobile ? "3%" : "12%",
          marginRight: isMobile ? "3%" : "10%",
          ".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper":
          {
            backgroundColor: isDarkTheme
              ? "#0b1727 !important"
              : "#ffffff !important",
          },
          width: !isMobile && "30vw",
          "@media (max-width: 600px)": {
            width: "100%",
          },
          height: "auto",
        }}
        renderChildern={renderDeleteDialogBox}
      />
    </Box>
  );
};

export default TaskSprint;
