import { Box, Button, Grid, Typography, styled } from "@mui/material"

export const MainBox = styled(Box)(({ isjtthemedark }) => ({
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    background: isjtthemedark === "true"
        ? "var(--darkTheme-before-login-bg)"
        : "var(--lightTheme-before-login-bg)",
    overflow: "auto",
    "@media (max-width: 600px)": {
        background: isjtthemedark === "true"
            ? "var(--darkTheme-before-login-sideblock-bg)"
            : "var(--lightTheme-before-login-bg)",
    },
}))

export const InnerBoxOne = styled(Box)(() => ({
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    width: "1000px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.14)",
    "@media (max-width: 600px)": {
        boxShadow: "none",
    },
}))

export const MainGrid = styled(Grid)(({ isjtthemedark }) => ({
    display: "flex",
    alignContent: "center",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: isjtthemedark === "true"
        ? "var(--darkTheme-before-login-img-bg)"
        : "var(--lightTheme-before-login-img-bg)",
}))

export const InnerBoxTwo = styled(Box)(() => ({
    flex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const InnerBoxThree = styled(Box)(() => ({
    height: "100%",
    flex: "1",
    padding: "60px 60px 40px 40px",
    "@media (max-width: 600px)": {
        padding: "0px 60px 0px 40px",
    },
}))

export const ErrorsTypography = styled(Typography)(() => ({
    color: "red",
    fontSize: "14px",
    padding: "0",
}))

export const OtpInputBox = styled(Box)(({ isjtthemedark }) => ({
    marginTop: "3px",
    ".MuiOutlinedInput-input": {
        color: isjtthemedark === "true"
            ? "var(--darkTheme-inputfield-color) !important"
            : "var(--lightTheme-inputfield-color) !important",
    },
    ".MuiFormLabel-root": {
        color: isjtthemedark === "true"
            ? "var(--darkTheme-inputfield-color) !important"
            : "var(--lightTheme-inputfield-color) !important",
    },
}))

export const ResendOtpInputBox = styled(Box)(({ isjtthemedark }) => ({
    display: "flex",
    justifyContent: "flex-end",
    color: isjtthemedark === "true"
        ? "var(--darkTheme-inputfield-color)"
        : "var(--lightTheme-inputfield-color)",
    marginTop: "10px",
    marginBottom: "7px"
}))

export const ErrorMessagesTypography = styled(Typography)(() => ({
    marginTop: "-10px",
    color: "red",
    typography: "subtitle2",
    textAlign: "left",
}))

export const InnerBoxFour = styled(Box)(() => ({
    color: "var(--third-color)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0 0px 0",
}))

export const OtpButton = styled(Button)(() => ({
    width: "100%",
    background: "var(--secondary-color)",
    color: "var(--secondary-color",
    fontWeight: "bold",
    "&:hover": {
        background: "red",
        color: "var(--secondary-color)",
    },
}))

export const InnerBoxFive = styled(Box)(() => ({
    color: "var(--third-color)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "-20px 0 30px 0",
}))


export const PasswordInputBox = styled(Box)(({ isjtthemedark }) => ({
    margin: "25px 0px 5px 0px",
    ".MuiOutlinedInput-input": {
        color: isjtthemedark === "true"
            ? "var(--darkTheme-inputfield-color) !important"
            : "var(--lightTheme-inputfield-color) !important",
    },
    ".MuiFormLabel-root": {
        color: isjtthemedark === "true"
            ? "var(--darkTheme-inputfield-color) !important"
            : "var(--lightTheme-inputfield-color) !important",
    },
}))

export const ConfirmPasswordInputBox = styled(Box)(({ isjtthemedark }) => ({
    // marginTop: "15px",
    margin: "15px 0px 5px 0px",
    ".MuiOutlinedInput-input": {
        color: isjtthemedark === "true"
            ? "var(--darkTheme-inputfield-color) !important"
            : "var(--lightTheme-inputfield-color) !important",
    },
    ".MuiFormLabel-root": {
        color: isjtthemedark === "true"
            ? "var(--darkTheme-inputfield-color) !important"
            : "var(--lightTheme-inputfield-color) !important",
    },
}))

export const SaveButton = styled(Button)(() => ({
    width: "100%",
    background: "var(--secondary-color)",
    color: "var(--secondary-color",
    fontWeight: "bold",
    "&:hover": {
        background: "red",
        color: "var(--secondary-color)",
    },
}))