import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Notification from 'react-web-notification';
import { Box, Tooltip, Zoom, IconButton, tooltipClasses, useMediaQuery } from "@mui/material";
import LightModeIcon from "@mui/icons-material/LightMode";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { ref, onValue, update } from 'firebase/database';

import { ReactComponent as BrandLogo } from "../../assets/img/icons/EmployeeVueLogo.svg";
import DigitalClock from "./DigitalClock";
import LogoutDialog from "./MenuItems/LogoutDialog";
import Notifications from "./../Notification/Notification";
import { formatTime } from "../ReusableComponents/UtilityFunctions";
import database from '../../Firebase/FirebaseConfig';
import { toggleTheme } from "../../Slices/blackThemeSlice";
import { resetUserDataReducer } from "../../Slices/UserDataSlice";
import { resetToastSliceReducer } from "../../Slices/toastSlice";
import { resetTaskSliceReducer } from "../../Slices/taskSlice";
import { resetSelectedActiveReducer, setGetNotificationCount } from "../../Slices/selectedActive";
import { resetSearchDataReducer } from "../../Slices/searchData";
import { resetLeaveDataReducer } from "../../Slices/leaveData";
import { resetEmployeeReducer, setemployeeAllAttendenceData } from "../../Slices/employeeDetailSlice";
import { resetAdmindetailReducer } from "../../Slices/adminDetailSlice";
import { setShowDialogBox } from "../../Slices/timerDetailsSlice";
import { resetTimerData, setElapsedTimes, setIsPlaying, setIsRunning, setIsStart, setShowTicketDialogBox, setTotalBreakTime } from "../../Slices/timerDetailsSlice";
import { getTimerData } from "../../Services/AttendanceManageServices";
import {
  DarkModeIcons,
  HeaderInnerBoxOne,
  HeaderMainBox,
  LogoutIcons,
  TextTypography,
  PipeTypography,
  SpaceTypography,
  ShowTimerBox,
  PunchInPunchOutBox,
  TimerBox,
  Badges
} from "../../Styles/AdminDashboard/DashBoardStyle";

const toolTipStyle = {
  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
  {
    marginTop: "9px",
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
  {
    marginBottom: "0px",
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
  {
    marginLeft: "1px",
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
  {
    marginRight: "0px",
  },
};

const Header = (props) => {
  const { handleStart, handleSidebarShow } = props;
  const notificationRef = useRef(null);
  const iconRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const { pathname } = useLocation();
  const user = useSelector((state) => state.selectedActiveView.role);
  const getNotificationCount = useSelector(
    (state) => state?.selectedActiveView?.getNotificationCount
  );
  const currentEmployeeId = useSelector(
    (state) => state.addEmployee.current?.empId
  );
  const currentAdminId = useSelector(
    (state) => state.adminData.adminData?.empId
  );

  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const isdefault = useSelector((state) => state.timerData.isDefault);
  const isRunning = useSelector((state) => state.timerData.isRunning);
  const isStart = useSelector((state) => state.timerData.isStart);
  const employeeEmail = useSelector((state) => state.addEmployee.current?.email);
  const adminEmail = useSelector((state) => state.adminData.adminData?.email);
  const employeeLogin = useSelector((state) => state.selectedActiveView.role === "employee");
  const adminLogin = useSelector((state) => state.selectedActiveView.role === "admin");
  const elapsedTimes = useSelector((state) => state.timerData.elapsedTimes);
  const pesentDate = formatDate(new Date());
  const peviousDate = window.localStorage.getItem("presentDay");
  const empId = employeeLogin ? currentEmployeeId : currentAdminId;
  const email = employeeLogin ? employeeEmail : adminLogin ? adminEmail : "";
  const currentTicketNo = window.localStorage.getItem("TaskNo") || "";
  const starts = window.localStorage.getItem("start") === "true" || false;

  const [openDialog, setOpenDialog] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(elapsedTimes);
  const [isOpen, setIsOpen] = useState(false);
  const [notification, setNotification] = useState([]);
  const processedNotificationIds = useRef(new Set());
  const [newNotificationCount, setNewNotificationCount] = useState(0);

  function themeChange() {
    if (dispatch(toggleTheme(!isDarkTheme))) {
      localStorage.removeItem("theme");
      localStorage.setItem("is_JT_theme_Dark", !isDarkTheme);
    }
  }

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const handleClickOutside = (event) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target) &&
      iconRef.current &&
      !iconRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  const renderIcon = (type) => {
    switch (type) {
      case "Leave":
        return "./leaveicon.png";
      case "Task":
        return "./taskicon.png";
      case "Project":
        return "./projecticon.png";
      case "ApprovedTask":
        return "./approvedTaskicon.png";
      default:
        return null;
    }
  };

  const handleLogoutConfirmation = async () => {
    let is_JT_theme_Dark = localStorage.getItem("is_JT_theme_Dark");
    window.localStorage.clear();
    localStorage.setItem("is_JT_theme_Dark", is_JT_theme_Dark);
    navigate("/");
    dispatch(resetUserDataReducer());
    dispatch(resetToastSliceReducer());
    dispatch(resetTaskSliceReducer());
    dispatch(resetSelectedActiveReducer());
    dispatch(resetSearchDataReducer());
    dispatch(resetLeaveDataReducer());
    dispatch(resetTimerData());
    dispatch(resetEmployeeReducer());
    dispatch(resetAdmindetailReducer());
    setOpenDialog(false);
  };

  const handleCancelLogout = () => {
    setOpenDialog(false);
  };

  const cleanMessage = (message) => {
    return message.replace(/localhost:3000/g, '');
  };

  // const handleNotificationClose = async (id) => {
  //   const notificationRef = ref(database, `notifications/${id}`);
  //   const unsubscribe = onValue(notificationRef, async (snapshot) => {
  //     if (snapshot.exists()) {
  //       const notification = snapshot.val();
  //       const updatedUsers = notification?.user?.filter(user => user?.empId !== empId);
  //       await update(notificationRef, {
  //         user: updatedUsers,
  //       });
  //       setNotification([]);
  //       setHasShownNotification(false);
  //     } else {
  //       console.log(`No notification found with id: ${id}`);
  //     }
  //     unsubscribe();
  //   }, (error) => {
  //     console.error("Error fetching notification:", error);
  //   });
  // };

  const handleIconClick = () => {
    setIsOpen((prev) => !prev);
    setNewNotificationCount(0);
  };

  const handleClick = (value) => {
    if (value === "stopTimer") {
      dispatch(setShowDialogBox(true))
    } else {
      dispatch(setIsPlaying(true));
      currentTicketNo ? handleStart(currentTicketNo) : dispatch(setShowTicketDialogBox(true))
    }
  };

  const extractProjectDetails = (notification, empId) => {
    if (notification?.type === "Project") {
      const parts = notification?.message.split(",");
      if (parts?.length >= 2) {
        const projectName = parts[0].replace("Project:", "").trim();
        const leaderId = parts[1].replace("LeaderId:", "").trim();
        const role = empId === leaderId ? "Team Lead" : "Team Member";

        return {
          project: projectName,
          assignBy: notification?.assignBy,
          role,
        };
      }
    }
    return null;
  };

  const memoizedfetchData = useMemo(() => {
    const fetchData = async () => {
      let firstStart = false
      if (!starts) {
        firstStart = true;
      } else {
        firstStart = false;
      }
      if (user) {
        try {
          const user = employeeLogin ? "user" : adminLogin ? "admin" : "";
          const response = await getTimerData(user)
          if (response.status === 200 || response.status === 201) {
            dispatch(
              setemployeeAllAttendenceData(response.data.wholedata.records)
            );
            const currentTime = new Date().getTime();
            const start = localStorage.getItem("start") === "true";
            if (start && firstStart) {
              const startTime = response.data.record.startTime;
              if (start) {
                const sTime = Math.floor((currentTime - startTime) / 1000);
                dispatch(setElapsedTimes(sTime));
                dispatch(setIsStart(false));
                dispatch(setIsRunning(true));
              } else {
                dispatch(setElapsedTimes(0));
                dispatch(setIsStart(false));
                dispatch(setIsRunning(true));
              }
            } else {
              const TimerStart = response.data.totalTimer.TimerStart;
              const TotalTime = response.data.totalTimer.TotalTime;
              const BreakTime = response.data.totalTimer.BreakTime;
              dispatch(setTotalBreakTime(BreakTime));
              if (start && !isdefault) {
                const sTime = Math.floor(
                  (currentTime - TimerStart - BreakTime) / 1000
                );
                dispatch(setElapsedTimes(sTime));
                dispatch(setIsStart(false));
                dispatch(setIsRunning(true));
              }
              if (start && isdefault) {
                const timeCurrent = new Date().getTime();
                const today = new Date();
                const combinedDateTimeString = today.toISOString().slice(0, 10) + " " + window.localStorage.getItem("punchIn");
                const combinedDateTime = new Date(combinedDateTimeString);
                const timeDate = combinedDateTime.getTime();
                const additionalTime = Math.floor(
                  (timeCurrent - timeDate) / 1000
                );
                const sTime = (TotalTime / 1000) + additionalTime;
                dispatch(setElapsedTimes(sTime));
                dispatch(setIsStart(false));
                dispatch(setIsRunning(true));
              }
              if (!start) {
                dispatch(setElapsedTimes(TotalTime / 1000));
                dispatch(setIsStart(true));
                dispatch(setIsRunning(false));
              }
            }
          }
          else {
            dispatch(setTotalBreakTime(0));
          }
        } catch (error) {

        }
      }
    };
    return fetchData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adminLogin,
    employeeLogin,
    dispatch,
    isdefault,
    user,
    starts,
    email
  ]);

  useEffect(() => {
    const notificationsRef = ref(database, 'notifications');
    const unsubscribe = onValue(notificationsRef, (snapshot) => {
      if (snapshot.exists()) {
        const notificationsList = Object.entries(snapshot.val())?.map(([key, value]) => ({
          id: key,
          ...value,
        }));
        const filteredNotifications = notificationsList?.map((notification) => {
          const hasValidUser = notification?.user?.some(user => user?.empId === empId && user?.isDelete === false && user?.isSend === false);
          if (hasValidUser) {
            const updatedUsers = notification?.user?.map(user => {
              if (user?.empId === empId && user?.isDelete === false && user?.isSend === false) {
                return { ...user, isOpen: true };
              }
              return user;
            });
            return {
              ...notification,
              user: updatedUsers,
            };
          }
          return null;
        })?.filter(notification => notification !== null);
        const lastNotification = filteredNotifications[filteredNotifications.length - 1];
        if (lastNotification && !processedNotificationIds.current.has(lastNotification.id)) {
          setNotification([lastNotification]);
          setNewNotificationCount(prevCount => prevCount + 1);
          processedNotificationIds.current.add(lastNotification.id);

          // Update the Firebase database to set isSend: true for the matched user
          const notificationKey = lastNotification?.id;
          const updatedUser = lastNotification?.user?.map((user) => {
            if (user?.empId === empId && user?.isDelete === false && user?.isSend === false) {
              return { ...user, isSend: true };
            }
            return user;
          });
          update(ref(database, `notifications/${notificationKey}`), {
            user: updatedUser,
          }).catch((error) => {
            console.error('Error updating notification:', error);
          });
          setTimeout(() => {
            setNotification([]);
          }, 6000);
        }
      } else {
        setNotification([]);
      }
    });
    return () => unsubscribe();
    // eslint-disable-next-line
  }, [empId]);

  useEffect(() => {
    const baseTitle = document.title.replace(/^🔸\(\d+\) /, '');
    if (newNotificationCount > 0) {
      document.title = `🔸(${newNotificationCount}) ${baseTitle}`;
      dispatch(setGetNotificationCount(getNotificationCount + 1));
    } else {
      document.title = baseTitle;
    }
    // eslint-disable-next-line
  }, [newNotificationCount]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const body = document.getElementById("root");
    if (isDarkTheme) {
      body.classList.add("dark-theme");
    } else {
      body.classList.remove("dark-theme");
    }
  }, [isDarkTheme]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = 'Any string';
    };

    if (starts) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [starts]);

  useEffect(() => {
    memoizedfetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStart])

  useEffect(() => {
    if (pathname === "/attendance") {
      memoizedfetchData();
    }
    // eslint-disable-next-line
  }, [pathname])

  useEffect(() => {
    setElapsedTime(elapsedTimes)
  }, [elapsedTimes])

  useEffect(() => {
    if (pesentDate !== peviousDate && !isdefault) {
      window.localStorage.removeItem("presentDay");
      window.localStorage.removeItem("totalTime");
      window.localStorage.removeItem("punchIn");
      window.localStorage.removeItem("start");
      window.localStorage.removeItem("totalBreakTime");
    }
    let elapsedTimeInterval;
    if (isRunning) {
      elapsedTimeInterval = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => {
      if (elapsedTimeInterval) {
        clearInterval(elapsedTimeInterval);
      }
    };
  }, [
    elapsedTime,
    isdefault,
    isRunning,
    starts,
    pesentDate, peviousDate,
    memoizedfetchData
  ]);

  useEffect(() => {
    if (!isdefault && !starts) {
      setElapsedTime(parseInt(window.localStorage.getItem("totalTime")));
    }
  }, [isdefault, starts]);

  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      memoizedfetchData();
    }, 900000);
    return () => {
      clearInterval(fetchDataInterval);
    }
  }, [memoizedfetchData])

  useEffect(() => {
  }, [getNotificationCount])

  return (
    <>
      <HeaderMainBox>
        <Box sx={{
          display: "flex",
          '@media (max-width: 600px)': {
            width: '100%',
          },
        }}>
          {empId && notification?.length > 0 && notification?.map((notification, index) => {
            const isOpen = notification?.user?.find((item) => item?.empId === empId)?.isOpen || false;
            const projectDetails = extractProjectDetails(notification, empId);
            const filterMessage = notification?.type === "Project"
              ? `${projectDetails?.assignBy} has added you to the project ${projectDetails?.project} as a ${projectDetails?.role}.`
              : notification?.message;
            return (
              <Notification
                key={notification?.id}
                title={`Assigned By: ${notification?.assignBy}`}
                options={{
                  body: cleanMessage(filterMessage),
                  icon: renderIcon(notification?.type),
                }}
                // onClose={() => handleClose(notification?.id)}
                // onClose={() => {
                //   handleNotificationClose(notification?.id);
                // }}
                onShow={(e, tag) => {
                  if (isOpen) {
                  }
                }}
                isOpen={isOpen}
                timeout={86400000} // 24 hours in milliseconds
              />
            );
          })}
          {isMobile ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flex: 1,
                  // padding: "0 5px",
                }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <BrandLogo
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                    cursor={"pointer"}
                    className={isDarkTheme ? "brandLogoDarkThemeclass" : ""}
                    width={"130px"}
                    height={"30px"}
                  />
                  <PipeTypography variant="h6" sx={{ fontSize: "1rem" }}>
                    |
                  </PipeTypography>
                  <SpaceTypography
                    variant="5"
                    isjtthemedark={isDarkTheme ? "true" : "false"}
                    sx={{ fontSize: "1rem" }}
                  >
                    TEAMSPACE
                  </SpaceTypography>
                </Box>
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={handleSidebarShow}
                >
                  <MenuIcon style={{ fontSize: "2rem", color: " var(--secondary-text-color)" }} />
                </IconButton>
              </Box>
            </>
          ) : (
            <>
              <BrandLogo
                onClick={() => {
                  navigate("/dashboard");
                }}
                cursor={"pointer"}
                className={isDarkTheme ? "brandLogoDarkThemeclass" : ""}
                width={"180px"}
                height={"30px"}
              />
              <PipeTypography variant="h5">|</PipeTypography>
              <SpaceTypography
                variant="4"
                isjtthemedark={isDarkTheme ? "true" : "false"}
              >
                TEAMSPACE
              </SpaceTypography>
              <TextTypography>
                Empower Insightful Workforce Management
              </TextTypography>
            </>
          )}
        </Box>
        {!isMobile && <HeaderInnerBoxOne>
          <TimerBox>
            <ShowTimerBox width={"80px"} marginRight={"3px"}>
              {elapsedTime ? formatTime(elapsedTime) : "00:00:00"}
            </ShowTimerBox>
            <PunchInPunchOutBox>
              {starts && currentTicketNo ? (
                <IconButton onClick={() => handleClick("stopTimer")} sx={{ color: "var(--secondary-text-color)" }}>
                  <PauseCircleFilledIcon style={{ fontSize: 24 }} />
                </IconButton>
              ) : (
                <IconButton onClick={() => handleClick()} sx={{ color: "var(--secondary-text-color)" }}>
                  <PlayArrowIcon style={{ fontSize: 25 }} />
                </IconButton>
              )}
            </PunchInPunchOutBox>
          </TimerBox>
          <DigitalClock fontSize="1.25em" />
          <Box margin="0px 0px 0px 0px" padding="8px">
            {isDarkTheme ? (
              <Tooltip
                title="Switch to Light theme"
                arrow
                disableInteractive
                TransitionComponent={Zoom}
                slotProps={{
                  popper: {
                    sx: toolTipStyle,
                  },
                }}
              >
                <LightModeIcon
                  sx={{ cursor: "pointer", fontSize: "25px" }}
                  color="warning"
                  onClick={themeChange}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title="Switch to Dark theme"
                arrow
                disableInteractive
                TransitionComponent={Zoom}
                slotProps={{
                  popper: {
                    sx: toolTipStyle,
                  },
                }}
              >
                <DarkModeIcons sx={{ cursor: "pointer", fontSize: "25px" }} onClick={themeChange} />
              </Tooltip>
            )}
          </Box>
          <Box margin="0px 0px 0px 0px" padding="8px" position="relative">
            <Tooltip
              title="Notification"
              arrow
              disableInteractive
              TransitionComponent={Zoom}
              slotProps={{
                popper: {
                  sx: toolTipStyle,
                },
              }}
            >
              <div ref={iconRef} onClick={handleIconClick}>
                {getNotificationCount !== 0 ? (
                  <Badges
                    badgeContent={getNotificationCount}
                    color="error"
                    overlap="circular"
                  >
                    <NotificationsIcon
                      sx={{
                        color: 'var(--secondary-text-color)',
                        cursor: 'pointer',
                        fontSize: "25px"
                      }}
                    />
                  </Badges>
                ) : (
                  <NotificationsIcon
                    sx={{
                      color: 'var(--secondary-text-color)',
                      cursor: 'pointer',
                      fontSize: "25px"
                    }}
                  />
                )}
              </div>
            </Tooltip>
            {isOpen && (<div ref={notificationRef}><Notifications toolTipStyle={toolTipStyle} isOpen={isOpen} onClose={() => setIsOpen(false)} /></div>)}
          </Box>
          <Box margin="0px 10px 0px 0px" padding="8px">
            <Tooltip
              title="Logout"
              arrow
              disableInteractive
              TransitionComponent={Zoom}
              slotProps={{
                popper: {
                  sx: toolTipStyle,
                },
              }}
            >
              <LogoutIcons
                onClick={() => {
                  setOpenDialog(true);
                }}
              />
            </Tooltip>
          </Box>
          {/* <Box>
          <AdminProfile />
        </Box> */}
        </HeaderInnerBoxOne>}
      </HeaderMainBox>
      {
        isMobile && (<><Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            boxShadow: "0px 13px 10px -20px #111",
            borderBottom: "1px solid var(--table-border-color)",
          }}
        >
          <Box marginLeft={"6%"}>
            <TimerBox ismobile={isMobile ? "true" : "false"}>
              <ShowTimerBox ismobile={isMobile ? "true" : "false"} width={"52px"} marginRight={"5px"}>
                {elapsedTime ? formatTime(elapsedTime) : "00:00:00"}
              </ShowTimerBox>
              <PunchInPunchOutBox ismobile={isMobile ? "true" : "false"}>
                {starts && currentTicketNo ? (
                  <IconButton onClick={() => handleClick("stopTimer")} sx={{ color: "var(--secondary-text-color)" }}>
                    <PauseCircleFilledIcon style={{ fontSize: 22 }} />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleClick()} sx={{ color: "var(--secondary-text-color)" }}>
                    <PlayArrowIcon style={{ fontSize: 22 }} />
                  </IconButton>
                )}
              </PunchInPunchOutBox>
            </TimerBox>
          </Box>
          <Box sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
            marginRight={"5%"}>
            <Box margin="6px 0px 0px 0px" padding="6px" position="relative">
              <Tooltip
                title="Notification"
                arrow
                disableInteractive
                TransitionComponent={Zoom}
                slotProps={{
                  popper: {
                    sx: toolTipStyle,
                  },
                }}
              >
                <div ref={iconRef} onClick={handleIconClick}>
                  {getNotificationCount !== 0 ? (
                    <Badges
                      badgeContent={getNotificationCount}
                      color="error"
                      overlap="circular"
                    >
                      <NotificationsIcon
                        fontSize="medium"
                        sx={{
                          color: 'var(--secondary-text-color)',
                          cursor: 'pointer',
                        }}
                      />
                    </Badges>
                  ) : (
                    <NotificationsIcon
                      fontSize="medium"
                      sx={{
                        color: 'var(--secondary-text-color)',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                </div>
              </Tooltip>
            </Box>
            <Box mt={0.5}>
              {isDarkTheme ? (
                <Tooltip
                  title="Switch to Light theme"
                  arrow
                  disableInteractive
                  TransitionComponent={Zoom}
                >
                  <LightModeIcon
                    sx={{ cursor: "pointer" }}
                    fontSize="medium"
                    color="warning"
                    onClick={themeChange}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  title="Switch to Dark theme"
                  arrow
                  disableInteractive
                  TransitionComponent={Zoom}
                >
                  <DarkModeIcons
                    sx={{ cursor: "pointer" }}
                    fontSize="medium"
                    onClick={themeChange}
                  />
                </Tooltip>
              )}
            </Box>
            <Box>
              <Tooltip
                title="Logout"
                arrow
                disableInteractive
                TransitionComponent={Zoom}
              >
                <IconButton onClick={() => setOpenDialog(true)}>
                  <LogoutIcon
                    fontSize="medium"
                    sx={{ color: "var(--secondary-text-color)" }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
          {isOpen && (<div ref={notificationRef}><Notifications toolTipStyle={toolTipStyle} isMobile={isMobile} isOpen={isOpen} onClose={() => setIsOpen(false)} /></div>)}
        </>)
      }
      <LogoutDialog
        openDialog={openDialog}
        handleCancelLogout={handleCancelLogout}
        handleLogoutConfirmation={handleLogoutConfirmation}
      />
    </>
  );
};

export default Header;

