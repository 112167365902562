import { Box, Button, TextField, Typography, styled } from "@mui/material"
import {
    commonStyle
} from "../../components/ReusableComponents/CustomDesignMUI/CustomMUI";

export const MainBox = styled(Box)(() => ({
    marginTop: "10px",
    marginLeft: "9px",
}))

export const InnerBoxOne = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}))

export const EditBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-2px",
}))

export const InputTextField = styled(TextField)(({ themechange }) => ({
    width: "97%",
    height: "55px",
    background: themechange === "true" ? "#142840" : "#ffffff",
    ".MuiTypography-root": {
        color: "var(--secondary-text-color) !important",
    },
    ...commonStyle
}))

export const CustomTextField = styled(TextField)(({ themechange }) => ({
    width: "97%",
    height: "55px",
    background: themechange === "true" ? "#142840" : "#ffffff",
}))

export const ErrorTypography = styled(Typography)(() => ({
    color: "red",
    marginTop: "20px"
}))

export const CancelButton = styled(Button)(({ isdarktheme }) => ({
    fontWeight: "bold",
    color: "gray",
    "&.Mui-disabled": {
        "-webkit-text-fill-color": isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82 !important",
        backgroundColor: isdarktheme === "true" ? "#424242 !important" : "#e0e0e0 !important",
    },
}))

export const SaveButton = styled(Button)(() => ({
    fontWeight: "bold",
    color: "var(--primary-color)",
}))

export const SaveNextButton = styled(Button)(() => ({
    fontWeight: "bold",
    backgroundColor: "var(--secondary-color)",
    color: "#ffffff",
}))