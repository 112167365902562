import { Box, Button, IconButton, Typography, styled, TextField } from "@mui/material"

export const CloseIconButton = styled(IconButton)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "10px",
    right: "-5px",
    fontWeight: "bold",
    textTransform: "capitalize",
    marginRight: "15px",
    fontSize: "2px",
    "&:hover": {
        background: "var(--highlight-color)",
    },
}))

export const MainBox = styled(Box)(({ isdarktheme }) => ({
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    borderRadius: "5px",
    // background: "var(--background-table-sidebar-card-color)",
    padding: "15px",
    width: "400px",
    background: isdarktheme === "true" ? "#121e2e" : "#ffffff",
    '@media (max-width: 600px)': {
        width: "250px",
    },
}))

export const ChangePasswordTypography = styled(Typography)(({ iconcolor }) => ({
    fontSize: "20px",
    color: iconcolor,
    fontWeight: "bold",
    // marginLeft: "10px",
}))

export const ErrorTypography = styled(Typography)(() => ({
    color: "red",
    fontSize: "14px",
    margin: "5px 0px 0px 3px",
}))

export const ErrorMessageTypography = styled(Typography)(() => ({
    color: "red",
    fontSize: "14px",
    padding: "0",
}))

export const SaveButton = styled(Button)(() => ({
    width: "100%",
    marginTop: "20px",
    background: "var(--secondary-color)",
    color: "var(--secondary-color",
    fontWeight: "bold",
    "&:hover": {
        background: "red",
        color: "var(--secondary-color)",
    },
}))

export const InputTextField = styled(TextField)(({ isdarktheme }) => ({
    ".MuiOutlinedInput-input": {
      color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
      borderColor: "white",
    },
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    ".MuiInputLabel-root": {
      color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    ".MuiInputLabel-root.Mui-focused": {
      color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
      borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    svg: {
      fill: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#484848",
    "& fieldset legend > span": {
      paddingLeft: "2px",
      paddingRight: "0px",
    },
  }));