import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Edit } from "@mui/icons-material";

import { setAllData } from "../../../Slices/UserDataSlice.js";
import { setemployeeAllData } from "../../../Slices/employeeDetailSlice.js";
import { setToast, setToastMsg, setToastType } from "../../../Slices/toastSlice.js";
import {
  viewEducationBox,
  viewProfileTitle,
  viewEducationTitle,
  viewProfileSubtitle,
} from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import { renderAsterisk } from "../../ReusableComponents/UtilityFunctions.js";
import { upsertFamily } from "../../../Services/EmployeeManageServices.js";
import { errorMessage } from "../../../Services/axiosInstance.js";
import { AddRecordBox, MainBox, TitleBox, InputTextField, PhoneTextField } from "../../../Styles/UpdateEmployee/UpdateFamilyStyle.js";
import { FooterBox } from "../../../Styles/UpdateEmployee/UpdatePersonalStyle.js";
import { CancelButton, SaveButton, SaveNextButton } from "../../../Styles/UpdateEmployee/UpdateContactStyle.js";

function UpdateFamily(props) {
  const { employeeCall, saveNextButtonCallback, setIsUpdated, isUpdated } = props;
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const user = useSelector((state) => state.selectedActiveView.role);
  const isMobile = useMediaQuery("(max-width:600px)");

  const [editEmployee, setEditEmployee] = useState(false);
  const [displayError, setDisplayError] = useState("");
  const [familyDetailsArray, setFamilyDetailsArray] = useState(
    employeeCall?.Family_Records || []
  );
  const [familyDetailsErrorsArray, setFamilyDetailsErrorsArray] = useState([]);

  const employee_id = employeeCall?.empId;
  const adminLogin = user === "admin";

  const getEmptyFamilyRecord = () => ({
    FirstName: "",
    lastName: "",
    Relation: "",
    Email: "",
    Phone_Number: "",
    Date_Of_Birth: "",
  });

  const getEmptyFamilyRecordErrors = () => ({
    FirstName: "",
    lastName: "",
    Relation: "",
    Email: "",
    Phone_Number: "",
    Date_Of_Birth: "",
  });

  const addRecord = () => {
    if (editEmployee) {
      if (validateAllForms()) {
        setFamilyDetailsArray((prevArray) => [
          ...prevArray,
          getEmptyFamilyRecord(),
        ]);
        setFamilyDetailsErrorsArray((prevErrorsArray) => [
          ...prevErrorsArray,
          getEmptyFamilyRecordErrors(),
        ]);
      }
    }
  };

  const validateAllForms = () => {
    const allErrors = familyDetailsArray?.map((record, index) =>
      validateForm(index)
    );
    setFamilyDetailsErrorsArray(allErrors);
    return allErrors?.every((errors) =>
      Object.values(errors).every((error) => !error)
    );
  };

  const handleInputChange1 = (index, event) => {
    const { name, value } = event.target;
    setFamilyDetailsArray((prevArray) =>
      prevArray?.map((record, i) =>
        i === index ? { ...record, [name]: value } : record
      )
    );
  };

  const removeRecord = (index) => {
    setFamilyDetailsArray((prevArray) =>
      prevArray.filter((_, i) => i !== index)
    );
    setFamilyDetailsErrorsArray((prevErrorsArray) =>
      prevErrorsArray.filter((_, i) => i !== index)
    );
  };

  const validateForm = (index) => {
    const record = familyDetailsArray[index];
    const errors = getEmptyFamilyRecordErrors();
    if (!record?.FirstName) {
      errors.FirstName = "First name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(record?.FirstName)) {
      errors.FirstName = "First name should not contain numeric values";
    }
    if (!record?.lastName) {
      errors.lastName = "Last name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(record?.lastName)) {
      errors.lastName = "Last name should not contain numeric values";
    }
    if (!record?.Relation) {
      errors.Relation = "Family relation is required";
    } else if (!/^[a-zA-Z\s]*$/.test(record?.Relation)) {
      errors.Relation = "Family relation should not contain numeric values";
    }
    if (!record?.Email) {
      errors.Email = "Email address is required";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(record?.Email)
    ) {
      errors.Email = "Invalid email format";
    }

    if (!record?.Phone_Number) {
      errors.Phone_Number = "Phone number is required";
    } else if (!/^[6-9]\d{9}$/.test(record?.Phone_Number)) {
      errors.Phone_Number = "Phone number is not valid";
    }

    if (!record?.Date_Of_Birth) {
      errors.Date_Of_Birth = "Date of birth is required";
    }
    return errors;
  };

  const saveAndNext = async () => {
    try {
      // const response = await axios.post(
      //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/family`,
      //   JSON.stringify({ employee_id, familyDetailsArray }),
      //   postHeader
      // );
      const postData = JSON.stringify({ employee_id, familyDetailsArray });
      const response = await upsertFamily(postData)
      if (response.status === 200 || response.status === 201) {
        dispatch(setAllData(response.data.user));
        dispatch(setemployeeAllData(response.data.employeeAllDetail));
        if (adminLogin) {
          if (isUpdated === true) {
            setIsUpdated(false)
          } else {
            setIsUpdated(true)
          }
        }
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  };

  return (
    <Box>
      <MainBox ismobile={isMobile?"true":"false"}>
        <TitleBox>
          <Box>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Family Details
            </Typography>
          </Box>
          {/* EDIT AND DELETE BUTTONS */}
          <Box>
            {editEmployee ? null : (
              <Box>
                <IconButton
                  disabled={editEmployee}
                  onClick={() => setEditEmployee(true)}
                  sx={{ color: "var( --third-color)" }}
                >
                  <Edit />
                </IconButton>
              </Box>
            )}
          </Box>
        </TitleBox>
        <hr />
        <AddRecordBox>
          <Typography sx={{ ...viewProfileTitle }}>Family Records</Typography>
          {editEmployee ? (
            <Button
              variant="outlined"
              sx={{ color: "var(--primary-color)", fontWeight: "bold" }}
              onClick={() => addRecord()}
            >
              + Add Record
            </Button>
          ) : null}
        </AddRecordBox>
        <Box>
          {editEmployee ? (
            <Box>
              {familyDetailsArray?.map((record, index) => (
                <Box key={index} sx={viewEducationBox}>
                  <Box sx={{ marginBottom: "15px" }}>
                    <InputTextField
                      type="text"
                      name="FirstName"
                      disabled={!editEmployee}
                      // label="First Name"
                      value={familyDetailsArray[index]?.FirstName}
                      onChange={(event) => handleInputChange1(index, event)}
                      label={
                        <>
                          First Name
                          {renderAsterisk()}
                        </>
                      }
                    />
                    {familyDetailsErrorsArray[index]?.FirstName && (
                      <Typography color="error">
                        {familyDetailsErrorsArray[index]?.FirstName}
                      </Typography>
                    )}

                    <InputTextField
                      name="lastName"
                      type="text"
                      disabled={!editEmployee}
                      value={familyDetailsArray[index]?.lastName}
                      // label="Last Name"
                      onChange={(event) => handleInputChange1(index, event)}
                      label={
                        <>
                          Last Name
                          {renderAsterisk()}
                        </>
                      }
                    />
                    {familyDetailsErrorsArray[index]?.lastName && (
                      <Typography color="error">
                        {familyDetailsErrorsArray[index]?.lastName}
                      </Typography>
                    )}

                    <InputTextField
                      type="text"
                      name="Relation"
                      disabled={!editEmployee}
                      value={familyDetailsArray[index]?.Relation}
                      // label="Family Relation"
                      onChange={(event) => handleInputChange1(index, event)}
                      label={
                        <>
                          Family Relation
                          {renderAsterisk()}
                        </>
                      }
                    />
                    {familyDetailsErrorsArray[index]?.Relation && (
                      <Typography color="error">
                        {familyDetailsErrorsArray[index]?.Relation}
                      </Typography>
                    )}

                    <InputTextField
                      type="email"
                      name="Email"
                      disabled={!editEmployee}
                      value={familyDetailsArray[index]?.Email}
                      // label="Family Email"
                      onChange={(event) => handleInputChange1(index, event)}
                      label={
                        <>
                          Family Email
                          {renderAsterisk()}
                        </>
                      }
                    />
                    {familyDetailsErrorsArray[index]?.Email && (
                      <Typography color="error">
                        {familyDetailsErrorsArray[index]?.Email}
                      </Typography>
                    )}

                    <PhoneTextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+91</InputAdornment>
                        ),
                      }}
                      type="text"
                      disabled={!editEmployee}
                      name="Phone_Number"
                      value={familyDetailsArray[index]?.Phone_Number}
                      // label="Mobile Number"
                      onChange={(event) => handleInputChange1(index, event)}
                      label={
                        <>
                         Mobile Number
                          {renderAsterisk()}
                        </>
                      }
                    />
                    {familyDetailsErrorsArray[index]?.Phone_Number && (
                      <Typography color="error">
                        {familyDetailsErrorsArray[index]?.Phone_Number}
                      </Typography>
                    )}

                    <InputTextField
                      InputProps={{
                        startAdornment: <InputAdornment position="start" />,
                      }}
                      isdarktheme={isDarkTheme ? "true" : "false"}
                      name="Date_Of_Birth"
                      type="date"
                      disabled={!editEmployee}
                      value={familyDetailsArray[index]?.Date_Of_Birth}
                      // label="D.O.B"
                      onChange={(event) => handleInputChange1(index, event)}
                      label={
                        <>
                         D.O.B
                          {renderAsterisk()}
                        </>
                      }
                    />
                    {familyDetailsErrorsArray[index]?.Date_Of_Birth && (
                      <Typography color="error">
                        {familyDetailsErrorsArray[index]?.Date_Of_Birth}
                      </Typography>
                    )}
                  </Box>

                  {/* Remove button for record */}
                  <Button
                    disabled={!editEmployee}
                    variant="outlined"
                    color="error"
                    sx={{ fontWeight: "bold" }}
                    onClick={() => removeRecord(index)}
                  >
                    Remove
                  </Button>
                </Box>
              ))}
            </Box>
          ) : (
            <Box>
              {familyDetailsArray?.map((record, index) => (
                <Box key={index} sx={{ marginBottom: "25px" }}>
                  <Box sx={viewEducationBox}>
                    <Typography sx={viewEducationTitle}>
                      {familyDetailsArray[index]?.FirstName}{" "}
                      {familyDetailsArray[index]?.lastName}
                    </Typography>
                    <Typography sx={viewProfileSubtitle}>
                      Relationship : {familyDetailsArray[index]?.Relation} |
                      Phone No: {familyDetailsArray[index]?.Phone_Number}
                    </Typography>
                    <Typography sx={viewProfileSubtitle}>
                      Email: {familyDetailsArray[index]?.Email} | DOB:{" "}
                      {familyDetailsArray[index]?.Date_Of_Birth}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Typography sx={{ color: "red" }}>{displayError}</Typography>
        <Box>
          {editEmployee ? (
            <FooterBox
            >
              <CancelButton
                onClick={() => {
                  setEditEmployee(false);

                  setFamilyDetailsArray(employeeCall?.Family_Records || []);
                }}
              >
                Cancel
              </CancelButton>
              <SaveButton
                onClick={() => {
                  if (validateAllForms()) {
                    if (saveAndNext()) setEditEmployee(false);
                  }
                }}
              >
                Save
              </SaveButton>
              <SaveNextButton
                variant="contained"
                onClick={() => {
                  if (validateAllForms()) {
                    if (saveAndNext()) {
                      setEditEmployee(false);
                      saveNextButtonCallback();
                    }
                  }
                }}
              >
                Save & Next
              </SaveNextButton>
            </FooterBox>
          ) : null}
        </Box>
      </MainBox>
    </Box>
  );
}

export default UpdateFamily;
