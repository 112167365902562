import { Box, Button, Card, Grid, TextField, styled } from "@mui/material"
import {commonStyle } from "../../components/ReusableComponents/CustomDesignMUI/CustomMUI";

export const MainBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 13px 10px -20px #111",
    borderBottom: "1px solid var(--table-border-color)",
    color: "var(--secondary-text-color)",
    marginBottom: "5px",
}))

export const CloseBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    padding: "0px 10px 5px 0px",
}))

export const MainCard = styled(Card)(() => ({
    boxShadow: 4,
    width: "100%",
    marginTop: "10px",
    background: "var(--background-table-sidebar-card-color)",
}))

export const MainGrid = styled(Grid)(() => ({
    width: "fit-content",
    height: "fit-content",
    textAlign: "center",
}))

export const InnerGridOne = styled(Grid)(() => ({
    textAlign: "center",
    paddingTop: "8px !important"
}))

export const InputTextField = styled(TextField)(() => ({
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--secondary-text-color)",
    },
    svg: {
        fill: "var(--secondary-text-color)",
    },
    "& fieldset legend > span": {
        paddingLeft: "2px",
        paddingRight: "0px",
    },
}))

export const DateTextField = styled(TextField)(({ isdarktheme }) => ({
    width: "100%",
    // height: "55px",
    marginTop: "8%",
    color: isdarktheme === "true" ? "white" : "black",
    background: isdarktheme === "true" ? "#142840" : "#ffffff",
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
        filter: isdarktheme === "true" ? "invert(1)" : "none",
    },
    ...commonStyle
}));

export const InnerGridTwo = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "column",
}))

export const InnerGridThree = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
}))

export const InnerGridFour = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
}))

export const InnerBoxOne = styled(Box)(() => ({
    display: "flex", justifyContent: "space-between",
    alignItems: "center",
}))

export const CancleButton = styled(Button)(() => ({
    ml: 2,
    marginRight: "10px"
}))