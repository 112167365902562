import { AccordionDetails, AccordionSummary, Box, Button, Typography, styled } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"

export const MainBox = styled(Box)(() => ({
    borderBottom: "1px solid var(--table-border-color)",
    marginBottom: "20px",
    "@media (max-width: 600px)": {
        marginBottom: "0px",
    }
}))

export const SliderBox = styled(Box)(() => ({
    "@media (max-width: 600px)": {
        padding: "10px 0px 0px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
        overflow: "hidden",
        position: "relative"
    },
}))

export const InnerSliderBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
        display: "none",
    },
    overflowY: "hidden",
}))

export const CommanTabBox = styled(Box)(() => ({
    "@media (max-width: 600px)": {
        display: "flex",
        flexDirection: "row",
        flexShrink: "0",
        minWidth: "100%",
        justifyContent: "center"
    },
}))

export const LeaveButton = styled(Button)(({ selectedtab }) => ({
    fontWeight: "10px",
    borderRadius: "0px",
    fontSize: "16px",
    letterSpacing: "1px",
    color:
        selectedtab === "Leaves"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedtab === "Leaves"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
}))

export const HolidayButton = styled(Button)(({ selectedtab }) => ({
    fontWeight: "10px",
    borderRadius: "0px",
    fontSize: "16px",
    letterSpacing: "1px",
    color:
        selectedtab === "Holidays"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedtab === "Holidays"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
}))

export const AssignLeaveButton = styled(Button)(({ selectedtab }) => ({
    fontWeight: "10px",
    borderRadius: "0px",
    fontSize: "16px",
    letterSpacing: "1px",
    color:
        selectedtab === "Assign Leaves"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedtab === "Assign Leaves"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
}))

export const ShowAssignLeaveButton = styled(Button)(({ selectedtab }) => ({
    fontWeight: "10px",
    borderRadius: "0px",
    fontSize: "16px",
    letterSpacing: "1px",
    color:
        selectedtab === "Show Assign Leaves"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedtab === "Show Assign Leaves"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
}))

export const LoadingBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "65vh",
}))

export const CompanyLeaveBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "flex-end"
}))

export const AddLeaveTypeButton = styled(Button)(() => ({
    marginLeft: "15px",
    marginBottom: "15px",
    backgroundColor: "#368c43",
    color: "#fff",
}))

export const DataGrids = styled(DataGrid)(({ dataGridHeight }) => ({
    height: dataGridHeight
        ? "230px"
        : "fit-content",
    ".MuiDataGrid-filler": {
        height: "0px !important",
        display: "none",
    },
    color: "var(--primary-text-color)",
    backgroundColor:
        "var(--subAccordian-background-color)",
    boxShadow:
        "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    ".MuiDataGrid-cell ": {
        cursor: "pointer",
        alignContent: "center",
    },
    ".MuiDataGrid-cell:focus": {
        outline: "none",
    },
    ".MuiDataGrid-columnHeader": {
        height: "48px !important",
    },
    ".MuiDataGrid-columnHeader:focus": {
        outline: "none",
    },
    textTransform: "capitalize",
    ".MuiDataGrid-columnHeaderTitle": {
        textTransform: "none !important",
    },
    ".MuiSvgIcon-fontSizeSmall": {
        fill: "var(--primary-text-color) !important",
    },
    ".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
    {
        color: "var(--primary-text-color) !important",
    },
    ".MuiDataGrid-overlay": {
        color: "var(--primary-text-color) !important",
        backgroundColor:
            "var(--subAccordian-background-color)",
    },
    ".MuiInput-underline:before": {
        borderBottom:
            "1px solid var(--primary-text-color) !important",
    },
    ".MuiDataGrid-scrollbar": {
        height: "auto !important",
      },
      "--DataGrid-containerBackground": "var(--primary-highlight-color)"
}))

export const NoLeavesAddedTypography = styled(Typography)(() => ({
    display: "flex",
    color: "var(--secondary-text-color)",
    justifyContent: "center",
}))

export const CompanyHolidayBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "flex-end"
}))

export const AddHolidayButton = styled(Button)(() => ({
    marginLeft: "15px",
    marginBottom: "15px",
    backgroundColor: "#388e3c",
    color: "#fff",
}))

export const HolidaysMainBox = styled(Box)(() => ({
    color: "var(--primary-text-color)",
    textAlign: "center",
    marginBottom: "20px",
    userSelect: "none",
    // borderTop: "1px solid var(--table-border-color)",
    // borderBottom: "1px solid var(--table-border-color)",
}))

export const HolidaysTextTypography = styled(Typography)(() => ({
    fontWeight: "bold",
    color: "var(--primary-color)",
    fontSize: "20px",
}))

export const HolidayDataGrid = styled(DataGrid)(({ dataGridHeight1 }) => ({
    height: dataGridHeight1 ? "230px" : "fit-content",
    ".MuiDataGrid-filler": {
        height: "0px !important",
        display: "none",
    },
    color: "var(--primary-text-color)",
    backgroundColor: "var(--subAccordian-background-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    ".MuiDataGrid-cell ": {
        cursor: "pointer",
        alignContent: "center",
    },
    ".MuiDataGrid-cell:focus": {
        outline: "none",
    },
    ".MuiDataGrid-columnHeader": {
        height: "48px !important",
    },
    ".MuiDataGrid-columnHeader:focus": {
        outline: "none",
    },
    ".MuiSvgIcon-fontSizeSmall": {
        fill: "var(--primary-text-color) !important",
    },
    ".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
    {
        color: "var(--primary-text-color) !important",
    },
    ".MuiDataGrid-overlay": {
        color: "var(--primary-text-color) !important",
        backgroundColor: "var(--subAccordian-background-color)",
    },
    ".MuiInput-underline:before": {
        borderBottom:
            "1px solid var(--primary-text-color) !important",
    },
    textTransform: "capitalize",
    ".MuiDataGrid-columnHeaderTitle": {
        textTransform: "none !important",
    },
    ".MuiDataGrid-scrollbar": {
        height: "auto !important",
    },
    "--DataGrid-containerBackground": "var(--primary-highlight-color)"
}))

export const NoHolidayAddedTypography = styled(Typography)(() => ({
    display: "flex",
    color: "var(--primary-color)",
    justifyContent: "center",
}))

export const CustomHolidayBox = styled(Box)(() => ({
    color: "var(--primary-text-color)",
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    userSelect: "none",
}))

export const CustomHolidayTypography = styled(Typography)(() => ({
    fontWeight: "bold",
    color: "var(--primary-color)",
    fontSize: "20px",
}))

export const AccordionSummarys = styled(AccordionSummary)(() => ({
    textTransform: "capitalize",
    backgroundColor: "var(--accordian-background-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    color: "var(--primary-text-color)",
}))

export const AccordionsDetails = styled(AccordionDetails)(() => ({
    backgroundColor: "var(--accordian-background-color)",
    color: "var(--primary-text-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))