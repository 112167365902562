import { Box, Button, Typography, styled, Avatar } from "@mui/material"
import Chip from '@mui/material/Chip';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

export const MainBox = styled(Box)(() => ({
    borderBottom: "1px solid var(--table-border-color)",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    '@media (max-width: 600px)': {
        flexDirection: "column",
    }
}))

export const StatusBox = styled(Box)(({ backgroundcolor }) => ({
    backgroundColor: backgroundcolor,
    minWidth: "70px",
    alignItems: "center",
    color: "#fff",
    borderRadius: "5px",
    height: "35px",
    display: "flex",
    padding: "0px 10px 0px 10px ",
    justifyContent: "center",
}))

export const VisibleTagsChip = styled(Chip, {
    shouldForwardProp: (prop) => prop !== 'color',
})(({ color }) => ({
    margin: '4px',
    backgroundColor: color,
    color: "#fff",
    height: '25px',
    fontSize: '12px',
}));

export const AdditionalCountChip = styled(Chip)(() => ({
    margin: "4px",
    backgroundColor: '#bdbdbd',
    color: "#000",
    height: "25px",
    fontSize: "12px",
}))

export const TasksButton = styled(Button)(({ selectedbutton }) => ({
    fontWeight: "10px",
    borderRadius: "0px",
    fontSize: "16px",
    letterSpacing: "1px",
    color:
        selectedbutton === "Tasks"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedbutton === "Tasks"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
}))

export const EmployeeActivityButton = styled(Button)(({ selectedbutton }) => ({
    fontWeight: "10px",
    borderRadius: "0px",
    fontSize: "16px",
    letterSpacing: "1px",
    color:
        selectedbutton === "Employee Activity"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedbutton === "Employee Activity"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
}))

export const TitleBox = styled(Box)(() => ({
    borderBottom: "1px solid var(--table-border-color)",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
}))

export const TitleTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    fontWeight: "bold",
    marginLeft: "15px",
}))

export const AddTaskBox = styled(Box)(() => ({
    paddingRight: "15px",
    paddingBottom: "5px",
}))

export const SideBox = styled(Box)(() => ({
    position: "fixed",
    top: 0,
    right: 0,
    width: "60%",
    height: "100vh",
    background: "var(--search-bar-color)",
    borderRadius: "5px",
    zIndex: 999,
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
    overflowY: "auto",
    '@media (max-width: 600px)': {
        width: '100%',
    },
}))

export const EmpActivityBox = styled(Box)(() => ({
    marginTop: "10px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    borderRadius: "5px",
    // background:
    // 	"var(--background-table-sidebar-card-color)",
    padding: "12px 10px 12px 10px",
}))

export const AddTaskButtonBox = styled(Box)(() => ({
    marginBottom: "20px",
    paddingRight: "15px",
    display: "flex",
    justifyContent: "flex-end",
    '@media (max-width: 600px)': {
        paddingRight: "4px",
    },
}))

export const SliderBox = styled(Box)(() => ({
    "@media (max-width: 600px)": {
        padding: "10px 0px 0px 0px",
    },
}))

export const InnerSliderBox = styled(Box)(() => ({
    "&::-webkit-scrollbar": {
        display: "none",
    },
    overflowY: "hidden",
}))

export const AddTaskButton = styled(Button)(() => ({
    '@media (max-width: 600px)': {
        width: '100%',
    },
}))

export const BoardViewBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    overflowX: "auto",
}))

export const BoardViewCard = styled(Box)(({ isdarktheme })  => ({
    width: "230px",
    flexShrink: 0,
    backgroundColor: isdarktheme === "true"
      ? "#121e2e"
      : "#f8f9fa",
    padding: 1,
    borderRadius: "5px",
}))

export const UserNameAvatar = styled(Avatar)(()  => ({
    position: "absolute",
    bottom: "8px",
    right: "8px",
    background:
      "var(--textProfile-background-color)",
    color:
      "var(--primary-text-color)",
    fontSize: "10px",
    width: 22,
    height: 22,
}))

export const ViewBox = styled(Box)(()  => ({
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width: 600px)": {
      justifyContent: "flex-start",
    },
}))

export const MainBoardViewBox = styled(Box)(()  => ({
    width: "100%",
    overflowX: "auto",
}))

export const CardHeaderBox = styled(Box)(()  => ({
    display: "flex",
    alignItems: "center",
    color: "white",
    padding: "5px 10px",
    borderRadius: "5px",
    fontWeight: "bold",
    fontSize: "18px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
    textTransform: "uppercase",
    textAlign: "center",
}))

export const AddTaskBoxButton = styled(Box)(()  => ({
    marginTop: "10px",
    textAlign: "center",
    width: "100%",
}))

export const InnerCardBox = styled(Box)(({ isdarktheme })  => ({
    position: "relative",
    padding: 2,
    marginTop: 1,
    backgroundColor: isdarktheme
      ? "var(--primary-highlight-color)"
      : "#fff",
    borderRadius: "8px",
    boxShadow:
      "0px 2px 4px rgba(0, 0, 0, 0.1)",
}))

export const TaskTitleTypography = styled(Typography)(() => ({
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
}))

export const TaskPriorityBox = styled(Box)(() => ({
    position: "absolute",
    bottom: "2px",
    right: "55px",
    fontSize: "20px",
}))

export const CalendarTodayIcons = styled(CalendarTodayIcon)(() => ({
    color: "grey",
    fontSize: "18px",
    position: "absolute",
    bottom: "10px",
    right: "35px",
}))