import { Box, Button, Checkbox, DialogActions, DialogContent, DialogTitle, InputAdornment, OutlinedInput, Table, TableCell, TableContainer, TableHead, TextField, Typography, styled } from "@mui/material"

export const DialogTitles = styled(DialogTitle)(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
}))

export const MainBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
}))

export const TitleBox = styled(Box)(({ themechange, ismobile }) => ({
    fontWeight: "bold",
    fontSize: "25px",
    color: themechange === "true" ? "#e0e0e0cc" : "#5e6e82",
    marginRight: "5px",
}))

export const DetailsBox = styled(Box)(({ themechange, ismobile }) => ({
    fontSize: "20px",
    color: themechange === "true" ? "#e0e0e0cc" : "#5e6e82",
    marginBottom: ismobile === "true" ? "5px" : "0px"
}))

export const LoadingBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "42vh",
}))

export const DialogContents = styled(DialogContent)(({ themechange }) => ({
    color: themechange === "true" ? "#e0e0e0cc" : "#5e6e82"
}))

export const InnerBoxOne = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
}))

export const LabelTypography = styled(Typography)(() => ({
    fontSize: "115%",
    alignSelf: "baseline"
}))

export const CustomTextField = styled(TextField)(({ themechange }) => ({
    width: "-webkit-fill-available",
    height: "fit-content",
    background: themechange === "true"
        ? "#142840"
        : "#ffffff",
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
        filter: themechange === "true" ? "invert(1)" : "none",
    },
}))

export const ErrorTypography = styled(Typography)(() => ({
    color: "red",
    marginTop: "2px",
    alignSelf: "baseline",
}))

export const PerInputAdornment = styled(InputAdornment)(({ themechange }) => ({
    ".MuiTypography-root": {
        fontSize: "160%",
        color: themechange === "true"
            ? "#e0e0e0cc !important"
            : "#5e6e82 !important",
    },
}))

export const OutlinedInputs = styled(OutlinedInput)(() => ({
    width: "-webkit-fill-available",
    height: "fit-content",
    // background: themeChange ? "#142840" : "#ffffff",
}))

export const DialogsActions = styled(DialogActions)(({ ismobile }) => ({
    display: "flex",
    justifyContent: ismobile !== "true" && "space-between",
    alignItems: "center",
    padding: "8px 0px 0px 0px"
}))

export const CancelButton = styled(Button)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    marginRight: "10px",
}))

export const TableMainBox = styled(Box)(() => ({
    padding: "5px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    borderRadius: "5px !important",
}))

export const TableContainers = styled(TableContainer)(() => ({
    borderRadius: "5px !important",
}))

export const Tables = styled(Table)(({ isdarktheme,sprint }) => ({
    "--table-border-color": isdarktheme === "true"
        ? "#5e6e82"
        : "#e0e0e0cc",
    '@media (max-width: 600px)': {
        width:sprint?'100%': '135%',
    },
}))

export const TableHeads = styled(TableHead)(() => ({
    background: "var(--primary-highlight-color)",
    height: "fit-content",
}))

export const TableCells = styled(TableCell)(({ emptasktableheadcell, isdarktheme }) => ({
    ...emptasktableheadcell,
    color: isdarktheme === "true"
        ? "#e0e0e0e3 !important"
        : "#544f5a !important",
}))

export const Checkboxs = styled(Checkbox)(() => ({
    color: "#2c7be5",
    paddingTop: "5px",
    paddingBottom: "5px",
}))

export const CustomTableCells = styled(TableCell)(({ emptasktablebodycell, isdarktheme }) => ({
    ...emptasktablebodycell,
    fontWeight: "500 !important",
    background: isdarktheme === "true"
        ? "#142840"
        : "#ffffff",
    color: isdarktheme === "true"
        ? "#e0e0e0e3 !important"
        : "#544f5a !important",
    paddingTop: "5px",
    paddingBottom: "5px",
}))