import { Box, Button, CircularProgress, DialogActions, IconButton, Radio, TextField, Typography, styled } from "@mui/material"
import TimerOffIcon from "@mui/icons-material/TimerOff";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

export const TitleTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    display: "inline",
    padding: "8px",
    borderRadius: "5px",
    fontWeight: "bold",
    fontSize: "130%",
}))

export const CloseIconButton = styled(IconButton)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "10px",
    right: "-5px",
    fontWeight: "bold",
    textTransform: "capitalize",
    marginRight: "15px",
    fontSize: "2px",
    "&:hover": {
        background: "var(--highlight-color)",
    },
}))

export const DateTypography = styled(Typography)(() => ({
    display: "inline",
    padding: "8px",
    borderRadius: "5px",
    fontWeight: "bold",
    fontSize: "13px",
    color: "var(--secondary-text-color)",
}))

export const MainBox = styled(Box)(() => ({
    padding: "10px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14)",
    borderRadius: "5px",
    marginTop: "10px",
    backgroundColor: "var(--primary-highlight-color)",
    display: "flex",
    justifyContent: "center",
}))

export const PunchInAtTypography = styled(Typography)(() => ({
    color: "var(--secondary-text-color)",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column", // Ensures vertical alignment of inner content
    alignItems: "center", // Center-aligns inner content horizontally
}))


export const PunchInTypography = styled(Typography)(() => ({
    padding: "3px 0px 0px 6px",
    fontSize: "13px",
    color: "var(--secondary-text-color)",
    fontWeight: "bold",
}))

export const InnerBoxOne = styled(Box)(() => ({
    marginTop: "15px",
    display: "flex",
    justifyContent: "center",
    marginBottom: "15px",
}))

export const PunchInButton = styled(Button)(() => ({
    backgroundColor: "var(--secondary-color)",
    color: "#fff",
    fontWeight: "bold",
    textTransform: "capitalize",
    fontSize: "17px",
    cursor: "pointer",
    width: "130px",
    padding: "5px 20px 5px 20px",
    "&:hover": {
        backgroundColor: "#83a80e",
    },
}))

export const PunchOutButton = styled(Button)(() => ({
    backgroundColor: "var(--reject-color)",
    color: "#fff",
    fontWeight: "bold",
    textTransform: "capitalize",
    fontSize: "17px",
    cursor: "pointer",
    width: "130px",
    padding: "5px 20px 5px 20px",
    "&:hover": {
        backgroundColor: "#d62929",
    },
}))

export const InnerBoxTwo = styled(Box)(() => ({
    marginTop: "1%",
    height: "20px",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 13px 10px -20px #111 inset",
    borderTop: "2px solid var(--table-border-color)",
    "@media (max-width: 600px)": {
        paddingBottom: "20px"
    }
}))

export const BreakTimeBox = styled(Box)(() => ({
    width: "100%",
    paddingLeft: "13%",
    marginTop: "10px",
    color: "var(--secondary-text-color)",
}))

export const OverTimeBox = styled(Box)(() => ({
    width: "100%",
    paddingLeft: "18%",
    marginTop: "10px",
    color: "var(--secondary-text-color)",
}))

export const MainBoxDialog = styled(Box)(() => ({
    cursor: "grab",
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "550px",
    height: "75px",
}))

export const InnerBoxOneDialog = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const InnerBoxTwoDialog = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#ff000221",
    borderRadius: "1000px",
    padding: "8px",
    marginRight: "25px",
}))

export const TimerOffIcons = styled(TimerOffIcon)(() => ({
    fontSize: "xx-large",
    fill: "#b12929"
}))

export const ContentBox = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    justifyContent: "left",
    marginTop: "-15px",
    paddingLeft: "35px",
    marginRight: "25px",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
}))

export const CustomRadio = styled(Radio)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82"
}))

export const ErrorTypography = styled(Typography)(() => ({
    color: "red",
    paddingLeft: "48px",
    marginTop: "6px"
}))

export const CustomTextField = styled(TextField)(({ isdarktheme }) => ({
    marginLeft: "30px",
    marginTop: "5px",
    width: "470px",
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline ":
    {
        borderColor: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    },
}))

export const DialogsActions = styled(DialogActions)(() => ({
    background: "var(--highlight-color)",
    boxShadow: "0px 2px 10px var(--dark-highlight-color)",
}))

export const CancelButton = styled(Button)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    textTransform: "capitalize",
    fontWeight: "bold",
    marginRight: "5px",
    borderRadius: "5px",
    "&.Mui-disabled": {
        "-webkit-text-fill-color": isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82 !important",
        backgroundColor: isdarktheme === "true" ? "#424242 !important" : "#e0e0e0 !important",
    },
}))

export const CustomButton = styled(Button)(({ theme }) => ({
    borderRadius: "5px",
    textTransform: "capitalize",
    "&.Mui-disabled": {
        opacity: 0.8,
        backgroundColor: theme.palette.error.main,
        "-webkit-text-fill-color": "white !important",
    },
}))

export const TicketMainBoxDialog = styled(Box)(() => ({
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "550px",
    height: "75px",
}))

export const SelectTaskBoxDialog = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#00ff1115",
    borderRadius: "1000px",
    padding: "8px",
    marginRight: "25px",
}))

export const ConfirmationNumberIcons = styled(ConfirmationNumberIcon)(() => ({
    fontSize: "xx-large",
    fill: "#1b5e20"
}))

export const EditTicketMainBox = styled(Box)(() => ({
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "550px",
    height: "75px",
}))

export const UpdateTaskBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#b300ff25",
    borderRadius: "1000px",
    padding: "8px",
    marginRight: "25px",
}))

export const CircularsProgress = styled(CircularProgress)(({ restprops }) => ({
    marginLeft: "",
    ".MuiCircularProgress-svg": {
        height: 140,
        color:
            restprops === "true"
                ? "var(--secondary-color)"
                : "var(--primary-color)",
        backgroundColor:
            restprops !== "true"
                ? "var(--primary-highlight-color)"
                : "var(--primary-color)",
        borderRadius: "50%",
        boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
    },
    ".MuiCircularProgress-circle": {
        strokeLinecap: "round",
    },
    borderRadius: "50%",
}))

export const ProgressMainBox = styled(Box)(() => ({
    marginTop: "-95px",
    marginLeft: "-19px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
}))

export const ContentTypography = styled(Typography)(({ restprops }) => ({
    textAlignLast: "center",
    fontWeight: "bold",
    fontSize: "14px",
    color:
        restprops === "true"
            ? "white"
            : "var(--primary-color)",
    width: "100px",
}))

export const TaskDescriptionButton = styled(Button)(() => ({
    fontWeight: "bold",
    "&:hover": {
        backgroundColor: "#007bff22",
        borderColor: "#0063cc22",
        borderRadius: 50,
        boxShadow: "none",
        flexGrow: { flex: 2 },
    },
}))