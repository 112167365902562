import { Box, Button, Card, Typography, styled } from "@mui/material"
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Timeline } from "@mui/lab";
import { DateTimePicker } from "@mui/x-date-pickers";

export const MainBox = styled(Box)(() => ({
    overflowY: "auto",
    margin: "0px 0px 25px 0px",
}))

export const InnerBoxOne = styled(Box)(({ ismobile }) => ({
    flexDirection: ismobile === "true" ? "column" : "row",
    alignItems: ismobile === "true" ? "flex-start" : "center",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 13px 10px -20px #111",
    borderBottom:
        "1px solid var(--table-border-color)",
    color: "var(--secondary-text-color)",
    marginBottom: ismobile !== "true" ? "20px" : "5px",
}))

export const SliderBox = styled(Box)(() => ({
    "@media (max-width: 600px)": {
        padding: "10px 0px 0px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
        overflow: "hidden",
        position: "relative"
    },
}))

export const LeftSideBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
}))

export const RightSideBox = styled(Box)(() => ({
    marginBottom: "5px",
    marginRight: "15px",
}))


export const InnerSliderBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
        display: "none",
    },
    overflowY: "hidden",
}))

export const CommanTabBox = styled(Box)(() => ({
    "@media (max-width: 600px)": {
        display: "flex",
        flexDirection: "row",
        flexShrink: "0",
        minWidth: "100%",
        justifyContent: "center"
    },
}))

export const InnerBoxThree = styled(Box)(() => ({
    borderBottom: "1px solid var(--table-border-color)",
    marginBottom: "20px",
}))

export const StatisticsButton = styled(Button)(({ selectedbutton }) => ({
    fontWeight: "10px",
    borderRadius: "0px",
    fontSize: "16px",
    letterSpacing: "1px",
    color:
        selectedbutton === "Statistics"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedbutton === "Statistics"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
}))

export const AttendanceSheetButton = styled(Button)(({ selectedbutton }) => ({
    fontWeight: "10px",
    borderRadius: "0px",
    fontSize: "16px",
    letterSpacing: "1px",
    color:
        selectedbutton ===
            "Attendance Sheet"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedbutton ===
            "Attendance Sheet"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
}))

export const CustomCard = styled(Card)(() => ({
    height: "100%",
    borderRadius: "5px",
    backgroundColor: "var(--background-table-sidebar-card-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))

export const CalendarTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    display: "inline",
    padding: "5px",
    borderRadius: "5px",
    fontWeight: "bold",
    fontSize: "130%",
}))

export const CalendarBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ".MuiDateCalendar-root": {
        ".MuiPickersCalendarHeader-label": {
            color:
                "var(--secondary-text-color)",
            fontWeight: "bold",
        },
        ".MuiPickersCalendarHeader-switchViewIcon":
        {
            color:
                "var(--secondary-text-color)",
        },
        ".MuiPickersDay-root": {
            color:
                "var(--secondary-text-color)",
            ".MuiPickersDay-root:not(.Mui-selected)":
            {
                color: "#fff",
                border:
                    "1px solid var(--primary-color)",
                backgroundColor:
                    "var(--primary-color)",
            },
        },
        ".MuiSvgIcon-root": {
            color:
                "var(--secondary-text-color)",
        },
        ".MuiDayCalendar-weekDayLabel": {
            color: "var(--secondary-color)",
            fontWeight: "bold",
        },
        ".MuiPickersYear-yearButton": {
            color:
                "var(--secondary-text-color)",
        },
        ".Mui-selected": {
            color: "#fff",
            border:
                "1px solid var(--secondary-text-color) !important",
        },
        "@media (max-width: 600px)": {
            flexDirection: "column",
            "& > div": {
                width: "100%",
            },
            ".MuiPickersCalendarHeader-root": {
                paddingLeft: "12px",
            },
        },
    },

}))

export const NoActivityTypography = styled(Typography)(() => ({
    color: "var(--secondary-text-color)",
    fontWeight: "500",
    marginLeft: "5%",
}))

export const AccessTimeIcons = styled(AccessTimeIcon)(() => ({
    fontSize: "12px",
    color: "var(--primary-text-color)",
    mr: 0.5,
}))

export const TitleTypography = styled(Typography)(() => ({
    fontSize: "12px",
    color: "var(--primary-text-color)",
}))

export const InnerBoxFour = styled(Box)(() => ({
    display: "flex",
    justifyContent: "flex-end",
}))

export const Last30DayButton = styled(Button)(({ datebutton }) => ({
    marginRight: "10px",
    backgroundColor: datebutton === "Last30" ? "var(--secondary-color)" : null,
    "&:hover": {
        backgroundColor:
            datebutton ===
                "Last30"
                ? "var(--secondary-color)"
                : null,
    },
    "@media (max-width: 600px)": {
        padding: "3px 8px",
        marginBottom: "5px"
    },
}))

export const MonthlyButton = styled(Button)(({ datebutton }) => ({
    marginRight: "10px",
    backgroundColor: datebutton === "Monthly" ? "var(--secondary-color)" : null,
    "&:hover": {
        backgroundColor:
            datebutton ===
                "Monthly"
                ? "var(--secondary-color)"
                : null,
    },
    "@media (max-width: 600px)": {
        padding: "3px 8px",
        marginBottom: "5px"
    },
}))

export const MonthBox = styled(Box)(() => ({
    borderRadius: "5px",
    display: "flex",
    flexDirection:
        "column",
    margin: "0px 10px 10px 10px",
    backgroundColor:
        "var(--primary-highlight-color)",
}))

export const EditTimeMainBox = styled(Box)(() => ({
    overflowY: "auto",
    margin: "0px 0px 25px 0px",
}))

export const EditTimeTitleBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 13px 10px -20px #111",
    borderBottom: "1px solid var(--table-border-color)",
    color: "var(--secondary-text-color)",
    marginBottom: "5px",
}))

export const EditTimeMainCard = styled(Card)(() => ({
    marginTop: "20px",
    // height: "",
    borderRadius: "5px",
    backgroundColor: "var(--background-table-sidebar-card-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))

export const DateTypography = styled(Typography)(() => ({
    margin: "10px 0px 30px 20px",
    fontWeight: "bold",
    "@media (max-width: 600px)": {
        margin: "0px 0px 0px 20px",
    }
}))

export const Timelines = styled(Timeline)(() => ({
    display: "flex",
    ".MuiTimelineItem-root::before": {
        content: "none",
    },
}))

export const CustomDateTimePicker = styled(DateTimePicker)(() => ({
    ".MuiInputBase-input-MuiOutlinedInput-input":
    {
        minWidth: "18vh",
    },
    ".MuiSvgIcon-root": {
        fill: "var(--secondary-text-color)",
    },
    "& fieldset legend > span": {
        paddingLeft: "2px",
        paddingRight: "0px",
    },
}))

export const SaveButton = styled(Button)(() => ({
    fontWeight: "bold",
    backgroundColor: "var(--secondary-color)",
    color: "#ffffff",
    "&.Mui-disabled": {
        opacity: 0.7,
        backgroundColor: "var(--secondary-color) !important",
        "-webkit-text-fill-color": "white !important",
    },
    
}))