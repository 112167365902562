import { Box, Button, Card, Typography, styled } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid";

export const InnerCardOne = styled(Card)(() => ({
  backgroundColor: 'var(--primary-highlight-color)',
  borderRadius: '8px',
  marginBottom: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  color: 'var(--secondary-text-color)'
}));

export const ShowAllButton = styled(Button)(() => ({
  marginTop: "3px",
  fontWeight: "normal",
  '&:hover': {
    fontWeight: "bold"
  }
}));

export const LoadingBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "65vh",
}))

export const CommanBox = styled(Box)(() => ({
  color: "var(--secondary-text-color)",
  padding: "16px",
  fontSize: "20px"
}))

export const MainBox = styled(Box)(() => ({
  margin: "0px 0px ",
}))

export const InnerBoxOne = styled(Box)(() => ({
  borderBottom: "1px solid var(--table-border-color)",
  marginBottom: "20px",
  boxShadow: "0px 13px 10px -20px #111",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}))

export const MarkAsBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  margin: "0px 5px 0px 5px",
  '@media (max-width: 600px)': {
    paddingRight: "0px",
    margin: "0px",
  },
}))



export const TitleTypography = styled(Typography)(() => ({
  color: "var(--primary-color)",
  fontWeight: "bold",
  borderRadius: "0px",
  margin: "0px 5px 0px 5px",
}))

export const DataGrids = styled(DataGrid)(({ dataGridHeight }) => ({
  height: dataGridHeight ? "230px" : "fit-content",
  ".MuiDataGrid-filler": {
    height: "0px !important",
    display: "none",
  },
  color: "var(--primary-text-color)",
  textTransform: "capitalize",
  ".MuiDataGrid-columnHeaderTitle": {
    textTransform: "none !important",
  },
  backgroundColor: "var(--subAccordian-background-color)",
  ".MuiDataGrid-cell ": {
    cursor: "pointer",
    alignContent: "center",
  },
  ".MuiDataGrid-cell:focus": {
    outline: "none",
  },
  ".MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  ".MuiInput-underline:before": {
    borderBottom: "1px solid var(--primary-text-color) !important",
  },
  ".MuiSvgIcon-fontSizeSmall": {
    fill: "var(--primary-text-color) !important",
  },
  ".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
  {
    color: "var(--primary-text-color) !important",
  },
  ".MuiDataGrid-overlay": {
    color: "var(--primary-text-color) !important",
    backgroundColor: "var(--subAccordian-background-color)",
  },
  ".MuiDataGrid-actionsCell": { type: "actions" },
  ".MuiCheckbox-root": {
    color: "var(--primary-text-color) !important",
    "&.Mui-checked": {
      color: "var(--primary-color) !important",
    },
  },
  "--DataGrid-containerBackground": "var(--primary-highlight-color)"
}));