import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Tooltip, Typography } from "@mui/material";

import TaskDialogBox from "./DetailTask";
import { numberArray } from "../Constant/constant";
import { TaskTypeCell, getIcon, sortingTask } from "../ReusableComponents/UtilityFunctions";
import { AdditionalCountChip, StatusBox, VisibleTagsChip } from "../../Styles/TaskManagement/TaskManagementStyle";
import "../../assets/styles/DataGridStyles.css";

export default function BasicExampleDataGrid({
  taskData,
  samelevel,
  change,
  setChange,
  currentAdminId,
  EmpIdForFilter,
  currentEmployeeId,
  discarderId,
  EmpIdOfTeammateForFilter,
  ReportingPersonEmpId,
  reportingEmployees,
  taskEdited,
  unapprovedTask,
}) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.selectedActiveView.role);
  const employee = useSelector((state) => state.addEmployee.current?.empId);
  const admin = useSelector((state) => state.adminData.adminData?.empId);
  const updatedTask = useSelector((state) => state.taskData.updatedTask);
  const getTaskTypeList = useSelector(
    (state) => state?.selectedActiveView?.getTaskTypeList
  );
  const currentEmployeeLogin = user === "employee";
  const adminLogin = user === "admin";

  const [viewer, setViewer] = useState("");
  const [column, setColumn] = useState([]);
  const [taskId, setTaskId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [dataGridHeight, setDataGridHeight] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  function handleDetailClick(id) {
    setOpenDialog(true);
    setTaskId(id);
    navigate(`?tNo=${id}`, { replace: true });
  }

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "In Progress":
        return "var(--pending-color)";
      case "Cancelled":
        return "var(--reject-color)";
      case "Completed":
        return "var(--approved-color)";
      case "Hold":
        return "var(--cancel-color)";
      case "To-Do":
        return "var(--primary-color)";
      default:
        return "var(--primary-color)";
    }
  };

  const formatTags = (tags) => {
    if (!tags || tags?.length === 0) return "";
    const maxVisibleTags = 2;
    const visibleTags = tags?.slice(0, maxVisibleTags);
    const additionalCount = tags?.length - maxVisibleTags;
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {visibleTags?.map((tag, index) => (
          <VisibleTagsChip
            key={index}
            label={tag?.title}
            color={tag?.color}
          />
        ))}
        {additionalCount > 0 && (
          <Tooltip title={tags?.slice(maxVisibleTags)?.map(tag => tag?.title).join(', ')}>
            <AdditionalCountChip
              label={`+${additionalCount}`}
            />
          </Tooltip>
        )}
      </div>
    );
  };

  const FormattedTaskData = filteredData?.map((task, index) => ({
    id: index + 1,
    record_id: task?._id,
    timestamp: task?.timestamp,
    date: task?.dueDate?.split("-").reverse().join("-"),
    ticketNo: task?.ticketNo,
    history: task?.history,
    assignBy: task?.assignBy?.name,
    assignByEmp: task?.assignBy?.empId,
    assignTo: task?.name,
    assignToEmp: task?.empId,
    title: task?.title,
    project: task?.project,
    priority: task?.priority,
    status: task?.status,
    statusValue: task?.statusValue,
    statusColor: task?.statusColor,
    category: task?.category,
    type: task?.type,
    description: task?.description,
    plannedHours: task?.plannedHours,
    actualHours: task?.actualHours,
    sprint: task?.sprint,
    tags:
      task?.tagList
        ?.filter((tag) => tag && tag)
        .map((tag) => tag) || [],
  }));

  const employeeColumns = useMemo(
    () =>
      [
        {
          field: 'type',
          headerName: 'Type',
          flex: 0.6,
          renderCell: (params) => {
            if (params?.row?.isNew) {
              return params.value;
            }
            const option = getTaskTypeList?.find(
              (opt) => opt?._id === params.value || opt?.taskTypeName === params.value
            );
            if (!option) return null;
            return (
              <TaskTypeCell
                taskTypeName={option?.taskTypeName}
              />
            );
          },
        },
        unapprovedTask
          ? { field: "assignTo", headerName: "Name", flex: 2 }
          : null,
        // { field:unapprovedTask ? "assignTo" : "id", headerName:unapprovedTask ? "Name" : "No.", flex: 2 },
        // { field: "project", headerName: "Project", flex: 2 },
        { field: "ticketNo", headerName: "Ticket", flex: 1 },
        { field: "title", headerName: "Title", flex: 3 },
        {
          field: "tags",
          headerName: "Tags",
          flex: 3,
          renderCell: (params) => formatTags(params.value),
        },
        { field: "date", headerName: "Due Date", flex: 1.2 },
        { field: "sprint", headerName: "Sprints", flex: 1.2 },
        {
          field: "priority",
          headerName: "Priority",
          flex: 1.5,
          renderCell: (params) => (
            <Box sx={{ display: "flex", height: "25px" }}>
              <Typography sx={{ marginRight: "8px" }}>
                {getIcon(params.value)}
              </Typography>
              <Typography
                sx={{
                  color: "var(--secondary-text-color) !important",
                }}
              >
                {params.value}
              </Typography>
            </Box>
          ),
        },
        {
          field: "status",
          headerName: "Status",
          flex: 1.4,
          sortable: true,
          renderCell: (params) => {
            const { statusValue, statusColor } = params.row;
            return (
              <StatusBox
                backgroundcolor={statusValue && statusValue !== "0"
                  ? statusColor
                  : getStatusBackgroundColor(params.value)}
              >
                {params.value} 
              </StatusBox>
            );
          },
        },
      ].filter((column) => column !== null),
    [unapprovedTask, getTaskTypeList]
  );

  const adminColumns = useMemo(
    () => [
      {
        field: 'type',
        headerName: 'Type',
        flex: 0.8,
        renderCell: (params) => {
          if (params?.row?.isNew) {
            return params.value;
          }
          const option = getTaskTypeList?.find(
            (opt) => opt?._id === params.value || opt?.taskTypeName === params.value
          );
          if (!option) return null;
          return (
            <TaskTypeCell
              taskTypeName={option?.taskTypeName}
            />
          );
        },
      },
      { field: "ticketNo", headerName: "Ticket", flex: 1 },
      { field: "title", headerName: "Title", flex: 2.2 },
      {
        field: "tags",
        headerName: "Tags",
        flex: 3.1,
        renderCell: (params) => formatTags(params.value),
      },
      { field: "date", headerName: "Due Date", flex: 1.5 },
      { field: "plannedHours", headerName: "Planned Hours", flex: 1.5 },
      { field: "actualHours", headerName: "Actual Hours", flex: 1.5 },
      { field: "sprint", headerName: "Sprints", flex: 1.5 },
      {
        field: "priority",
        headerName: "Priority",
        flex: 1.5,
        renderCell: (params) => (
          <Box sx={{ display: "flex", height: "25px" }}>
            <Typography
              sx={{
                marginRight: "2px",
                justifyContent: "flex-start",
              }}
            >
              {getIcon(params.value)}
            </Typography>
            <Typography
              sx={{
                color: "var(--secondary-text-color) !important",
              }}
            >
              {params.value}
            </Typography>
          </Box>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        sortable: true,
        flex: 1.6,
        renderCell: (params) => {
          const { statusValue, statusColor } = params.row;
          return (
            <StatusBox
              backgroundcolor={statusValue && statusValue !== "0"
                ? statusColor
                : getStatusBackgroundColor(params.value)}
            >
              {params.value}
            </StatusBox>
          );
        },
      },
    ],
    [getTaskTypeList]
  );

  useEffect(() => {
    if (updatedTask && Object.keys(updatedTask).length > 0 && updatedTask?._id) {
      setFilteredData((prevData) => {
        const matchesCurrentFilter = prevData?.some(task => task._id === updatedTask?._id) ||
          (adminLogin && updatedTask?.empId === currentAdminId) ||
          (EmpIdForFilter && updatedTask?.empId === EmpIdForFilter && updatedTask?.empId !== discarderId) ||
          (currentEmployeeId && updatedTask?.empId === currentEmployeeId) ||
          (ReportingPersonEmpId && updatedTask?.empId === ReportingPersonEmpId) ||
          (unapprovedTask && !updatedTask?.approved && !updatedTask?.empId === currentEmployeeId);
        if (matchesCurrentFilter) {
          const index = prevData?.findIndex((task) => task?._id === updatedTask?._id);
          if (unapprovedTask && updatedTask?.approved) {
            return prevData?.filter((task) => task?._id !== updatedTask?._id) || [];
          }
          if (index !== -1) {
            const updatedData = [...prevData];
            updatedData[index] = updatedTask;
            return updatedData;
          } else {
            return [updatedTask, ...(prevData || [])];
          }
        }
        return prevData;
      });
    }
  }, [updatedTask, adminLogin, currentAdminId, EmpIdForFilter, currentEmployeeId, ReportingPersonEmpId, discarderId, unapprovedTask]);

  useEffect(() => {
    //for only admin data show
    if (adminLogin) {
      const relatedTasks = taskData?.filter(
        (task) =>
          task?.empId === currentAdminId ||
          task?.contributed?.some((contributor) => contributor?.empId === currentAdminId)
      );
      const parentTaskIds = relatedTasks
        ?.map((task) => task?.parentTaskId)
        ?.filter((id) => id);
      const parentTasks = taskData?.filter(
        (task) =>
          parentTaskIds?.includes(task?._id) && task?.empId !== currentAdminId
      );
      const allTasks = [...relatedTasks, ...parentTasks];
      const uniqueTasks = Array.from(
        new Map(allTasks.map((task) => [task?._id, task])).values()
      );
      setFilteredData(sortingTask(uniqueTasks));
    }

    //for each employee id for admin
    if (EmpIdForFilter) {
      const data = taskData.filter(
        (task) => task?.empId === EmpIdForFilter && task?.empId !== discarderId
      );
      setFilteredData(sortingTask(data));
    }

    //for only loged in employee id
    if (currentEmployeeId) {
      setFilteredData(sortingTask(taskData));
    }

    //for reporting person employee's id
    if (ReportingPersonEmpId) {
      const data = reportingEmployees?.filter(
        (task) => task?.empId === ReportingPersonEmpId
      );
      setFilteredData(sortingTask(data));
    }
    if (unapprovedTask) {
      const data = reportingEmployees?.filter(
        (task) => task?.approved === false
      );
      setFilteredData(sortingTask(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportingEmployees,
    ReportingPersonEmpId,
    adminLogin,
    samelevel,
    currentEmployeeId,
    EmpIdForFilter,
    taskData,
    currentAdminId,
    discarderId,
    EmpIdOfTeammateForFilter,
  ]);

  useEffect(() => {
    if (currentEmployeeLogin) {
      setViewer(employee);
      setColumn(employeeColumns);
    } else if (adminLogin) {
      setViewer(admin);
      setColumn(adminColumns);
    }
  }, [
    change,
    taskEdited,
    admin,
    adminColumns,
    adminLogin,
    currentEmployeeLogin,
    employee,
    employeeColumns,
  ]);

  return (
    <Box sx={{ width: "100%", maxHeight: "fit-content", overflowX: 'auto' }}>
      <Box sx={{
        '@media (max-width: 600px)': {
          width: '330%',
        },
      }}>
        <DataGrid
          disableColumnMenu
          rowHeight={43}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          aria-selected="false"
          rows={FormattedTaskData}
          columns={column}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize:
                  parseInt(window.localStorage.getItem("paginationNo"), 10) || 10,
              },
            },
            columns: {
              columnVisibilityModel: {
                sprint: false,
                // type: false
              },
            }
          }}
          onRowCountChange={(params) => {
            if (params === 0) {
              setDataGridHeight(true);
            } else {
              setDataGridHeight(false);
            }
          }}
          onRowClick={(params) => {
            handleDetailClick(params.row.ticketNo);
          }}
          onPaginationModelChange={(pageSize) => {
            window.localStorage.setItem("paginationNo", pageSize.pageSize);
          }}
          onPageSizeChange={(pageSize) => {
            window.localStorage.setItem("paginationNo", pageSize);
          }}
          pageSizeOptions={numberArray}
          components={{
            Toolbar: GridToolbar,
            // noRowsOverlay: CustomNoRowsOverlay,
          }}
          sx={{
            height: dataGridHeight ? "230px" : "fit-content",
            ".MuiDataGrid-filler": {
              height: "0px !important",
              display: "none",
            },
            color: "var(--primary-text-color)",
            textTransform: "capitalize",
            ".MuiDataGrid-columnHeaderTitle": {
              textTransform: "none !important",
            },
            backgroundColor: "var(--subAccordian-background-color)",
            ".MuiDataGrid-cell ": {
              cursor: "pointer",
              alignContent: "center",
            },
            ".MuiDataGrid-cell:focus": {
              outline: "none",
            },
            ".MuiDataGrid-columnHeader": {
              height: "48px !important",
            },
            ".MuiInput-underline:before": {
              borderBottom: "1px solid var(--primary-text-color) !important",
            },
            ".MuiSvgIcon-fontSizeSmall": {
              fill: "var(--primary-text-color) !important",
            },
            ".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
            {
              color: "var(--primary-text-color) !important",
            },
            ".MuiDataGrid-overlay": {
              color: "var(--primary-text-color) !important",
              backgroundColor: "var(--subAccordian-background-color)",
            },
            ".MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            ".MuiDataGrid-actionsCell": { type: "actions" },
            ".MuiDataGrid-scrollbar": {
              height: "auto !important",
            },
            "--DataGrid-containerBackground": "var(--primary-highlight-color)"
          }}
        />
      </Box>
      <TaskDialogBox
        unapprovedTask={unapprovedTask || false}
        open={openDialog}
        setOpenDialog={setOpenDialog}
        taskId={taskId}
        viewer={viewer}
        change={change}
        setChange={setChange}
      />
    </Box>
  );
}
