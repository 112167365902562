import { Autocomplete, Box, Card, TextField, Typography, styled } from "@mui/material"

export const MainBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 13px 10px -20px #111",
    borderBottom: "1px solid var(--table-border-color)",
    color: "var(--secondary-text-color)",
    marginBottom: "5px",
}))

export const CloseBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    padding: "0px 10px 5px 0px",
}))

export const MainCard = styled(Card)(() => ({
    boxShadow: 4,
    width: "100%",
    marginTop: "10px",
    background: "var(--background-table-sidebar-card-color)",
}))

export const InputTextField = styled(TextField)(() => ({
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--secondary-text-color)",
    },
    svg: {
        fill: "var(--secondary-text-color)",
    },
    "& fieldset legend > span": {
        paddingLeft: "2px",
        paddingRight: "0px",
    },
}))

export const StatusTextField = styled(TextField)(({ employeedisplay }) => ({
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--secondary-text-color)",
    },
    svg: {
        fill: employeedisplay === "true"
            ? "var(--background-table-sidebar-card-color)"
            : "var(--secondary-text-color)",
    },
    "& fieldset legend > span": {
        paddingLeft: "2px",
        paddingRight: "0px",
    },
}))

export const AdditionalDetailsTypography = styled(Typography)(() => ({
    color: "var(--secondary-text-color)",
    textDecoration: "underline",
    // background: "var(--website-background-color)",
}))

export const LeaderNameAutocomplete = styled(Autocomplete)(({ employeedisplay, adminlogin }) => ({
    // width: "400px",
    marginTop: "15px",
    ".css-38raov-MuiButtonBase-root-MuiChip-root": {
        background: "var(--tag-background-color)",
    },
    ".css-i4bv87-MuiSvgIcon-root": {
        fill: employeedisplay === "true"
            ? "var(--background-table-sidebar-card-color)"
            : "var(--tag-close-icon-color)",
    },
    ".MuiAutocomplete-tagSizeMedium": {
        color: "var(--primary-text-color)",
    },
    ".css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator":
        { color: "var(--primary-text-color)" },
    "MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator svg":
    {
        color: adminlogin === "true"
            ? "var(--primary-text-color)"
            : "transparent",
    },
}))

export const MemberNameAutocomplete = styled(Autocomplete)(({ employeedisplay }) => ({
    ".cMuiButtonBase-root-MuiChip-root": {
        background: "var(--tag-background-color)",
    },
    ".MuiSvgIcon-root": {
        fill: employeedisplay === "true"
            ? "var(--background-table-sidebar-card-color)"
            : "var(--tag-close-icon-color)",
    },
    ".MuiAutocomplete-tagSizeMedium": {
        color: "var(--primary-text-color)",
    },
    ".MuiAutocomplete-clearIndicator": {
        color: "var(--primary-text-color)",
    },
}))


export const WorkFlowNameAutocomplete = styled(Autocomplete)(({ employeedisplay }) => ({
    ".cMuiButtonBase-root-MuiChip-root": {
        background: "var(--tag-background-color)",
    },
    ".MuiSvgIcon-root": {
        fill: employeedisplay === "true"
            ? "var(--background-table-sidebar-card-color)"
            : "var(--tag-close-icon-color)",
    },
    ".MuiAutocomplete-tagSizeMedium": {
        color: "var(--primary-text-color)",
    },
    ".MuiAutocomplete-clearIndicator": {
        color: "var(--primary-text-color)",
    },
}))

export const TechnologyStackTypography = styled(Typography)(() => ({
    color: "var(--secondary-text-color)",
    textDecoration: "underline",
    // background: "var(--website-background-color)",
}))
