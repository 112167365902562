import { Box, Button, IconButton, TextField, Typography, styled } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"

export const MainBox = styled(Box)(() => ({
    borderBottom: "1px solid var(--table-border-color)",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    '@media (max-width: 600px)': {
        flexDirection: "column",
        alignItems: "flex-start",
    },
}))

export const TitleTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    fontWeight: "bold",
    marginLeft: "15px",
    '@media (max-width: 600px)': {
        marginLeft: "4px",
    },
}))

export const InnerBoxOne = styled(Box)(() => ({
    width: "100%",
    maxHeight: "fit-content"
}))

export const CloseIconButton = styled(IconButton)(({ isdarktheme, ismobile }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    padding: ismobile === "true" ? "5px 0px" : "8px 0px",
}))

export const ManageRoleDataGrid = styled(DataGrid)(({ dataGridHeight }) => ({
    height: dataGridHeight ? "230px" : "fit-content",
    ".MuiDataGrid-filler": {
        height: "0px !important",
        display: "none",
    },
    color: "var(--primary-text-color)",
    textTransform: "capitalize",
    ".MuiDataGrid-columnHeaderTitle": {
        textTransform: "none !important",
    },
    backgroundColor: "var(--subAccordian-background-color)",
    ".MuiDataGrid-cell ": {
        cursor: "pointer",
        alignContent: "center",
    },
    ".MuiDataGrid-cell:focus": {
        outline: "none",
    },
    ".MuiDataGrid-columnHeader:focus": {
        outline: "none",
    },
    ".MuiDataGrid-columnHeader": {
        height: "48px !important",
    },
    ".MuiButtonBase-root:focus": {
        outline: "none",
    },
    ".MuiSvgIcon-fontSizeSmall": {
        fill: "var(--primary-text-color) !important",
    },
    ".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
    {
        color: "var(--primary-text-color) !important",
    },
    ".MuiDataGrid-overlay": {
        color: "var(--primary-text-color) !important",
        backgroundColor:
            "var(--subAccordian-background-color)",
    },
    ".MuiInput-underline:before": {
        borderBottom:
            "1px solid var(--primary-text-color) !important",
    },
    ".MuiDataGrid-actionsCell": { type: "actions" },
    ".MuiDataGrid-scrollbar": {
        height: "auto !important",
    },
    "--DataGrid-containerBackground": "var(--primary-highlight-color)"
}))

export const InnerBoxTwo = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
}))

export const HeaderBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
        maxWidth: "100%",
    },
}))

export const InnerBoxThree = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const InnerBoxFour = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#f4b73e25",
    borderRadius: "1000px",
    padding: "8px 5px",
    marginRight: "15px",
}))

export const InnerBoxFive = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    justifyContent: "left",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    marginTop: "7px",
}))

export const InnerBoxSix = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    justifyContent: "left",
    marginTop: "20px",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
}))

export const ErrorTypography = styled(Typography)(() => ({
    color: "red",
    marginTop: "0px",
}))

export const LinkBox = styled(Box)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    marginTop: "15px",
}))

export const GroupNameBox = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    justifyContent: "left",
    marginTop: "15px",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
}))

export const GroupNameTextField = styled(TextField)(({ isdarktheme }) => ({
    ".MuiOutlinedInput-input": {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
        borderColor: "white",
    },
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    ".MuiInputLabel-root": {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    ".MuiInputLabel-root.Mui-focused": {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
        borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    width: { sm: 50, md: 350 },
    "& .MuiInputBase-input": {
        height: "0px",
    },
    "& fieldset legend > span": {
        paddingLeft: "2px",
        paddingRight: "0px",
    },
}))

export const LevelNameBox = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    justifyContent: "left",
    marginTop: "20px",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
}))

export const CancelButton = styled(Button)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    textTransform: "capitalize",
    fontWeight: "bold",
    borderRadius: "5px",
}))

export const UpsertRoleButton = styled(Button)(() => ({
    borderRadius: "5px",
    textTransform: "capitalize",
}))

export const InnerMainBox = styled(Box)(() => ({
    paddingBottom: "5px",
    '@media (max-width: 600px)': {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginLeft: "4px",
        marginTop: "10px",
    },
}))

export const AddRoleButton = styled(Button)(() => ({
    marginRight: "15px",
    '@media (max-width: 600px)': {
        marginLeft: "0px",
        display: "flex",
        justifyItems: "flex-end"
    },
    // background: "var(--primary-color)",
}))

export const CustomTextField = styled(TextField)(({ isdarktheme }) => ({
    ".MuiOutlinedInput-input": {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
        borderColor: "white",
    },
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    ".MuiInputLabel-root": {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    ".MuiInputLabel-root.Mui-focused": {
        color: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
        borderColor: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
    "& fieldset legend > span": {
        paddingLeft: "2px",
        paddingRight: "0px",
    },
    svg: {
        fill: isdarktheme === "true" ? "#e0e0e0cc !important" : "#5e6e82",
    },
}))